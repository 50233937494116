<template>
    <v-app>
        <v-main>
            <transition name="page" mode="out-in">
                <component :is="layout"></component>
            </transition>
        </v-main>
    </v-app>
</template>

<style lang="sass">
    @import 'web/css/app.scss'
</style>

<script lang="ts">
    import { defineComponent, provide } from '@vue/composition-api'
    import Default from './layouts/default.vue'
    import Auth from './layouts/auth.vue'
    import Empty from './layouts/empty.vue'

    const defaultLayout = 'default'

    export default defineComponent({
        name: 'App',

        components: {
            Auth,
            Empty,
            Default,
        },

        data() {
            return {
                layout: this.$route.meta?.layout || defaultLayout,
            }
        },

        watch: {
            $route: {
                handler(route) {
                    this.layout = route.meta?.layout || defaultLayout
                }
            }
        },

        setup(_, ctx) {
            provide('router', ctx.root.$router)
        },
    })

</script>
