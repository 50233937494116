<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        persistent
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">{{ value.fullName }}</v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <v-checkbox
                        v-for="(role, index) in roles.data"
                        v-model="value.roleIds"
                        :key="'role_' + role.id"
                        :value="role.id"
                        :label="role.name"
                        :hide-details="index !== roles.data.length - 1"
                        multiple
                        :error-messages="form.errors.get('roles')"
                    />
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    :loading="form.busy"
                    :disabled="form.busy"
                >{{ t('general_action_update') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="cancel"
                    width="45%"
                    dark
                >{{ t('general_action_cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { useLocales } from '@/core/defaults'
    import { User } from '@/core/models/user'
    import { RoleCollection } from '@/store/modules/role-collection'

    const fillable = [
        'roles',
    ]

    export default defineComponent({
        name: 'UserRoleModal',

        props: {
            value: {
                type: User,
                required: true
            }
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()

            const state = reactive({
                touched: false,
            })

            const i18n = useI18n()

            const roles = computed(() => <RoleCollection>store.getters['roleCollection/get'])

            const form = reactive(useForm())

            const submit = () => {
                form
                    .usingSubmit(
                        store.dispatch('user/update',
                            { id: props.value.id, payload: props.value.payload(fillable) }
                        )
                    )
                    .then(() => {
                        state.touched = false
                        context.emit('submit')
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            const cancel = () => {
                form.errors.clear()
                state.touched = false
                context.emit('cancel')
            }

            return {
                roles,
                form,
                submit,
                cancel,
                ...useLocales(),
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
