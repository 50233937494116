import moment, { Moment } from 'moment/moment'
import { HealthSection, HealthSectionPayload } from '@/core/models/health-section'

export interface HealthArticlePayload {
    id: number
    health_section_id: number
    name: string
    lead_paragraph: string
    content: string
    photo_url: string
    published?: boolean
    published_at: string
    created_at: string
    section?: HealthSectionPayload
    translations?: {}
}

export class HealthArticle {

    id: number
    healthSectionId: number
    name: string
    leadParagraph: string
    content: string
    photoUrl: string
    photo?: File
    published: boolean
    publishedAt: Moment
    createdAt: Moment
    section: HealthSection
    translations?: {} = <any>{}

    constructor(data: HealthArticlePayload) {
        this.id = data.id
        this.healthSectionId = data.health_section_id
        this.name = data.name
        this.leadParagraph = data.lead_paragraph
        this.content = data.content
        this.photoUrl = data.photo_url
        this.published = !! data.published
        this.createdAt = moment(data.created_at)
        this.publishedAt = moment(data.published_at)
        this.section = new HealthSection(data.section || <any>{})
        this.capture(data)
    }

    fill(data: HealthArticlePayload): this {
        this.id = data.id
        this.healthSectionId = data.health_section_id
        this.name = data.name
        this.leadParagraph = data.lead_paragraph
        this.content = data.content
        this.photoUrl = data.photo_url
        this.published = !! data.published
        this.createdAt = moment(data.created_at)
        this.publishedAt = moment(data.published_at)
        this.section = new HealthSection(data.section || <any>{})
        this.capture(data)
        return this
    }

    capture(data: HealthArticlePayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.photo = undefined
        this.translations = {}
    }

    payload(): HealthArticlePayload | any {
        return {
            id: this.id,
            health_section_id: this.healthSectionId,
            name: this.name,
            lead_paragraph: this.leadParagraph,
            content: this.content,
            published: this.published,
            published_at: this.publishedAt.toISOString(),
            translations: this.translations,
        }
    }
}
