
<div>
    <app-bar :title="t('glossary_title')">
        <template #actions>
            <div class="px-10">
                <v-btn
                    :to="{ name: 'glossary-sections.create' }"
                    class="sm sm-h"
                    color="orange"
                >{{ t('glossary_section_title_add') }}</v-btn>
            </div>
        </template>
    </app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-tabs
            class="px-4 my-7"
            height="52"
        >
            <div class="tabs-line"></div>
            <v-tab
                :to="{ name: 'glossary-sections' }"
                class="pl-0"
            >{{ t('glossary_tab_sections') }}</v-tab>
            <v-tab
                :to="{ name: 'glossary-definitions' }"
            >{{ t('glossary_tab_definitions') }}</v-tab>

            <v-tabs-items>
                <card-collection
                    class="px-0-nested"
                    :meta="meta"
                    :busy="overlay"
                    @updated="updated"
                >
                    <template #data>
                        <thead>
                        <tr>
                            <th class="touched">
                                <sort-field
                                    v-model="queries.sort"
                                    field="id"
                                    @touched="onSort"
                                >{{ t('glossary_section_label_id') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="name"
                                    @touched="onSort"
                                >{{ t('glossary_section_label_name') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="created_at"
                                    @touched="onSort"
                                >{{ t('general_label_creation_date') }}
                                </sort-field>
                            </th>
                            <th>{{ t('glossary_section_label_definitions') }}</th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="published"
                                    @touched="onSort"
                                >{{ t('glossary_section_label_published') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="sort_order"
                                    @touched="onSort"
                                >{{ t('glossary_section_label_sort_order') }}
                                </sort-field>
                            </th>
                            <th class="text-center">{{ t('general_label_action') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="collection.data.length === 0">
                            <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                        </tr>
                        <template v-else v-for="section in collection.data">
                            <tr>
                                <td>
                                    <a
                                        :href="`/glossary/sections/${section.id}/view`"
                                        :class="{ 'orange--text': ! section.published }"
                                        @click.prevent="touch(section)"
                                    >{{ section.id }}</a>
                                </td>
                                <td>
                                    <a
                                        :href="`/glossary/sections/${section.id}/view`"
                                        @click.prevent="touch(section)"
                                    >{{ section.name }}</a>
                                </td>
                                <td>
                                    {{ section.createdAt.format('DD/MM/YYYY') }}
                                </td>
                                <td>{{ section.definitionsCount }}</td>
                                <td>
                                    {{ section.published ? t('general_text_yes') : t('general_text_no') }}
                                </td>
                                <td>{{ section.sortOrder }}</td>
                                <td class="text-center">
                                    <v-menu
                                        offset-y
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click.prevent="edit(section)"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_edit') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click.prevent="suspend(section)"
                                            >
                                                <v-list-item-title
                                                    v-if="section.published"
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_suspend') }}
                                                </v-list-item-title>
                                                <v-list-item-title
                                                    v-else
                                                    class="orange--text"
                                                >{{ t('general_action_unsuspend') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <confirm-modal
                                                :title="t('general_title_confirm_action')"
                                                @agree="del(section)"
                                            >
                                                <template #default="{ on, attrs }">
                                                    <v-list-item
                                                        href
                                                        v-on="on"
                                                        v-bind="attrs"
                                                    >
                                                        <v-list-item-title
                                                            class="customized-deep-primary--text"
                                                        >{{ t('general_action_delete') }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </confirm-modal>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </card-collection>
            </v-tabs-items>
        </v-tabs>
    </v-layout>
</div>
