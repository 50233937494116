
<div>
    <app-bar
        :title="group.name"
    />

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div v-if="permitted('admin_group_management')" class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}</v-btn>
                <v-btn
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prgroup="suspend(group)"
                >
                    {{ group.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <v-btn
                    class="sm"
                    color="orange"
                    outlined
                    @click.prgroup="del(group)"
                >{{ t('general_action_delete') }}</v-btn>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <img
                    class=""
                    v-if="group.photoUrl"
                    :src="group.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('group_label_description') }}:</div>
                <div class="form-subtitle-description" v-html="group.description"></div>
                <card-unit :items="items()">
                    <template #title>{{ t('group_label_id') }}: {{ group.id }}</template>
                </card-unit>
                <template v-if="group.photos && group.photos.length">
                    <div class="form-subtitle mb-3">{{ t('group_label_photos') }}:</div>
                    <v-row>
                        <v-col
                            v-for="photo in group.photos"
                            :key="photo"
                            cols="6"
                        >
                            <v-sheet
                                color="white"
                                elevation="0"
                                height="185"
                                width="185"
                                rounded="lg"
                                class="pt-6"
                            >
                                <v-img
                                    :lazy-src="photo"
                                    :src="photo"
                                    max-height="135"
                                    max-width="135"
                                    class="mx-auto"
                                ></v-img>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </template>
            </div>
            <div class="float-left mr-10">
                <v-tabs
                    v-model="currentTab"
                    height="52"
                >
                    <div class="tabs-line"></div>
                    <v-tabs-slider></v-tabs-slider>

                    <template>
                        <v-tab class="pl-0">{{ t('group_label_members') }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                untitled
                                :items="members.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                <template #actions>
                                    <v-card-actions
                                        class="px-0 mx-n3 mt-4"
                                        v-if="memberMeta && memberMeta.total > 0"
                                    >
                                        <v-pagination
                                            v-model="memberQueries.page"
                                            :length="memberPageCount"
                                            total-visible="7"
                                        ></v-pagination>
                                        <span class="pagination-meta">{{ t('general_pagination_meta', { from: memberMeta.from, to: memberMeta.to, total: memberMeta.total }) }}</span>
                                    </v-card-actions>
                                </template>
                            </card-unit>
                            <v-btn
                                v-if="members.data.length"
                                @click="_exportUsers"
                                class="exs mt-2"
                                color="primary"
                                small
                            >{{ t('general_action_export') }}</v-btn>
                        </v-tab-item>
                    </template>
                </v-tabs>
            </div>
        </div>

    </v-layout>
</div>
