<template>
    <div>
        <app-bar :title="t('events_title')"></app-bar>

        <v-layout>
            <event-collection></event-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import EventCollection from '@/components/events/EventCollection.vue'

    export default defineComponent({
        components: {
            EventCollection,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({})

            return {
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>