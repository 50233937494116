import { useHttp } from '../http'

export const useHealthArticleHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`health-articles/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`health-articles`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`health-articles/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`health-articles`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`health-articles/${id}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`health-articles/${id}/photo`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo
    }
}