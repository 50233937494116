import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'
import { Stroke, StrokePayload } from '@/core/models/stroke'

export interface RaceExecutionPayload {
    id: number
    user_id: number
    stroke_id: number
    name: string
    photo_url: string
    distance: number
    distance_unit: string
    duration: string
    calories_burned: number
    steps: number
    avg_pace: string
    swimming_location: string
    raced_at: string
    user?: UserPayload
    stroke?: StrokePayload
}

export class RaceExecution {

    id: number
    userId: number
    strokeId: number
    name: string
    photoUrl: string
    photo?: File
    distance: number
    distanceUnit: string
    duration: string
    caloriesBurned: number
    steps: number
    avgPace: string
    swimmingLocation: string
    racedAt: Moment
    user: User
    stroke: Stroke

    constructor(data: RaceExecutionPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.name = data.name
        this.photoUrl = data.photo_url
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = data.duration
        this.caloriesBurned = data.calories_burned
        this.steps = data.steps
        this.avgPace = data.avg_pace
        this.swimmingLocation = data.swimming_location
        this.racedAt = moment(data.raced_at)
        this.user = new User(data.user || <any>{})
        this.stroke = new Stroke(data.stroke || <any>{})
        this.capture(data)
    }

    fill(data: RaceExecutionPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.name = data.name
        this.photoUrl = data.photo_url
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = data.duration
        this.caloriesBurned = data.calories_burned
        this.steps = data.steps
        this.avgPace = data.avg_pace
        this.swimmingLocation = data.swimming_location
        this.racedAt = moment(data.raced_at)
        this.user = new User(data.user || <any>{})
        this.stroke = new Stroke(data.stroke || <any>{})
        this.capture(data)
        return this
    }

    capture(data: RaceExecutionPayload): this {
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.photo = undefined
        return this
    }
}
