import moment from 'moment/moment'

export const capturedDays = (): any[] => {
    let arr = <any>[]
    for (let i = 1; i <= 31; i++) {
        arr.push({ text: i, value: (i < 10 ? '0'  : '') + i })
    }
    return arr
}

export const capturedMonths = (): any[] => {
    let arr = <any>[]
    for (let i = 1; i <= 12; i++) {
        arr.push({ text: i, value: (i < 10 ? '0'  : '') + i })
    }
    return arr
}

export const capturedYears = (): any[] => {
    let arr = <any>[]
    for (let i = moment().year(); i >= 1950; i--) {
        arr.push({ text: i, value: i + '' })
    }
    return arr
}

export const timeStringToMilliseconds = (time: string): number => {
    let args = moment('2000-01-01 ' + time)
    return (args.hours() * 3600 + args.minutes() * 60 + args.seconds()) * 1000 + args.milliseconds()
}

export const millisecondsToTimeString = (ms: number, short: boolean = false): string => {
    let seconds = Math.floor(ms / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    let milliseconds = ms % 1000
    seconds = seconds % 60
    minutes = minutes % 60
    let hoursStr = hours < 10 ? '0' + hours : hours
    let minutesStr = minutes < 10 ? '0' + minutes : minutes
    let secondsStr = seconds < 10 ? '0' + seconds : seconds
    let millisecondsStr = milliseconds.toString()
    if (milliseconds < 10) {
        millisecondsStr = '0' + millisecondsStr
    }
    if (milliseconds < 100) {
        millisecondsStr = '0' + millisecondsStr
    }
    if (short) {
        return `${hoursStr}:${minutesStr}:${secondsStr}`
    } else {
        return `${hoursStr}:${minutesStr}:${secondsStr}.${millisecondsStr}`
    }
}