import { Module } from 'vuex'
import { RootState } from '../store-types'
import { usePageHttp } from '@/core/api/pages'
import { Page, PagePayload } from '@/core/models/page'

const state: Page = new Page(<PagePayload>{})

export const page: Module<Page, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Page, payload: PagePayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                usePageHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { id: number, payload: PagePayload }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                usePageHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Page): Page => {
            return state
        }
    }
}