import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { useRaceExecutionHttp } from '@/core/api/race-executions'
import { RaceExecution, RaceExecutionPayload } from '@/core/models/race-execution'

export type RaceExecutionCollection = {
    data: Array<RaceExecution>,
    meta?: MetaPayload,
    links?: any
}

const state: RaceExecutionCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const raceExecutionCollection: Module<RaceExecutionCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: RaceExecutionCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: RaceExecutionPayload) => {
                state.data.push(new RaceExecution(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useRaceExecutionHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: RaceExecutionCollection): RaceExecutionCollection => {
            return state
        }
    }
}