<template>
    <div>
        <app-bar :title="t('users_title')"></app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <v-tabs
                class="px-4 my-7"
                height="52"
            >
                <div class="tabs-line"></div>
                <v-tab
                    :to="{ name: 'users' }"
                    class="pl-0"
                >{{ t('users_title') }}</v-tab>
                <v-tab
                    :to="{ name: 'roles' }"
                >{{ t('roles_title') }}</v-tab>
                <v-tab
                    :to="{ name: 'permissions' }"
                >{{ t('permissions_title') }}</v-tab>

                <v-tabs-items>
                    <card-collection
                        class="px-0-nested"
                        :meta="meta"
                        :busy="overlay"
                        @updated="updated"
                    >
                        <template #filters>
                            <v-toolbar
                                elevation="0"
                                color="transparent"
                                class="mt-7"
                                height="56"
                            >
                                <v-text-field
                                    v-model="queries.search"
                                    @click:clear="clear"
                                    class="input-search md mr-2"
                                    clearable
                                    outlined
                                    dense
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :label="t('general_label_search')"
                                ></v-text-field>

                                <v-select
                                    v-model="queries.role"
                                    :items="roles"
                                    class="md mr-2"
                                    outlined
                                    dense
                                    append-icon="mdi-chevron-down"
                                    hide-details
                                    :label="t('user_label_role')"
                                ></v-select>

                                <v-select
                                    v-model="queries.status"
                                    :items="[ { text: '-', value: '' }, { text: userStatusTitle('active'), value: 'active' }, { text: userStatusTitle('suspended'), value: 'suspended' } ]"
                                    class="md mr-2"
                                    outlined
                                    dense
                                    append-icon="mdi-chevron-down"
                                    hide-details
                                    :label="t('user_label_status')"
                                ></v-select>

                                <v-btn
                                    @click="search"
                                    class="sm mx-5"
                                    color="primary"
                                >{{ t('general_action_search') }}</v-btn>

                                <v-spacer></v-spacer>

                                <v-btn
                                    v-ripple="false"
                                    plain
                                    color="orange darken-4"
                                    @click="reset"
                                >{{ t('general_action_reset_filters') }}</v-btn>

                            </v-toolbar>
                        </template>

                        <template #data>
                            <thead>
                            <tr>
                                <th class="touched">
                                    <sort-field
                                        v-model="queries.sort"
                                        field="id"
                                        @touched="onSort"
                                    >{{ t('user_label_id') }}</sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="last_name"
                                        @touched="onSort"
                                    >{{ t('user_label_last_name') }}</sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="first_name"
                                        @touched="onSort"
                                    >{{ t('user_label_first_name') }}</sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="email"
                                        @touched="onSort"
                                    >{{ t('user_label_email_address') }}</sort-field>
                                </th>
                                <th>{{ t('user_label_role') }}</th>
                                <th>{{ t('user_label_status') }}</th>
                                <th v-if="permitted('*')" class="text-center">{{ t('general_label_action') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="collection.data.length === 0">
                                <td class="text-center" :colspan="permitted('*') ? 7 : 6">{{ t('general_text_no_data') }}</td>
                            </tr>
                            <template v-else v-for="user in collection.data">
                                <tr>
                                    <td>{{ user.id }}</td>
                                    <td>{{ user.lastName }}</td>
                                    <td>{{ user.firstName }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.rolesStrTitle }}</td>
                                    <td>{{ userStatusTitle(user.status) }}</td>
                                    <td v-if="permitted('*')" class="text-center">
                                        <v-menu
                                            offset-y
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                            </template>
                                            <v-list dense>
                                                <user-role-modal
                                                    :value="user"
                                                    @submit="capture"
                                                >
                                                    <template #default="{ on, attrs }">
                                                        <v-list-item
                                                            href
                                                            v-on="on"
                                                            v-bind="attrs"
                                                        >
                                                            <v-list-item-title
                                                                class="customized-deep-primary--text"
                                                            >{{ t('user_action_update_roles') }}</v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                </user-role-modal>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </template>
                    </card-collection>
                </v-tabs-items>
            </v-tabs>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { useLocales } from '@/core/defaults'
    import { UserCollection } from '@/store/modules/user-collection'
    import { Role } from '@/core/models/role'
    import UserRoleModal from '@/components/users/UserRoleModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import { User } from "@/core/models/user";

    export default defineComponent({
        components: {
            UserRoleModal,
            CardCollection,
            SortField,
            ConfirmModal,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                meta: undefined
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                relations: 'roles',
                role: undefined,
                status: undefined,
                statuses: 'active,suspended',
            })

            const reset = () => {
                queries.search = undefined
                queries.page = 1
                queries.per_page = 20
                queries.sort = '-id'
                queries.role = undefined
                queries.status = undefined
                capture()
            }

            const store = useStore()

            const collection = computed(() => <UserCollection>store.getters['userCollection/get'])
            const roles = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['roleCollection/get'].data.map((item: Role) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const del = (user: User) => {
                state.overlay = true
                store.dispatch('user/delete', { id: user.id }).then(() => {
                    capture()
                })
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('userCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
                queries.role,
                queries.status
            ], () => {
                capture()
            })

            onMounted(() => {
                store.dispatch('roleCollection/get', { sort: 'id' })
                capture()
            })

            return {
                queries,
                collection: collection.value,
                roles,
                capture,
                updated,
                onSort,
                search,
                clear,
                reset,
                del,
                ...useI18n(),
                ...useEnums(),
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>
