import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { useUserStatisticHttp } from '@/core/api/user-statistics'
import { UserStatistic, UserStatisticPayload } from '@/core/models/user-statistic'

export type UserStatisticCollection = {
    data: Array<UserStatistic>,
    meta?: MetaPayload,
    links?: any
}

const state: UserStatisticCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const userStatisticCollection: Module<UserStatisticCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: UserStatisticCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: UserStatisticPayload) => {
                state.data.push(new UserStatistic(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useUserStatisticHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: UserStatisticCollection): UserStatisticCollection => {
            return state
        }
    }
}