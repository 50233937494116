<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="article.exists() ? t('health_article_title_update') : t('health_article_title_create')"
            back="/health/articles"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_article_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="article.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="article.name"
                                    :items="article.translations['name']"
                                    @touch="(v) => article.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal-cols">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('health_article_label_section') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="article.healthSectionId"
                                        :items="sections"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('health_section_id')"
                                    ></v-select>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('general_label_publishing_date') }}</div>
                                <div class="horizontal-input">
                                    <v-text-field
                                        v-model="publishedAt"
                                        type="datetime-local"
                                        required
                                        outlined
                                        dense
                                        :error-messages="form.errors.get('published_at')"
                                    ></v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_article_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="article.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="article.photoUrl ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="photoForm.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="article.photo = undefined"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_article_label_lead_paragraph') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="article.leadParagraph"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('lead_paragraph')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="article.leadParagraph"
                                    :items="article.translations['lead_paragraph']"
                                    @touch="(v) => article.translations['lead_paragraph'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_article_label_content') }}</div>
                            <div class="horizontal-input">
                                <tinymce key="lead_content" v-model="article.content"></tinymce>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    editor
                                    v-model="article.content"
                                    :items="article.translations['content']"
                                    @touch="(v) => article.translations['content'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_action_published') }}</div>
                            <div class="horizontal-input">
                                <v-switch
                                    class="ma-0"
                                    v-model="article.published"
                                ></v-switch>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'articles' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ article.exists() ? t('general_action_save') : t('health_article_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { HealthArticle } from '@/core/models/health-article'
    import { HealthSection } from '@/core/models/health-section'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import Tinymce from '@/components/Tinymce.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal,
            DatePickerModal,
            Tinymce,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id'] || undefined

            const enums = useEnums()

            const state = reactive({
                overlay: true,
                publishedAt: <string | undefined>undefined
            })

            const articleModel = computed(() => <HealthArticle>store.getters['healthArticle/get'])
            const sections = computed(
                () => [{ text: '-', value: '' }].concat(
                    store.getters['healthSectionCollection/get'].data.map((item: HealthSection) => {
                        return { text: item.name, value: item.id }
                    })
                )
            )

            const form = reactive(useForm())
            const photoForm = reactive(useForm())

            const capture = () => {
                store.dispatch('healthArticle/getOrNew', { id: id, payload: { append: 'translations', published: '' } }).then(() => {
                    state.overlay = false
                    state.publishedAt = articleModel.value.publishedAt.format('YYYY-MM-DDTHH:mm')
                })
            }

            const submit = () => {
                form.usingSubmit(store.dispatch('healthArticle/updateOrCreate', {
                    id: id,
                    payload: articleModel.value.payload()
                }))
                    .then(() => {
                        const id = <any>articleModel.value.id
                        const next = () => router.push({ name: 'articles.view', params: <any>{ id: id } })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                        if (articleModel.value.photo) {
                            const data = new FormData()
                            data.append('photo', articleModel.value.photo)
                            photoForm.usingSubmit(store.dispatch('healthArticle/photo', { id: id, payload: data }).then(() => {
                                next()
                            }))
                        } else {
                            next()
                        }
                    })
            }

            const viewPhoto = () => {
                window.open(articleModel.value.photoUrl)
            }

            watch(() => [state.publishedAt], (newValue: any) => {
                articleModel.value.publishedAt = moment(newValue, moment.HTML5_FMT.DATETIME_LOCAL)
            })

            onMounted(() => {
                store.dispatch('healthSectionCollection/get', { sort: '-id' })
                capture()
            })

            return {
                form,
                photoForm,
                capture,
                viewPhoto,
                submit,
                article: articleModel.value,
                sections,
                moment,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
