import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useLazyTranslationsHttp } from '@/core/api/lazy-translations'
import { LazyTranslation, LazyTranslationPayload } from '@/core/models/lazy-translation'

const state: LazyTranslation = new LazyTranslation(<LazyTranslationPayload>{})

export const lazyTranslation: Module<LazyTranslation, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: LazyTranslation, payload: LazyTranslationPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useLazyTranslationsHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        import: <T>(context: any, replace: boolean): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useLazyTranslationsHttp()
                    .postImport(replace)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        export: <T>(context: any, group?: string): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useLazyTranslationsHttp()
                    .postExport(group)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        find: <T>(context: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useLazyTranslationsHttp()
                    .find()
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: LazyTranslation): LazyTranslation => {
            return state
        }
    }
}