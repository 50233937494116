
<div>
    <app-bar :title="section.name"/>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(section)"
                >
                    {{ section.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <confirm-modal
                    :title="t('general_title_confirm_action')"
                    @agree="del(section)"
                >
                    <template #default="{ on, attrs }">
                        <v-btn
                            class="sm"
                            color="orange"
                            outlined
                            v-on="on"
                            v-bind="attrs"
                        >{{ t('general_action_delete') }}</v-btn>
                    </template>
                </confirm-modal>
            </div>
            <card-unit :items="[
                    { text: t('glossary_section_label_name'), value: section.name },
                    { text: t('general_label_creation_date'), value: section.createdAt.format('DD/MM/YYYY') },
                    { text: t('glossary_section_label_published'), value: section.published ? t('general_text_yes') : t('general_text_no') },
                    { text: t('glossary_section_label_sort_order'), value: section.sortOrder },
                    { text: t('glossary_section_label_definitions'), value: section.definitionsCount },
                ]">
                <template #title>{{ t('glossary_section_label_id') }}: {{ section.id }}</template>
            </card-unit>
        </div>
    </v-layout>
</div>
