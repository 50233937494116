import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useTranslationHttp } from '@/core/api/translations'
import { Translation, TranslationPayload } from '@/core/models/translation'

const state: Translation = new Translation(<TranslationPayload>{})

export const translation: Module<Translation, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Translation, payload: TranslationPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        update: <T>(context: any, payload: { id: number, payload: TranslationPayload }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useTranslationHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        updateOrCreate: <T>(context: any, payload: { payload: TranslationPayload }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useTranslationHttp()
                    .updateOrCreate(payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Translation): Translation => {
            return state
        }
    }
}