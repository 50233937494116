<template>
    <v-flex>
        <v-progress-linear
            v-if="overlay && ! (!! user)"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :class="{ 'px-0-nested': !! user, 'pt-7': ! (!! user) }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-btn
                        @click="extra = ! extra"
                        class="square mr-7 elevation-0"
                        color="primary-light"
                    ><v-icon size="18">{{ extra ? 'mdi-close' : 'mdi-cog' }}</v-icon></v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>

                <v-toolbar
                    v-if="extra"
                    elevation="0"
                    color="transparent"
                    height="56"
                    class="mt-2"
                >
                    <v-select
                        v-model="queries.swimming_location"
                        :items="[ { text: '-', value: '' } ].concat(locations())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_swimming_location')"
                    ></v-select>

                    <v-select
                        v-if="user"
                        v-model="queries.goal_id"
                        :items="goals"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_goals')"
                    ></v-select>

                    <v-select
                        v-else
                        v-model="queries.stroke_id"
                        :items="strokes"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_stroke')"
                    ></v-select>

                    <v-select
                        v-model="queries.duration"
                        :items="[ { text: '-', value: '' } ].concat(durationValues())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_duration')"
                    ></v-select>

                    <v-select
                        v-model="queries.creator"
                        :items="[ { text: '-', value: '' } ].concat(creators())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('general_label_created_by')"
                    ></v-select>

                    <v-select
                        v-model="queries.provider"
                        :items="[ { text: '-', value: '' } ].concat(activityProviders())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workout_label_provider')"
                    ></v-select>

                    <v-select
                        v-if="user"
                        v-model="queries.workout_type"
                        :items="[ { text: '-', value: '' } ].concat(workoutTypes())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_workout_type')"
                    ></v-select>

                    <v-select
                        v-else
                        v-model="queries.level"
                        :items="[ { text: '-', value: '' } ].concat(levels())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_level')"
                    ></v-select>
                </v-toolbar>

                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mb-0"
                    height="56"
                >
                    <v-checkbox
                        v-model="queries.only_active"
                        :label="t('workout_execution_label_show_active_users')"
                        hide-details
                        class="ma-0 customized-deep-primary-color"
                    />
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="workout_id"
                            @touched="onSort"
                        >{{ t('workout_label_id') }}
                        </sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="workout_name"
                            @touched="onSort"
                        >{{ t('workout_label_name') }}
                        </sort-field>
                    </th>
                    <th>{{ t('workout_label_swimming_location') }}</th>
                    <th v-if="user">{{ t('workout_label_goals') }}</th>
                    <th v-else>{{ t('workout_label_stroke') }}</th>
                    <th>{{ t('workout_label_duration') }}</th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="finished_at"
                            @touched="onSort"
                        >{{ t('workout_execution_label_finished_at') }}</sort-field>
                    </th>
                    <th>{{ t('workout_label_created_by') }}</th>
                    <th>{{ t('workout_label_provider') }}</th>
                    <th v-if="user">{{ t('workout_label_workout_types') }}</th>
                    <th v-else>{{ t('workout_label_level') }}</th>
                    <th v-if="! user">
                        <sort-field
                            v-model="queries.sort"
                            field="user_name"
                            @touched="onSort"
                        >{{ t('workout_execution_label_user_name') }}
                        </sort-field>
                    </th>
                    <th class="text-center" v-if="!! user">{{ t('general_label_action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="10">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="execution in collection.data">
                    <tr>
                        <td>
                            <a
                                href="javascript:"
                                @click.prevent="touch(execution)"
                            >{{ execution.workout.id }}</a>
                        </td>
                        <td>
                            <a
                                href="javascript:"
                                @click.prevent="touch(execution)"
                            >{{ execution.workout.name }}</a>
                        </td>
                        <td>
                            {{ locationTitle(execution.workout.swimmingLocation) }}
                        </td>
                        <td v-if="user">
                            {{ execution.workout.goalsStrTitle }}
                        </td>
                        <td v-else>
                            {{ execution.workout.stroke.name }}
                        </td>
                        <td>
                            {{ time.format(execution.totalTime) }}
                        </td>
                        <td>
                            {{ execution.finishedAt ? execution.finishedAt.format('DD/MM/YYYY HH:mm') : '-' }}
                        </td>
                        <td>
                            {{ creatorTitle(execution.workout.creator) }}
                        </td>
                        <td>
                            {{ activityProviderTitle(execution.workout.provider) }}
                        </td>
                        <td v-if="user">
                            {{ workoutTypesStrTitle(execution.workout.workoutTypes) }}
                        </td>
                        <td v-else>
                            {{ levelTitle(execution.workout.level) }}
                        </td>
                        <td v-if="! user" class="touched">
                            <a
                                href="javascript:"
                                @click.prevent="viewUser(execution.user)"
                            >{{ execution.user.fullName }}</a>
                        </td>
                        <td class="text-center">
                            <v-menu
                                offset-y
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click.prevent="touch(execution)"
                                    >
                                        <v-list-item-title
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_details') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <confirm-modal
                                        v-if="permitted('admin_workout_execution_management')"
                                        :title="t('general_title_confirm_action')"
                                        @agree="del(execution)"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                href
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </confirm-modal>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
            <template #export>
                <v-btn
                    @click="_export"
                    class="exs mt-4 ml-4"
                    color="primary"
                    small
                >{{ t('general_action_export') }}</v-btn>
            </template>
        </card-collection>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useRouter } from '@/composition-api'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { WorkoutExecution } from '@/core/models/workout-execution'
    import { WorkoutExecutionCollection } from '@/store/modules/workout-execution-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import SortField from '@/components/SortField.vue'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { Goal } from '@/core/models/goal'
    import { Stroke } from '@/core/models/stroke'
    import { User } from '@/core/models/user'
    import { useWorkoutExecutionHttp } from '@/core/api/workout-executions'

    export default defineComponent({
        name: 'WorkoutExecutionCollection',

        components: {
            DatePickerModal,
            CardCollection,
            ConfirmModal,
            SortField,
        },

        props: {
            user: {
                type: User
            },
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined,
                extra: false
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-finished_at',
                relations: 'user,workout,feedback,users,workout.sets,workout.stroke,workout_set_executions,workout_set_executions.set,workout_set_executions.set.stroke',
                finished_at_not_null: '1',
                user_id: props.user?.id,
                only_active: undefined,
                date_from: undefined,
                date_to: undefined,
                swimming_location: undefined,
                goal_id: undefined,
                stroke_id: undefined,
                level: undefined,
                duration: undefined,
                creator: undefined,
                provider: undefined,
                workout_type: undefined
            })

            const reset = () => {
                queries.search = undefined
                queries.date_from = undefined
                queries.date_to = undefined
                queries.swimming_location = undefined
                queries.goal_id = undefined
                queries.stroke_id = undefined
                queries.level = undefined
                queries.duration = undefined
                queries.creator = undefined
                queries.provider = undefined
                queries.workout_type = undefined
                queries.only_active = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <WorkoutExecutionCollection>store.getters['workoutExecutionCollection/get'])
            const goals = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['goalCollection/get'].data.map((item: Goal) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))
            const strokes = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const viewUser = (user: User) => {
                router.push({ name: 'trainees.show', params: <any>{ id: user.id }, query: { tab: '3' } })
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('workoutExecutionCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const touch = (executionModel: WorkoutExecution) => {
                context.emit('touched', executionModel)
            }

            const del = (executionModel: WorkoutExecution) => {
                state.overlay = true
                store.dispatch('workoutExecution/delete', { id: executionModel.id }).then(() => {
                    capture()
                })
            }

            const _export = () => {
                state.overlay = true
                useWorkoutExecutionHttp()._export(queries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'WorkoutExecutions.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-finished_at'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
                queries.swimming_location,
                queries.goal_id,
                queries.stroke_id,
                queries.level,
                queries.duration,
                queries.creator,
                queries.provider,
                queries.workout_type,
                queries.only_active,
            ], () => {
                capture()
            })

            onMounted(() => {
                collection.value.data = []
                store.dispatch('goalCollection/get', { sort: 'sort_order' })
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                capture()
            })

            return {
                time,
                queries,
                collection: collection.value,
                goals,
                strokes,
                moment,
                touch,
                del,
                _export,
                updated,
                viewUser,
                onSort,
                search,
                clear,
                reset,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
