
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="definition.exists() ? t('glossary_definition_title_update') : t('glossary_definition_title_create')"
        back="/glossary/definitions"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="definition.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="definition.name"
                                :items="definition.translations['name']"
                                @touch="(v) => definition.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_section') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="definition.glossarySectionId"
                                :items="sections"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                :error-messages="form.errors.get('glossary_section_id')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_content') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="definition.content"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('content')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="definition.content"
                                :items="definition.translations['content']"
                                @touch="(v) => definition.translations['content'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_goal') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="definition.goal"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('goal')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="definition.goal"
                                :items="definition.translations['goal']"
                                @touch="(v) => definition.translations['goal'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_strokes') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="definition.strokeIds"
                                :items="strokes.data"
                                item-text="name"
                                item-value="id"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                multiple
                                :error-messages="form.errors.get('strokes')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_video') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="definition.video"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('video')"
                            ></v-textarea>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('glossary_definition_label_sort_order') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="definition.sortOrder"
                                type="number"
                                step="1"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('sort_order')"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('general_action_published') }}</div>
                        <div class="horizontal-input">
                            <v-switch
                                class="ma-0"
                                v-model="definition.published"
                            ></v-switch>
                        </div>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4">
                <v-btn
                    :to="{ name: 'glossary-definitions' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prevent="submit"
                >{{ definition.exists() ? t('general_action_save') : t('glossary_definition_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
