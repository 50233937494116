<template>
    <v-overlay
        :value="overlay"
        :dark="false"
        opacity="0.5"
        color="#fff"
    >
        <v-progress-circular
            indeterminate
            size="48"
            color="primary"
        ></v-progress-circular>
    </v-overlay>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'

    export default defineComponent({

        name: 'Overlay',

        props: ['value'],

        /**
         * Component constructor.
         * @param props
         * @param context
         */
        setup(props, context) {

            const state = reactive({
                overlay: props.value
            })

            watch(() => props.value, (newValue) => {
                state.overlay = newValue
            })

            return {
                ...toRefs(state)
            }

        }
    })
</script>
