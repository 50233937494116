import Vue from 'vue'
import VueCompositionAPI, { reactive } from '@vue/composition-api'
import { useAuthService } from '@/core/api/auth'
import { useToken } from '@/core/utils/token'
import { User, UserPayload } from '@/core/models/user'

Vue.use(VueCompositionAPI)

const tokenHelper = useToken()

const state = reactive({
    user: new User(<UserPayload>{}),
})

export const useAuth = () => {

    const auth = <T>(): Promise<T> => {
        return new Promise<T>((resolve, reject) => {
            if (tokenHelper.getToken()) {
                const authService = useAuthService()
                authService.getUser()
                    .then((data: any) => {
                        state.user.fill(<UserPayload>data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            } else {
                resolve({} as T)
            }
        })
    }

    const user = (): User => {
        return state.user
    }

    const signed = (): boolean => {
        return !! state.user.id
    }

    const watchToken = () => {
        setInterval(() => {
            if (signed() && ! tokenHelper.getToken()) {
                window.location.reload()
            }
            console.log('guard.watchToken() status: watched')
        }, 5000)
    }

    const logout = () => {
        const authService = useAuthService()

        authService.logout()

        state.user.fill(<UserPayload>{})
    }

    return {
        auth,
        user,
        signed,
        watchToken,
        logout
    }
}

export const auth = {
    install: (VueInstance: typeof Vue, options: any) => {
        const auth = useAuth()

        VueInstance.prototype.$auth = auth
        VueInstance.prototype.permitted = (permission: string): boolean => {
            return auth.user().permitted(permission)
        }
        VueInstance.prototype.notPermitted = (permission: string): boolean => {
            return auth.user().notPermitted(permission)
        }
    }
}

Vue.use(auth)