import { useHttp } from '../http'

export const useUserStatisticHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`user-statistics`, payload)
    }

    const _export = <T>(payload: any): Promise<T> => {
        return useHttp().get(`user-statistics/export`, payload, { responseType: 'blob' })
    }

    const _exportProfiles = <T>(payload: any): Promise<T> => {
        return useHttp().get(`user-statistics/export-profiles`, payload, { responseType: 'blob' })
    }

    return {
        get,
        _export,
        _exportProfiles
    }
}