
<div>
    <app-bar
        :title="notification.title"
    />

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}</v-btn>
                <v-btn
                    class="sm"
                    color="orange"
                    outlined
                    @click.prnotification="del(notification)"
                >{{ t('general_action_delete') }}</v-btn>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <img
                    class=""
                    v-if="notification.photoUrl"
                    :src="notification.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('notification_label_message') }}:</div>
                <div class="form-subtitle-description" v-html="notification.message"></div>
                <card-unit :items="items()">
                    <template #title>{{ t('notification_label_id') }}: {{ notification.id }}</template>
                </card-unit>
            </div>
            <div class="float-left mr-10">
                <v-tabs
                    v-model="currentTab"
                    height="52"
                >
                    <div class="tabs-line"></div>
                    <v-tabs-slider></v-tabs-slider>

                    <template v-if="userMeta && userMeta.total > 0">
                        <v-tab class="pl-0">{{ t('notification_label_attendees') }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                untitled
                                :items="users.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                <template #actions>
                                    <v-card-actions
                                        class="px-0 mx-n3 mt-4"
                                        v-if="userMeta && userMeta.total > 0"
                                    >
                                        <v-pagination
                                            v-model="userQueries.page"
                                            :length="userPageCount"
                                            total-visible="7"
                                        ></v-pagination>
                                        <span class="pagination-meta">{{ t('general_pagination_meta', { from: userMeta.from, to: userMeta.to, total: userMeta.total }) }}</span>
                                    </v-card-actions>
                                </template>
                            </card-unit>
                            <v-btn
                                v-if="users.data.length"
                                @click="_exportUsers"
                                class="exs mt-2"
                                color="primary"
                                small
                            >{{ t('general_action_export') }}</v-btn>
                        </v-tab-item>
                    </template>
                </v-tabs>
            </div>
        </div>

    </v-layout>
</div>
