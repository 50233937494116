<template>
    <v-flex>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-app-bar
            v-if="used"
            color="white"
            height="84"
            elevation="0"
            class="px-6"
        >
            <v-toolbar-title>{{ t('users_title') }}</v-toolbar-title>
            <v-btn
                @click="unused(true)"
                plain
                icon
                absolute
                right
                color="customized-primary"
                class="mr-8"
            >
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
                @click="unused()"
                plain
                icon
                absolute
                right
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>

        <card-collection
            :class="{ 'px-6': used }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-select
                        v-model="queries.preferred_locale"
                        :items="[ { text: '-', value: '' } ].concat(locales())"
                        class="md mx-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_language')"
                    ></v-select>

                    <v-select
                        v-model="queries.os"
                        :items="[ { text: '-', value: '' } ].concat(oss())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_os_used')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}</v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}</v-btn>

                </v-toolbar>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                >
                    <v-select
                        v-model="queries.gender"
                        :items="[ { text: '-', value: '' } ].concat(genders())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_gender')"
                    ></v-select>

                    <v-select
                        v-model="queries.stroke_id"
                        :items="strokes"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_fav_stroke')"
                    ></v-select>

                    <v-select
                        v-model="queries.goal_id"
                        :items="goals"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_goals')"
                    ></v-select>

                    <v-select
                        v-model="queries.age"
                        :items="[ { text: '-', value: '' } ].concat(ageValues())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_age')"
                    ></v-select>

                    <v-select
                        v-model="queries.activity_providers"
                        :items="activityProviders(true)"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        multiple
                        :label="t('user_label_provider')"
                    ></v-select>
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="id"
                            @touched="onSort"
                        >{{ t('user_label_id') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="last_name"
                            @touched="onSort"
                        >{{ t('user_label_last_name') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="first_name"
                            @touched="onSort"
                        >{{ t('user_label_first_name') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="email"
                            @touched="onSort"
                        >{{ t('user_label_email_address') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="registered_at"
                            @touched="onSort"
                        >{{ t('user_label_registration_date') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="preferred_locale"
                            @touched="onSort"
                        >{{ t('user_label_language') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="country_code_iso"
                            @touched="onSort"
                        >{{ t('user_label_country') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="city"
                            @touched="onSort"
                        >{{ t('user_label_city') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="provider"
                            @touched="onSort"
                        >{{ t('user_label_provider') }}</sort-field>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="9">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="user in collection.data">
                    <tr>
                        <td>{{ user.id }}</td>
                        <td>{{ user.lastName }}</td>
                        <td>{{ user.firstName }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.registeredAt.format('DD/MM/YYYY') }}</td>
                        <td>{{ localeTitle(user.preferredLocale) }}</td>
                        <td>{{ user.countryCodeIso }}</td>
                        <td>{{ user.city }}</td>
                        <td>{{ providerTitle(user.provider) }}</td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { useLocales } from '@/core/defaults'
    import { UserStatisticCollection } from '@/store/modules/user-statistic-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'
    import { Stroke } from '@/core/models/stroke'
    import { Goal } from '@/core/models/goal'

    export default defineComponent({
        components: {
            DatePickerModal,
            CardCollection,
            SortField,
        },

        props: {
            used: Boolean
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                date_from: undefined,
                date_to: undefined,
                preferred_locale: undefined,
                activity_providers: <any>undefined,
                gender: undefined,
                stroke_id: undefined,
                goal_id: undefined,
                level: undefined,
                age: undefined,
                os: undefined,
                status: 'active',
            })

            const reset = () => {
                queries.search = undefined
                queries.page = 1
                queries.per_page = 20
                queries.sort = '-id'
                queries.date_from = undefined
                queries.date_to = undefined
                queries.preferred_locale = undefined
                queries.activity_providers = undefined
                queries.gender = undefined
                queries.stroke_id = undefined
                queries.goal_id = undefined
                queries.level = undefined
                queries.age = undefined
                queries.os = undefined
                capture()
            }

            const store = useStore()

            const collection = computed(() => <UserStatisticCollection>store.getters['userStatisticCollection/get'])
            const strokes = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))
            const goals = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['goalCollection/get'].data.map((item: Goal) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))

            const getQueries = () => {
                let payload = <any>{}
                Object.assign(payload, queries)
                if (queries.activity_providers instanceof Array) {
                    payload.activity_providers = queries.activity_providers.join(',')
                }
                return payload
            }

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                store.dispatch('userStatisticCollection/get', getQueries()).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            const unused = (used: boolean = false) => {
                context.emit('unused', (used ? getQueries() : undefined))
            }

            watch(() => [
                queries.sort,
                queries.preferred_locale,
                queries.gender,
                queries.stroke_id,
                queries.goal_id,
                queries.level,
                queries.age,
                queries.os,
            ], () => {
                capture()
            })

            onMounted(() => {
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                store.dispatch('goalCollection/get', { sort: 'sort_order' })
                capture()
            })

            return {
                queries,
                collection: collection.value,
                strokes,
                goals,
                updated,
                onSort,
                search,
                clear,
                reset,
                unused,
                ...useI18n(),
                ...useEnums(),
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>