
<div class="page-customized">
    <workout-collection
        v-if="collect"
        used
        @unused="unused"
    ></workout-collection>

    <template v-else>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="program.exists() ? t('program_title_update') : t('program_title_create')"
            back="/programs"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('program_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="program.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="program.name"
                                    :items="program.translations['name']"
                                    @touch="(v) => program.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('program_label_description') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="program.description"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('description')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="program.description"
                                    :items="program.translations['description']"
                                    @touch="(v) => program.translations['description'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div
                            v-if="program.creator === 'openswim'"
                            class="form-group-horizontal-cols"
                        >
                            <div class="form-group-horizontal form-group-horizontal-col-1">
                                <div class="horizontal-label">{{ t('program_label_coach') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.userId"
                                        :items="users"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('user_id')"
                                    ></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('program_label_coach_advice') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="program.coachAdvice"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('coach_advice')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="program.coachAdvice"
                                    :items="program.translations['coach_advice']"
                                    @update="(v) => program.translations['coach_advice'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal-cols">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_photo') }}</div>
                                <div class="horizontal-input">
                                    <v-file-input
                                        v-model="program.photo"
                                        required
                                        outlined
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-paperclip"
                                        :append-outer-icon="program.photoUrl ? 'mdi-eye-outline' : ''"
                                        dense
                                        :error-messages="form.errors.get('photo')"
                                        @click:append-outer="viewPhoto()"
                                        @click:clear="program.photo = undefined"
                                    ></v-file-input>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_next_program') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.nextProgramId"
                                        :items="nextPrograms"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('next_program_id')"
                                    ></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-horizontal-cols">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_sport') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.sportType"
                                        :items="sportTypes()"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        disabled
                                        :error-messages="form.errors.get('sport_type')"
                                    ></v-select>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_level') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.level"
                                        :items="levels()"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('level')"
                                    ></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-horizontal-cols">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_workout_types') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.workoutTypes"
                                        :items="workoutTypes()"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        multiple
                                        :error-messages="form.errors.get('workout_types')"
                                    ></v-select>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_dominant_stroke') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.strokeId"
                                        :items="strokes.data"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('stroke_id')"
                                    ></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-horizontal-cols">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_duration') }}</div>
                                <div class="horizontal-input">
                                    <v-text-field
                                        :value="program.weekSchedules.length"
                                        required
                                        outlined
                                        dense
                                        readonly
                                        append-outer-icon="mdi-plus-circle"
                                        @click:append-outer="addWeek()"
                                        :error-messages="form.errors.get('workout_schedules')"
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('program_label_goals') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="program.goalIds"
                                        :items="goals.data"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        multiple
                                        :error-messages="form.errors.get('training_program_goals')"
                                    ></v-select>
                                </div>
                            </div>
                        </div>

                        <v-tabs
                            v-if="program.weekSchedules.length"
                            v-model="currentWeekTab"
                            height="52"
                        >
                            <div class="tabs-line"></div>
                            <v-tabs-slider></v-tabs-slider>

                            <template v-for="(weekSchedule, index) in program.weekSchedules">
                                <v-tab
                                    :key="`week_${index}`"
                                    :class="{ 'pl-0': index === 0, 'red--text': form.errors.consists(`workout_schedules.${index}`) }"
                                >{{ t('program_workout_week_number_prefix') }} {{ index + 1 }}</v-tab>
                                <v-tab-item
                                    :key="`week_item_${index}`"
                                    class="py-5"
                                >
                                    <v-btn
                                        @click="collect = true"
                                        v-ripple="false"
                                        plain
                                        color="orange darken-4"
                                        class="add mr-3"
                                    >
                                        <v-icon left size="16">mdi-plus-circle</v-icon>{{ t('program_workout_button_add_new') }}
                                    </v-btn>
                                    <v-btn
                                        @click="delWeek(index)"
                                        class="exs mr-3"
                                        color="orange"
                                        small
                                    >{{ t('general_action_delete') }}</v-btn>
                                    <v-menu
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="exs mr-3"
                                                color="primary"
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                            >{{ t('general_action_move') }}</v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                :key="`set_card_item_${index}_${i}`"
                                                @click="moveWeek(index, i)"
                                                v-for="(v, i) in program.weekSchedules"
                                                :disabled="index === i"
                                                link
                                            >
                                                <v-list-item-title>{{ i + 1 }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-btn
                                        @click="duplicateWeek(index)"
                                        class="exs mr-5"
                                        color="primary"
                                        outlined
                                        small
                                    >{{ t('general_action_duplicate') }}</v-btn>
                                    <v-simple-table class="transparent">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-for="(schedule, number) in weekSchedule">
                                                    <td class="titled">{{ schedule.workout.id }}</td>
                                                    <td class="titled">{{ schedule.workout.name }}</td>
                                                    <td>{{ sportTypeTitle(schedule.workout.sportType) }}</td>
                                                    <td>{{ schedule.workout.stroke.name }}</td>
                                                    <td>{{ workoutTypesStrTitle(schedule.workout.workoutTypes) }}</td>
                                                    <td>{{ schedule.workout.goalsStrTitle }}</td>
                                                    <td>{{ time.format(schedule.workout.workoutTotalTime) }}</td>
                                                    <td class="text-center">
                                                        <v-menu
                                                            offset-y
                                                            left
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    icon
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list dense>
                                                                <v-list-item
                                                                    v-if="number > 0"
                                                                    @click.prevent="moveWorkout(index, number, number - 1)"
                                                                >
                                                                    <v-list-item-title
                                                                        class="customized-primary--text"
                                                                    >{{ t('general_action_move_up') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                    v-if="number < weekSchedule.length - 1"
                                                                    @click.prevent="moveWorkout(index, number, number + 1)"
                                                                >
                                                                    <v-list-item-title
                                                                        class="customized-primary--text"
                                                                    >{{ t('general_action_move_down') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                    @click.prevent="delWorkout(index, number)"
                                                                >
                                                                    <v-list-item-title
                                                                        class="orange--text"
                                                                    >{{ t('general_action_delete') }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-tab-item>
                            </template>
                        </v-tabs>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'programs' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ program.exists() ? t('general_action_save') : t('program_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </template>

</div>
