
<div
    class="touched"
    :class="{ 'primary--text': isTouched() }"
    @click="touch"
>
    <slot></slot>
    <template v-if="touched === field">
        <v-icon size="12">mdi-chevron-down</v-icon>
    </template>
    <template v-else-if="touched === '-' + field">
        <v-icon size="12">mdi-chevron-up</v-icon>
    </template>
    <template v-else>
        <v-icon size="12">mdi-sort</v-icon>
    </template>
</div>
