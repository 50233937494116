<template>
    <v-flex>
        <v-progress-linear
            v-if="overlay && ! (!! user)"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :class="{ 'px-0-nested': !! user, 'pt-7': ! (!! user) }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-btn
                        @click="extra = ! extra"
                        class="square mr-7 elevation-0"
                        color="primary-light"
                    ><v-icon size="18">{{ extra ? 'mdi-close' : 'mdi-cog' }}</v-icon></v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>

                <v-toolbar
                    v-if="extra"
                    elevation="0"
                    color="transparent"
                    height="56"
                    class="mt-2"
                >
                    <v-select
                        v-model="queries.status"
                        :items="[ { text: '-', value: '' } ].concat(programStatuses())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('program_execution_label_status')"
                    ></v-select>

                    <v-select
                        v-model="queries.level"
                        :items="[ { text: '-', value: '' } ].concat(levels())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('programs_label_level')"
                    ></v-select>

                    <v-select
                        v-model="queries.stroke_id"
                        :items="strokes"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('programs_label_stroke')"
                    ></v-select>

                    <v-select
                        v-model="queries.calendar_duration"
                        :items="[ { text: '-', value: '' } ].concat(calendarDurationValues())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('programs_label_duration')"
                    ></v-select>
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="training_program_id"
                            @touched="onSort"
                        >{{ t('program_label_id') }}
                        </sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="training_program_name"
                            @touched="onSort"
                        >{{ t('program_label_name') }}
                        </sort-field>
                    </th>
                    <th>{{ t('program_execution_label_status') }}</th>
                    <th>{{ t('program_label_level') }}</th>
                    <th>{{ t('program_label_stroke') }}</th>
                    <th>{{ t('program_execution_label_started_at') }}</th>
                    <th>{{ t('program_execution_label_finished_at') }}</th>
                    <th>{{ t('program_label_duration') }}</th>
                    <th>{{ t('program_execution_label_progress_done') }}</th>
                    <th class="text-center" v-if="!! user">{{ t('general_label_action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="10">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="execution in collection.data">
                    <tr>
                        <td>
                            <a
                                href="javascript:"
                                @click.prevent="touch(execution)"
                            >{{ execution.program.id }}</a>
                        </td>
                        <td>
                            <a
                                href="javascript:"
                                @click.prevent="touch(execution)"
                            >{{ execution.program.name }}</a>
                        </td>
                        <td :class="{ 'green--text': execution.status === 'finished' }">
                            <strong>{{ programStatusTitle(execution.status) }}</strong>
                        </td>
                        <td>
                            {{ levelTitle(execution.program.level) }}
                        </td>
                        <td>
                            {{ execution.program.stroke.name }}
                        </td>
                        <td>
                            {{ execution.startedAt.format('DD/MM/YYYY HH:mm') }}
                        </td>
                        <td>
                            {{ execution.finishedAt.format('DD/MM/YYYY HH:mm') }}
                        </td>
                        <td>
                            {{ execution.program.calendarDuration }}  {{ calendarDurationUnitTitle(execution.program.calendarDurationUnit).toLowerCase() }}
                        </td>
                        <td>
                            {{ execution.progressPercentage }}
                        </td>
                        <td v-if="! user" class="touched">
                            <a
                                href="javascript:"
                                @click.prevent="viewUser(execution.user)"
                            >{{ execution.user.fullName }}</a>
                        </td>
                        <td class="text-center">
                            <v-menu
                                offset-y
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click.prevent="touch(execution)"
                                    >
                                        <v-list-item-title
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_details') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <confirm-modal
                                        v-if="permitted('admin_program_execution_management')"
                                        :title="t('general_title_confirm_action')"
                                        @agree="del(execution)"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                href
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </confirm-modal>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useRouter } from '@/composition-api'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { TrainingProgramExecution } from '@/core/models/training-program-execution'
    import { ProgramExecutionCollection } from '@/store/modules/program-execution-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import SortField from '@/components/SortField.vue'
    import moment from 'moment/moment'
    import { Stroke } from '@/core/models/stroke'
    import { User } from '@/core/models/user'

    export default defineComponent({
        name: 'ProgramExecutionCollection',

        components: {
            DatePickerModal,
            CardCollection,
            ConfirmModal,
            SortField,
        },

        props: {
            user: {
                type: User,
            }
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined,
                extra: false
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-started_at,-id',
                relations: 'user,training_program,training_program.workout_schedules',
                user_id: props.user?.id,
                date_from: undefined,
                date_to: undefined,
                level: undefined,
                stroke_id: undefined,
                calendar_duration: undefined,
                status: undefined,
            })

            const reset = () => {
                queries.search = undefined
                queries.date_from = undefined
                queries.date_to = undefined
                queries.level = undefined
                queries.stroke_id = undefined
                queries.calendar_duration = undefined
                queries.status = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <ProgramExecutionCollection>store.getters['programExecutionCollection/get'])
            const strokes = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const viewUser = (user: User) => {
                router.push({ name: 'trainees.show', params: <any>{ id: user.id }, query: { tab: '2' } })
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('programExecutionCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const del = (executionModel: TrainingProgramExecution) => {
                state.overlay = true
                store.dispatch('programExecution/delete', { id: executionModel.id }).then(() => {
                    capture()
                })
            }

            const touch = (executionModel: TrainingProgramExecution) => {
                context.emit('touched', executionModel)
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-started_at,-id'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
                queries.level,
                queries.stroke_id,
                queries.calendar_duration,
                queries.status,
            ], () => {
                capture()
            })

            onMounted(() => {
                collection.value.data = []
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                capture()
            })

            return {
                queries,
                collection: collection.value,
                strokes,
                moment,
                touch,
                del,
                updated,
                viewUser,
                onSort,
                search,
                clear,
                reset,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>