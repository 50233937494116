<template>
    <div>
        <app-bar :title="t('email_title')"></app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <card-collection
                :meta="meta"
                :busy="overlay"
                @updated="updated"
            >
                <template #filters>
                    <v-toolbar
                        elevation="0"
                        color="transparent"
                        class="mt-7"
                        height="56"
                    >
                        <v-text-field
                            v-model="queries.search"
                            @click:clear="clear"
                            class="input-search md mr-2"
                            clearable
                            outlined
                            dense
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :label="t('general_label_search')"
                        ></v-text-field>

                        <v-select
                            v-model="queries.confirmed"
                            :items="[ { text: '-', value: '' }, { text: t('general_text_yes'), value: 'yes' }, { text: t('general_text_no'), value: 'no' } ]"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('user_label_confirmed_email')"
                        ></v-select>

                        <v-select
                            v-model="queries.preferred_locale"
                            :items="[ { text: '-', value: '' } ].concat(locales())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('user_label_language')"
                        ></v-select>

                        <v-select
                            v-model="queries.status"
                            :items="[ { text: '-', value: '' } ].concat(userStatuses())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('user_label_status')"
                        ></v-select>

                        <v-btn
                            @click="search"
                            class="sm mx-5"
                            color="primary"
                        >{{ t('general_action_search') }}</v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                            v-ripple="false"
                            plain
                            color="orange darken-4"
                            @click="reset"
                        >{{ t('general_action_reset_filters') }}</v-btn>

                    </v-toolbar>
                </template>

                <template #data>
                    <thead>
                    <tr>
                        <th class="touched">
                            <sort-field
                                v-model="queries.sort"
                                field="id"
                                @touched="onSort"
                            >{{ t('user_label_id') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="last_name"
                                @touched="onSort"
                            >{{ t('user_label_last_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="first_name"
                                @touched="onSort"
                            >{{ t('user_label_first_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="email"
                                @touched="onSort"
                            >{{ t('user_label_email_address') }}</sort-field>
                        </th>
                        <th>{{ t('user_label_phone') }}</th>
                        <th>{{ t('user_label_confirmed_email') }}</th>
                        <th>{{ t('user_label_language') }}</th>
                        <th>{{ t('user_label_status') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="collection.data.length === 0">
                        <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                    </tr>
                    <template v-else v-for="user in collection.data">
                        <tr>
                            <td>{{ user.id }}</td>
                            <td>{{ user.lastName }}</td>
                            <td>{{ user.firstName }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.phone }}</td>
                            <td>{{ user.emailVerifiedAt ? t('general_text_yes') : t('general_text_no') }}</td>
                            <td>{{ localeTitle(user.preferredLocale) }}</td>
                            <td>{{ userStatusTitle(user.status) }}</td>
                        </tr>
                    </template>
                    </tbody>
                </template>
                <template #export>
                    <v-btn
                        @click="_export"
                        class="exs mt-4 ml-4"
                        color="primary"
                        small
                    >{{ t('general_action_export') }}</v-btn>
                </template>
            </card-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { useLocales } from '@/core/defaults'
    import { useUserHttp } from '@/core/api/users'
    import { UserCollection } from '@/store/modules/user-collection'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'

    export default defineComponent({
        components: {
            CardCollection,
            SortField,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                meta: undefined
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                confirmed: undefined,
                preferred_locale: undefined,
                status: undefined,
            })

            const reset = () => {
                queries.search = undefined
                queries.page = 1
                queries.per_page = 20
                queries.sort = '-id'
                queries.confirmed = undefined
                queries.preferred_locale = undefined
                queries.status = undefined
                capture()
            }

            const store = useStore()

            const collection = computed(() => <UserCollection>store.getters['userCollection/get'])

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('userCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const _export = () => {
                state.overlay = true
                useUserHttp()._exportEmails(queries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Users.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
                queries.confirmed,
                queries.preferred_locale,
                queries.status
            ], () => {
                capture()
            })

            onMounted(() => {
                capture()
            })

            return {
                queries,
                collection: collection.value,
                _export,
                updated,
                onSort,
                search,
                clear,
                reset,
                ...useI18n(),
                ...useEnums(),
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>