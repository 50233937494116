<template>
    <v-dialog
        v-model="touched"
        max-width="480"
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">
                {{ payload.key }}
            </v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <v-textarea
                        v-model="value.value"
                        type="text"
                        :label="`${t('translation_title_prefix')} (${payload.locale.toUpperCase()})`"
                        required
                        outlined
                        :error-messages="form.errors.get('value')"
                        :readonly="payload.locale === 'xx'"
                    ></v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    dark
                    v-if="payload.locale !== 'xx'"
                >{{ t('general_action_update') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="touched = false"
                    width="45%"
                    dark
                >{{ t('general_action_cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs, PropType } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { Translation, TranslationPayload } from '@/core/models/translation'

    export default defineComponent({
        name: 'TranslationModal',

        /**
         * Component props.
         */
        props: {
            value: {
                type: Translation,
                default: () => {
                    return new Translation(<TranslationPayload>{})
                }
            },
            payload: {
                type: Object as PropType<{ key: string, group: string, locale: string }>,
                required: true
            }
        },

        /**
         * Component constructor.
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()

            const state = reactive({
                touched: false
            })

            const i18n =useI18n()
            const form = reactive(useForm())

            const submit = () => {
                props.value?.forceFill(props.payload)
                form.usingSubmit(store.dispatch('translation/updateOrCreate', { payload: props.value.payload() }))
                    .then(() => {
                        state.touched = false
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                        context.emit('touched')
                    })
            }

            return {
                form,
                submit,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
