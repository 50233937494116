import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useStatsHttp } from '@/core/api/stats'
import { StatUser, StatUserPayload } from '@/core/models/stat-user'

const state: StatUser = new StatUser(<StatUserPayload>{})

export const statUser: Module<StatUser, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: StatUser, payload: StatUserPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useStatsHttp()
                    .users(payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: StatUser): StatUser => {
            return state
        }
    }
}