
<div class="fill-height">
    <app-bar :title="t('feedbacks_title')"></app-bar>

    <template v-if="touchedFeedback.exists()">
        <v-app-bar
            color="transparent"
            height="84"
            elevation="0"
            class="mb-n6 px-6"
        >
            <v-toolbar-title>{{ touchedFeedback.workout.name }}</v-toolbar-title>
            <v-btn
                @click="unset"
                plain
                icon
                absolute
                right
                class="mr-n8"
            ><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>

        <div class="px-10 pb-6 overflow-hidden">
            <feedback-view :feedback="touchedFeedback"></feedback-view>
        </div>
    </template>

    <template v-if="! touchedFeedback.exists()">
        <feedback-collection @touched="touchFeedback"></feedback-collection>
    </template>
</div>
