
<v-dialog
    v-model="touched"
    max-width="480"
    persistent
    scrollable
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
        <v-card-title class="customized-primary white--text">{{ value.fullName }}</v-card-title>

        <v-card-text class="pb-0">
            <v-form class="mt-6">
                <v-checkbox
                    v-for="(role, index) in roles.data"
                    v-model="value.roleIds"
                    :key="'role_' + role.id"
                    :value="role.id"
                    :label="role.name"
                    :hide-details="index !== roles.data.length - 1"
                    multiple
                    :error-messages="form.errors.get('roles')"
                />
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="submit"
                width="45%"
                :loading="form.busy"
                :disabled="form.busy"
            >{{ t('general_action_update') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="orange"
                @click="cancel"
                width="45%"
                dark
            >{{ t('general_action_cancel') }}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
