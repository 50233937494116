import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { useTrainingProgramHttp } from '@/core/api/training-programs'
import { TrainingProgram, TrainingProgramPayload } from '@/core/models/training-program'

export type ProgramCollection = {
    data: Array<TrainingProgram>,
    meta?: MetaPayload,
    links?: any
}

const state: ProgramCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const programCollection: Module<ProgramCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: ProgramCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: TrainingProgramPayload) => {
                state.data.push(new TrainingProgram(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useTrainingProgramHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: ProgramCollection): ProgramCollection => {
            return state
        }
    }
}