<template>
    <div>
        <app-bar :title="program.name"/>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div v-if="permitted('admin_program_management')" class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}</v-btn>
                    <v-btn
                        v-if="! program.archived"
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(program)"
                    >
                        {{ program.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="customized-orange"
                        outlined
                        @click.prevent="archived(program)"
                    >
                        {{ program.archived ? t('general_action_unarchived') : t('general_action_archived') }}
                    </v-btn>
                    <v-btn
                        class="sm"
                        color="orange"
                        outlined
                        @click.prevent="del(program)"
                    >{{ t('general_action_delete') }}</v-btn>
                </div>
                <div class="float-left mr-10" style="max-width: 394px">
                    <img
                        class=""
                        v-if="program.photoUrl"
                        :src="program.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('program_label_description') }}:</div>
                    <div class="form-subtitle-description">{{ program.description }}</div>
                    <div class="form-subtitle">{{ t('program_label_coach_advice') }}:</div>
                    <div class="form-subtitle-description">{{ program.coachAdvice }}</div>
                    <card-unit :items="items()">
                        <template #title>{{ t('program_label_id') }}: {{ program.id }}</template>
                    </card-unit>
                    <card-unit
                        v-if="participants.data.length > 0"
                        :items="participants.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                        <template #title>{{ t('program_label_users') }}:</template>
                        <template #actions>
                            <v-card-actions
                                class="px-0 mx-n3 mt-4"
                                v-if="participantMeta && participantMeta.total > 0"
                            >
                                <v-pagination
                                    v-model="participantQueries.page"
                                    :length="participantPageCount"
                                    total-visible="7"
                                ></v-pagination>
                                <span class="pagination-meta">{{ t('general_pagination_meta', { from: participantMeta.from, to: participantMeta.to, total: participantMeta.total }) }}</span>
                            </v-card-actions>
                        </template>
                    </card-unit>
                </div>
                <div class="float-left" style="max-width: calc(100% - 434px)">
                    <v-tabs
                        v-if="program.weekSchedules.length"
                        v-model="currentTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <template v-for="(weekSchedule, index) in program.weekSchedules">
                            <v-tab :class="{ 'pl-0': index === 0 }">{{ t('program_workout_week_number_prefix') }} {{ index + 1 }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    class="pb-7"
                                    v-for="(schedule, number) in weekSchedule"
                                    :key="`schedule_${index}_${number}`"
                                    untitled
                                    :items="[
                                        { text: t('program_workout_prefix'), value: schedule.workoutOrderNumber },
                                        { text: t('workout_label_id'), value: schedule.workoutId },
                                        { text: t('workout_label_name'), value: schedule.workout.name },
                                        { text: t('workout_label_workout_types'), value: workoutTypesStrTitle(schedule.workout.workoutTypes) },
                                        { text: t('workout_label_duration'), value: schedule.workout.totalTime },
                                        { text: t('workout_label_distance'), value: schedule.workout.distance + ' m' },
                                    ]"
                                >
                                </card-unit>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                </div>
            </div>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { TrainingProgram } from '@/core/models/training-program'
    import { UserCollection } from '@/store/modules/user-collection'
    import { pageCount } from '@/core/utils/pagination'
    import moment from 'moment/moment'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const participantQueries = reactive({
                user_program_id: id,
                page: 1,
                per_page: 20,
                sort: '-id',
                only_active: 1,
            })

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
                participantMeta: undefined,
                participantPageCount: 1,
            })

            const participants = computed(() => <UserCollection>store.getters['userCollection/get'])

            const capture = () => {
                store.dispatch('program/get', { id: id, payload: { relations: 'user,stroke,workoutSchedules,workoutSchedules.workout,workoutSchedules.workout.sets' } }).then(() => {
                    captureParticipants()
                })
            }

            const captureParticipants = () => {
                store.dispatch('userCollection/get', participantQueries).then((data) => {
                    state.participantMeta = data.meta
                    state.participantPageCount = pageCount(data.meta)
                    state.overlay = false
                })
            }

            const programModel = computed(() => <TrainingProgram>store.getters['program/get'])

            const suspend = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/update', { id: programModel.id, payload: { enabled: ! programModel.enabled, only: 'enabled' } }).then(() => {
                    store.dispatch('notify', { color: 'success', message: 'Data updated successfully!' })
                    capture()
                })
            }

            const archived = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/update', { id: programModel.id, payload: { archived: ! programModel.archived, only: 'archived' } }).then(() => {
                    capture()
                })
            }

            const edit = () => router.push({ name: 'programs.show', params: { id: <any>programModel.value.id } })

            const del = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/delete', { id: programModel.id }).then(() => {
                    router.push({ name: 'programs' })
                })
            }

            const participantUpdated = (updatedQueries: any) => {
                participantQueries.page = updatedQueries.page
                capture()
            }

            watch(() => [participantQueries.page], () => {
                captureParticipants()
            })

            const items = () => {
                let items = <any>[]
                if (programModel.value.userId) {
                    items = items.concat([{
                        text: i18n.t('general_label_user'),
                        value: programModel.value.user.fullName,
                        route: { name: 'trainees.show', params: <any>{ id: programModel.value.userId }, query: { tab: '2' } }
                    }])
                }
                items = items.concat([
                    { text: i18n.t('program_label_sport'), value: enums.sportTypeTitle(programModel.value.sportType) },
                    { text: i18n.t('program_label_workout_types'), value: enums.workoutTypesStrTitle(programModel.value.workoutTypes) },
                    { text: i18n.t('program_label_goals'), value: programModel.value.goalsStrTitle },
                    { text: i18n.t('program_label_dominant_stroke'), value: programModel.value.stroke.name },
                    { text: i18n.t('program_label_level'), value: enums.levelTitle(programModel.value.level) },
                    { text: i18n.t('program_label_duration'), value: programModel.value.calendarDuration + ' ' + enums.calendarDurationUnitTitle(programModel.value.calendarDurationUnit).toLowerCase() }
                ])
                if (programModel.value.nextProgramId) {
                    items = items.concat([{
                        text: i18n.t('program_label_next_program'),
                        value: programModel.value.nextProgramId,
                        route: { name: 'programs.show', params: <any>{ id: programModel.value.nextProgramId } }
                    }])
                }
                return items
            }

            onMounted(() => {
                capture()
            })
            
            return {
                capture,
                items,
                program: programModel.value,
                participants: participants.value,
                moment,
                edit,
                del,
                suspend,
                archived,
                participantQueries,
                participantUpdated,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
