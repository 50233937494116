import { NavigationGuardNext, Route } from 'vue-router/types/router'
import { useAuth } from '@/plugins/auth'

const authSignedGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
    const auth = useAuth()

    if (['login', 'register'].includes(<string>to.name) && auth.signed()) {
        next({ name: 'home' })
    } else {
        next()
    }
}

export default authSignedGuard