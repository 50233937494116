<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        persistent
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">{{ value.exists() ? t('user_card_title_update_comment') : t('user_card_title_create_comment') }}</v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <v-textarea
                        v-model="value.comment"
                        type="number"
                        :label="t('user_label_comment')"
                        required
                        outlined
                        dense
                        :error-messages="form.errors.get('comment')"
                    ></v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    :loading="form.busy"
                    :disabled="form.busy"
                >{{ t('general_action_save') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="cancel"
                    width="45%"
                    dark
                >{{ t('general_action_cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useAuth } from '@/plugins/auth'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { UserComment } from '@/core/models/user-comment'

    export default defineComponent({
        name: 'UserCommentModal',

        props: {
            value: {
                type: UserComment,
                default: () => new UserComment(<any>{})
            },
            id: {
                type: Number,
                required: true
            }
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()
            const auth = useAuth()
            const i18n = useI18n()

            const state = reactive({
                touched: false,
            })

            const form = reactive(useForm())

            const submit = () => {
                let payload = { comment: props.value.comment, user_id: auth.user().id, recipient_id: props.id }
                form.usingSubmit(
                    props.value.exists() ?
                        store.dispatch('userComment/update', { id: props.value.id, payload: payload }) :
                        store.dispatch('userComment/create', { payload: payload })
                ).then(() => {
                    state.touched = false
                    store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    context.emit('submit')
                    props.value.comment = ''
                })
            }

            const cancel = () => {
                form.errors.clear()
                state.touched = false
                context.emit('cancel')
            }

            return {
                form,
                submit,
                cancel,
                ...useEnums(),
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
