export interface StrokePayload {
    id: number
    name: string
    type: string
    sort_order: number
    time_50m?: string
    time_100m?: string
    enabled?: boolean
}

export class Stroke {

    id: number
    name: string
    type: string
    sortOrder: number
    time50m?: string
    time100m?: string
    enabled?: boolean

    constructor(data: StrokePayload) {
        this.id = data.id
        this.name = data.name
        this.type = data.type
        this.sortOrder = data.sort_order
        this.time50m = data.time_50m
        this.time100m = data.time_100m
        this.enabled = data.enabled
    }

    fill(data: StrokePayload): this {
        this.id = data.id
        this.name = data.name
        this.type = data.type
        this.sortOrder = data.sort_order
        this.time50m = data.time_50m
        this.time100m = data.time_100m
        this.enabled = data.enabled
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<StrokePayload>{})
    }

    payload(): StrokePayload | any {
        return {
            id: this.id,
            name: this.name,
            sort_order: this.sortOrder,
            time_50m: this.time50m,
            time_100m: this.time100m,
            enabled: this.enabled,
        }
    }
}