<template>
    <div>
        <app-bar :title="t('complaints_title')"></app-bar>

        <v-layout>
            <complaint-collection></complaint-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import ComplaintCollection from '@/components/complaints/ComplaintCollection.vue'

    export default defineComponent({
        components: {
            ComplaintCollection,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({})

            return {
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>