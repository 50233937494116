<template>
    <div>
        <app-bar :title="t('pages_title')"></app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <card-collection
                :meta="meta"
                :busy="overlay"
                @updated="updated"
            >
                <template #filters>
                    <v-toolbar
                        elevation="0"
                        color="transparent"
                        class="mt-7"
                        height="56"
                    >
                        <v-text-field
                            v-model="queries.search"
                            @click:clear="clear"
                            class="input-search md"
                            clearable
                            outlined
                            dense
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :label="t('general_label_search')"
                        ></v-text-field>

                        <v-btn
                            @click="search"
                            class="sm mx-5"
                            color="primary"
                        >{{ t('general_action_search') }}</v-btn>
                    </v-toolbar>
                </template>

                <template #data>
                    <thead>
                        <tr>
                            <th class="touched">
                                <sort-field
                                    v-model="queries.sort"
                                    field="id"
                                    @touched="onSort"
                                >{{ t('page_label_id') }}</sort-field>
                            </th>
                            <th>{{ t('page_label_name') }}</th>
                            <th>{{ t('page_label_slug') }}</th>
                            <th>{{ t('page_label_published') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="collection.data.length === 0">
                            <td class="text-center" colspan="4">{{ t('general_text_no_data') }}</td>
                        </tr>
                        <template v-else v-for="page in collection.data">
                            <tr>
                                <td>
                                    <a
                                        :href="`/pages/${page.id}`"
                                        @click.prevent="show(page)"
                                        :class="{ 'orange--text': ! page.published }"
                                    >
                                        {{ page.id }}
                                    </a>
                                </td>
                                <td>
                                    <a
                                        :href="`/pages/${page.id}`"
                                        @click.prevent="show(page)"
                                    >
                                        {{ page.name }}
                                    </a>
                                </td>
                                <td>
                                    {{ page.slug }}
                                </td>
                                <td>
                                    <v-icon
                                        :color="page.published ? 'green' : 'red'"
                                    >mdi-check-circle-outline</v-icon>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </card-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { Page } from '@/core/models/page'
    import { PageCollection } from '@/store/modules/page-collection'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'

    export default defineComponent({
        components: {
            CardCollection,
            SortField,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                meta: undefined
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                date_from: undefined,
                date_to: undefined,
            })

            const reset = () => {
                queries.search = undefined
                queries.page = 1
                queries.per_page = 20
                queries.sort = '-id'
                queries.date_from = undefined
                queries.date_to = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <PageCollection>store.getters['pageCollection/get'])

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('pageCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const show = (pageModel: Page) => {
                router.push({ name: 'pages.show', params: <any>{ id: pageModel.id } })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            watch(() => [queries.sort], () => {
                capture()
            })

            onMounted(() => {
                capture()
            })

            return {
                queries,
                collection: collection.value,
                show,
                updated,
                onSort,
                search,
                clear,
                reset,
                ...useI18n(),
                ...toRefs(state)
            }
        }

    })
</script>