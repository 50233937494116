import { useHttp } from '../http'

export const useUserHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`users/${id}`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`users/${id}`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`users/${id}`)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`users`, payload)
    }

    const resendVerification = <T>(id: number): Promise<T> => {
        return useHttp().post(`users/${id}/resend-verification`)
    }

    const _export = <T>(payload: any): Promise<T> => {
        return useHttp().get(`users/export`, payload, { responseType: 'blob' })
    }

    const _exportEmails = <T>(payload: any): Promise<T> => {
        return useHttp().get(`users/export-emails`, payload, { responseType: 'blob' })
    }

    return {
        find,
        update,
        del,
        get,
        resendVerification,
        _export,
        _exportEmails,
    }
}