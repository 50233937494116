<template>
    <div>
        <app-bar :title="t('notifications_title')"></app-bar>

        <v-layout>
            <notification-collection></notification-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import NotificationCollection from '@/components/notifications/NotificationCollection.vue'

    export default defineComponent({
        components: {
            NotificationCollection,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({})

            return {
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>