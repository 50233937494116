
<div>
    <app-bar :title="t('permissions_title')">
        <template #actions>
            <div v-if="permitted('*')" class="px-10">
                <v-btn
                    @click="change"
                    class="sm sm-h"
                    color="orange"
                    :disabled="busy"
                >{{ t('general_action_save') }}</v-btn>
            </div>
        </template>
    </app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-tabs
            class="px-4 my-7"
            height="52"
        >
            <div class="tabs-line"></div>
            <v-tab
                :to="{ name: 'users' }"
                class="pl-0"
            >{{ t('users_title') }}</v-tab>
            <v-tab
                :to="{ name: 'roles' }"
            >{{ t('roles_title') }}</v-tab>
            <v-tab
                :to="{ name: 'permissions' }"
            >{{ t('permissions_title') }}</v-tab>
            <v-tabs-items>
                <card-collection
                    class="px-0-nested"
                    :busy="overlay"
                >
                    <template #data>
                        <thead>
                        <tr>
                            <th style="width: 200px"></th>
                            <th
                                v-for="role in roles.data"
                                class="text-center"
                            >{{ role.name }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                            v-for="permission in permissions()"
                        >
                            <tr>
                                <td class="titled">{{ permission.text }}</td>
                                <td
                                    v-for="role in roles.data"
                                    class="text-center"
                                >
                                    <v-icon
                                        v-if="role.permitted(permission.value)"
                                        class="touched"
                                        color="customized-green"
                                        @click="deletePermission(role, permission.value)"
                                        :disabled="! permitted('*')"
                                    >mdi-check-circle-outline</v-icon>
                                    <v-icon
                                        v-else
                                        class="touched"
                                        color="grey lighten-1"
                                        @click="addPermission(role, permission.value)"
                                        :disabled="! permitted('*')"
                                    >mdi-check-circle-outline</v-icon>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </card-collection>
            </v-tabs-items>
        </v-tabs>
    </v-layout>
</div>
