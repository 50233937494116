<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }">
                <v-btn
                    v-ripple="false"
                    plain
                    color="orange darken-4"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon left size="16">mdi-translate</v-icon>{{ t('translation_edit_modal_action_edit') }}
                </v-btn>
            </slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">{{ t('translation_edit_modal_title_edit') }}</v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <template
                        v-for="locale in locales()"
                    >
                        <template v-if="text">
                            <v-textarea
                                v-model="translations[locale.value]"
                                :label="locale.text"
                                required
                                outlined
                                dense
                            ></v-textarea>
                        </template>
                        <template v-else-if="editor">
                            <tinymce :key="unique + '_' + locale.value" v-model="translations[locale.value]"></tinymce>
                        </template>
                        <template v-else>
                            <v-text-field
                                v-model="translations[locale.value]"
                                :label="locale.text"
                                required
                                outlined
                                dense
                            ></v-text-field>
                        </template>
                    </template>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="orange"
                    @click="touched = false"
                    width="45%"
                    dark
                >{{ t('general_action_back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    dark
                >{{ t('translation_edit_modal_action_save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { computed, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useLocales } from '@/core/defaults'
    import { isArray } from 'lodash'
    import { randomInt } from '@/core/utils'
    import Tinymce from '@/components/Tinymce.vue'

    export default defineComponent({
        name: 'EditTranslationsModal',

        components: {
            Tinymce,
        },

        /**
         * Component props.
         */
        props: {
            value: {},
            items: {
                required: true
            },
            text: Boolean,
            editor: Boolean,
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const rand = () => {
                return 'editor_' + randomInt(100000, 999999)
            }

            const store = useStore()

            const state = reactive({
                touched: false,
                translations: <any>{},
                unique: rand(),
            })

            if (props.items !== undefined) {
                state.translations = isArray(props.items) ? {} : props.items
            }

            const preferredLocale = computed(() => <string>store.getters['preferredLocale'])

            const submit = () => {
                context.emit('input', state.translations[preferredLocale.value])
                context.emit('touch', state.translations)
                state.touched = false
            }

            watch(() => props.value, (newValue) => {
                state.translations[preferredLocale.value] = newValue
            })

            watch(() => props.items, (newValue) => {
                state.translations = newValue || {}
            })

            watch(() => state.touched, (newValue) => {
                setTimeout(() => {
                    state.unique = rand()
                }, 1)
            })

            return {
                preferredLocale,
                submit,
                ...useI18n(),
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>
