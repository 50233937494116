import { useHttp } from '../http'

export const usePageHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`pages/${id}`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`pages/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`pages`, payload)
    }

    return {
        find,
        update,
        get,
    }
}