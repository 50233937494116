<template>
    <div>
        <app-bar
            :title="notification.title"
        />

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}</v-btn>
                    <v-btn
                        class="sm"
                        color="orange"
                        outlined
                        @click.prnotification="del(notification)"
                    >{{ t('general_action_delete') }}</v-btn>
                </div>
                <div class="float-left mr-10" style="max-width: 394px">
                    <img
                        class=""
                        v-if="notification.photoUrl"
                        :src="notification.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('notification_label_message') }}:</div>
                    <div class="form-subtitle-description" v-html="notification.message"></div>
                    <card-unit :items="items()">
                        <template #title>{{ t('notification_label_id') }}: {{ notification.id }}</template>
                    </card-unit>
                </div>
                <div class="float-left mr-10">
                    <v-tabs
                        v-model="currentTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <template v-if="userMeta && userMeta.total > 0">
                            <v-tab class="pl-0">{{ t('notification_label_attendees') }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    untitled
                                    :items="users.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                    <template #actions>
                                        <v-card-actions
                                            class="px-0 mx-n3 mt-4"
                                            v-if="userMeta && userMeta.total > 0"
                                        >
                                            <v-pagination
                                                v-model="userQueries.page"
                                                :length="userPageCount"
                                                total-visible="7"
                                            ></v-pagination>
                                            <span class="pagination-meta">{{ t('general_pagination_meta', { from: userMeta.from, to: userMeta.to, total: userMeta.total }) }}</span>
                                        </v-card-actions>
                                    </template>
                                </card-unit>
                                <v-btn
                                    v-if="users.data.length"
                                    @click="_exportUsers"
                                    class="exs mt-2"
                                    color="primary"
                                    small
                                >{{ t('general_action_export') }}</v-btn>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                </div>
            </div>

        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { useUserHttp } from '@/core/api/users'
    import { Notification } from '@/core/models/notification'
    import { UserCollection } from '@/store/modules/user-collection'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { pageCount } from '@/core/utils/pagination'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const userQueries = reactive({
                notification_schedule_id: id,
                page: 1,
                per_page: 20,
                sort: '-id',
                only_active: 1,
            })

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
                userMeta: undefined,
                userPageCount: 1,
                scheduleId: <number | undefined>0,
            })

            const users = computed(() => <UserCollection>store.getters['userCollection/get'])

            const capture = () => {
                store.dispatch('notification/get', { id: id, payload: { relations: 'role,users' } }).then(() => {
                    store.dispatch('userCollection/get', userQueries).then((data) => {
                        state.userMeta = data.meta
                        state.userPageCount = pageCount(data.meta)
                        state.overlay = false
                    })
                })
            }

            const notificationModel = computed(() => <Notification>store.getters['notification/get'])

            const edit = () => router.push({ name: 'notifications.show', params: { id: <any>notificationModel.value.id } })

            const del = (notificationModel: Notification) => {
                state.overlay = true
                store.dispatch('notification/delete', { id: notificationModel.id }).then(() => {
                    router.push({ name: 'notifications' })
                })
            }

            const items = () => {
                return <any>[
                    { text: i18n.t('notification_label_status'), value: enums.notificationStatusTitle(notificationModel.value.status) },
                    { text: i18n.t('notification_label_send_at'), value: notificationModel.value.sendAt?.format('DD/MM/YYYY HH:mm') || '' },
                    { text: i18n.t('notification_label_cta'), value: notificationModel.value.cta ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('notification_label_cta_text'), value: notificationModel.value.ctaText || '-' },
                    { text: i18n.t('notification_label_cta_params'), value: notificationModel.value.ctaParamsStr || '-' },
                    { text: i18n.t('notification_label_send_to'), value: notificationModel.value.roleId ? notificationModel.value.role.name : i18n.t('notification_label_role_id_null') },
                    { text: i18n.t('notifications_override'), value: notificationModel.value.force ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                ]
            }

            const userUpdated = (updatedQueries: any) => {
                userQueries.page = updatedQueries.page
                capture()
            }

            const _exportUsers = () => {
                state.overlay = true
                useUserHttp()._export(userQueries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Attendees.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            onMounted(() => {
                capture()
            })

            return {
                capture,
                items,
                notification: notificationModel.value,
                users: users.value,
                moment,
                edit,
                del,
                userQueries,
                userUpdated,
                _exportUsers,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
