import { Translation } from './translation'

export interface LazyTranslationPayload {
    locales: string[]
    groups: { [key: string]: string }
    num_translations: number
    num_changed: number
    delete_enabled: boolean
    /* relations */
    translations: { [key: string]: { [key: string]: Translation } }
}

export class LazyTranslation {

    locales: string[]
    groups: { [key: string]: string }
    numTranslations: number
    numChanged: number
    deleteEnabled: boolean
    /* relations */
    translations: { [key: string]: { [key: string]: Translation } }

    constructor(data: LazyTranslationPayload) {
        this.locales = data.locales
        this.groups = data.groups
        this.numTranslations = data.num_translations
        this.numChanged = data.num_changed
        this.deleteEnabled = data.delete_enabled
        this.translations = <any>{}
        if (data.translations) {
            for (const [key, value] of Object.entries(data.translations)) {
                let translations = <any>{}
                for (const [key2, value2] of Object.entries(value)) {
                    translations[key2] = new Translation(value2)
                }
                this.translations[key] = translations
            }
        }
    }

    fill(data: LazyTranslationPayload): this {
        this.locales = data.locales
        this.groups = data.groups
        this.numTranslations = data.num_translations
        this.numChanged = data.num_changed
        this.deleteEnabled = data.delete_enabled
        this.translations = <any>{}
        if (data.translations) {
            for (const [key, value] of Object.entries(data.translations)) {
                let translations = <any>{}
                for (const [key2, value2] of Object.entries(value)) {
                    translations[key2] = new Translation(value2)
                }
                this.translations[key] = translations
            }
        }
        return this
    }

    valueGroups(values: { text: string, value?: string }[] = []): any[] {
        let groups = <any>[]

        values.map((item) => {
            groups.push({
                text: item.text,
                value: item.value,
            })
        })

        if (this.groups) {
            for (const [key, value] of Object.entries(this.groups)) {
                groups.push({
                    text: value,
                    value: key,
                })
            }
        }

        return groups
    }
}