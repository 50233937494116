import { useHttp } from '../http'

export const useDefaultHttp = () => {

    const defaults = <T>(): Promise<T> => {
        return useHttp().get(`defaults?translation_group=admin`)
    }

    return {
        defaults
    }
}