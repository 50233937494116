export interface FeedbackStatPayload {
    feelings: {
        not_great: number
        neutral: number
        very_good: number
    }
    levels: {
        very_hard: number
        challenging: number
        fine: number
        easy: number
    }
    fit_in_time: {
        not_at_all: number
        hard_but_ok: number
        satisfying: number
        perfectly: number
    }
    descriptions: {
        too_long: number
        confusing: number
        clear: number
        very_helpful: number
    }
}

export class FeedbackStat {

    feelings: {
        notGreat: number
        neutral: number
        veryGood: number
    }
    levels: {
        veryHard: number
        challenging: number
        fine: number
        easy: number
    }
    fitInTime: {
        notAtAll: number
        hardButOk: number
        satisfying: number
        perfectly: number
    }
    descriptions: {
        tooLong: number
        confusing: number
        clear: number
        veryHelpful: number
    }

    constructor(data: FeedbackStatPayload) {
        this.feelings = {
            notGreat: data?.feelings?.not_great,
            neutral: data?.feelings?.neutral,
            veryGood: data?.feelings?.very_good
        }
        this.levels = {
            veryHard: data?.levels?.very_hard,
            challenging: data?.levels?.challenging,
            fine: data?.levels?.fine,
            easy: data?.levels?.easy
        }
        this.fitInTime = {
            notAtAll: data?.fit_in_time?.not_at_all,
            hardButOk: data?.fit_in_time?.hard_but_ok,
            satisfying: data?.fit_in_time?.satisfying,
            perfectly: data?.fit_in_time?.perfectly
        }
        this.descriptions = {
            tooLong: data?.descriptions?.too_long,
            confusing: data?.descriptions?.confusing,
            clear: data?.descriptions?.clear,
            veryHelpful: data?.descriptions?.very_helpful
        }
    }

    fill(data: FeedbackStatPayload): this {
        this.feelings = {
            notGreat: data?.feelings?.not_great,
            neutral: data?.feelings?.neutral,
            veryGood: data?.feelings?.very_good
        }
        this.levels = {
            veryHard: data?.levels?.very_hard,
            challenging: data?.levels?.challenging,
            fine: data?.levels?.fine,
            easy: data?.levels?.easy
        }
        this.fitInTime = {
            notAtAll: data?.fit_in_time?.not_at_all,
            hardButOk: data?.fit_in_time?.hard_but_ok,
            satisfying: data?.fit_in_time?.satisfying,
            perfectly: data?.fit_in_time?.perfectly
        }
        this.descriptions = {
            tooLong: data?.descriptions?.too_long,
            confusing: data?.descriptions?.confusing,
            clear: data?.descriptions?.clear,
            veryHelpful: data?.descriptions?.very_helpful
        }
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
