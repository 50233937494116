
<v-flex>
    <h1 class="app-title">
        {{ t('login_title') }}
    </h1>
    <v-spacer class="pa-4" />
    <v-form>
        <v-row class="mb-n8">
            <v-col>
                <v-text-field
                    v-model="form.email"
                    type="email"
                    :label="t('login_label_email')"
                    required
                    outlined
                    dense
                    autocomplete="current-email"
                    :error-messages="form.errors.get('email')"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mb-n14">
            <v-col>
                <v-text-field
                    v-model="form.password"
                    type="password"
                    :label="t('login_label_password')"
                    required
                    outlined
                    dense
                    autocomplete="current-password"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mb-n8">
            <v-col>
                <v-checkbox
                    v-model="form.remember_me"
                    :label="t('login_label_remember_me')"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    :disabled="form.busy"
                    :loading="form.busy"
                    color="primary"
                    class="sm mr-4"
                    large
                    @click.prevent="submit()"
                >
                    {{ t('login_button_sign_in') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</v-flex>
