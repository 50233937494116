<template>
    <div>
        <app-bar
            :title="group.name"
        />

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div v-if="permitted('admin_group_management')" class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}</v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prgroup="suspend(group)"
                    >
                        {{ group.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <v-btn
                        class="sm"
                        color="orange"
                        outlined
                        @click.prgroup="del(group)"
                    >{{ t('general_action_delete') }}</v-btn>
                </div>
                <div class="float-left mr-10" style="max-width: 394px">
                    <img
                        class=""
                        v-if="group.photoUrl"
                        :src="group.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('group_label_description') }}:</div>
                    <div class="form-subtitle-description" v-html="group.description"></div>
                    <card-unit :items="items()">
                        <template #title>{{ t('group_label_id') }}: {{ group.id }}</template>
                    </card-unit>
                    <template v-if="group.photos && group.photos.length">
                        <div class="form-subtitle mb-3">{{ t('group_label_photos') }}:</div>
                        <v-row>
                            <v-col
                                v-for="photo in group.photos"
                                :key="photo"
                                cols="6"
                            >
                                <v-sheet
                                    color="white"
                                    elevation="0"
                                    height="185"
                                    width="185"
                                    rounded="lg"
                                    class="pt-6"
                                >
                                    <v-img
                                        :lazy-src="photo"
                                        :src="photo"
                                        max-height="135"
                                        max-width="135"
                                        class="mx-auto"
                                    ></v-img>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </template>
                </div>
                <div class="float-left mr-10">
                    <v-tabs
                        v-model="currentTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <template>
                            <v-tab class="pl-0">{{ t('group_label_members') }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    untitled
                                    :items="members.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                    <template #actions>
                                        <v-card-actions
                                            class="px-0 mx-n3 mt-4"
                                            v-if="memberMeta && memberMeta.total > 0"
                                        >
                                            <v-pagination
                                                v-model="memberQueries.page"
                                                :length="memberPageCount"
                                                total-visible="7"
                                            ></v-pagination>
                                            <span class="pagination-meta">{{ t('general_pagination_meta', { from: memberMeta.from, to: memberMeta.to, total: memberMeta.total }) }}</span>
                                        </v-card-actions>
                                    </template>
                                </card-unit>
                                <v-btn
                                    v-if="members.data.length"
                                    @click="_exportUsers"
                                    class="exs mt-2"
                                    color="primary"
                                    small
                                >{{ t('general_action_export') }}</v-btn>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                </div>
            </div>

        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { useUserHttp } from '@/core/api/users'
    import { useRankHttp } from '@/core/api/ranks'
    import { Group } from '@/core/models/group'
    import { UserCollection } from '@/store/modules/user-collection'
    import { RankCollection } from '@/store/modules/rank-collection'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { pageCount } from '@/core/utils/pagination'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const memberQueries = reactive({
                user_group_id: id,
                page: 1,
                per_page: 20,
                sort: '-id',
                only_active: 1,
            })

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
                memberMeta: undefined,
                memberPageCount: 1,
            })

            const members = computed(() => <UserCollection>store.getters['userCollection/get'])

            const capture = () => {
                store.dispatch('group/get', { id: id, payload: { 'relations': 'user' } }).then(() => {
                    store.dispatch('userCollection/get', memberQueries).then((data) => {
                        state.memberMeta = data.meta
                        state.memberPageCount = pageCount(data.meta)
                        state.overlay = false
                    })
                })
            }

            const groupModel = computed(() => <Group>store.getters['group/get'])

            const suspend = (groupModel: Group) => {
                state.overlay = true
                store.dispatch('group/update', { id: groupModel.id, payload: { enabled: ! groupModel.enabled, only: 'enabled' } }).then(() => {
                    capture()
                })
            }

            const edit = () => router.push({ name: 'groups.show', params: { id: <any>groupModel.value.id } })

            const del = (groupModel: Group) => {
                state.overlay = true
                store.dispatch('group/delete', { id: groupModel.id }).then(() => {
                    router.push({ name: 'groups' })
                })
            }

            const items = () => {
                let items = <any>[
                    { text: i18n.t('general_label_created_by'), value: enums.creatorTitle(groupModel.value.creator) },
                ]
                if (groupModel.value.userId) {
                    items = items.concat([{
                        text: i18n.t('general_label_user'),
                        value: groupModel.value.user.fullName,
                        route: { name: 'trainees.show', params: <any>{ id: groupModel.value.userId }, query: { tab: '5' } }
                    }])
                }
                items = items.concat([
                    { text: i18n.t('group_label_group_type'), value: enums.groupTypeTitle(groupModel.value.groupType) },
                    { text: i18n.t('group_label_location'), value: groupModel.value.location || '-' },
                    { text: i18n.t('general_label_coordinates'), value: groupModel.value.coordinatesTitle },
                    { text: i18n.t('group_label_private'), value: groupModel.value.private ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('general_label_created'), value: groupModel.value.createdAt?.format('DD/MM/YYYY HH:mm') || '' },
                ])

                return items
            }

            const memberUpdated = (updatedQueries: any) => {
                memberQueries.page = updatedQueries.page
                capture()
            }

            const _exportUsers = () => {
                state.overlay = true
                useUserHttp()._export(memberQueries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Members.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            onMounted(() => {
                capture()
            })

            return {
                capture,
                items,
                group: groupModel.value,
                members: members.value,
                moment,
                edit,
                del,
                suspend,
                memberQueries,
                memberUpdated,
                _exportUsers,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
