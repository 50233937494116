<template>
    <div class="fill-height">
        <app-bar :title="t('feedbacks_title')"></app-bar>

        <template v-if="touchedFeedback.exists()">
            <v-app-bar
                color="transparent"
                height="84"
                elevation="0"
                class="mb-n6 px-6"
            >
                <v-toolbar-title>{{ touchedFeedback.workout.name }}</v-toolbar-title>
                <v-btn
                    @click="unset"
                    plain
                    icon
                    absolute
                    right
                    class="mr-n8"
                ><v-icon>mdi-close</v-icon></v-btn>
            </v-app-bar>

            <div class="px-10 pb-6 overflow-hidden">
                <feedback-view :feedback="touchedFeedback"></feedback-view>
            </div>
        </template>

        <template v-if="! touchedFeedback.exists()">
            <feedback-collection @touched="touchFeedback"></feedback-collection>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, reactive, ref, toRefs } from '@vue/composition-api'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { Feedback } from '@/core/models/feedback'
    import FeedbackCollection from '@/components/feedback/FeedbackCollection.vue'
    import FeedbackView from '@/components/feedback/FeedbackView.vue'

    export default defineComponent({
        components: {
            FeedbackCollection,
            FeedbackView,
        },

        /**
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()

            const state = reactive({
                feedbackId: <number | undefined>parseInt(<any>router.currentRoute.query.feedback_id),
            })

            const touchedFeedback = ref(new Feedback(<any>{}))

            const touchFeedback = (execution: Feedback) => {
                Object.assign(touchedFeedback.value, execution)
            }

            const captureFeedback = () => {
                if (state.feedbackId) {
                    store.dispatch('feedback/get', {
                        id: state.feedbackId,
                        payload: {
                            relations: 'user,workout,workout_execution'
                        }
                    }).then((data) => {
                        touchFeedback(new Feedback(data.data))
                    })
                }
            }

            const unset = () => {
                if (state.feedbackId) {
                    router.replace({ query: undefined })
                    state.feedbackId = undefined
                }
                touchedFeedback.value.unset()
            }

            onMounted(() => {
                captureFeedback()
            })

            return {
                touchedFeedback: <Feedback>touchedFeedback.value,
                touchFeedback,
                unset,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>