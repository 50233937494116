import { useHttp } from '../http'

export const useRoleHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`roles/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`roles`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`roles/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`roles`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`roles/${id}`)
    }

    const updatePermissions = <T>(payload: any): Promise<T> => {
        return useHttp().patch(`roles/permissions`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        updatePermissions,
    }
}