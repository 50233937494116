import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { useBadgeHttp } from '@/core/api/badges'
import { Badge, BadgePayload } from '@/core/models/badge'

export type BadgeCollection = {
    data: Array<Badge>,
    meta?: MetaPayload,
    links?: any
}

const state: BadgeCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const badgeCollection: Module<BadgeCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: BadgeCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: BadgePayload) => {
                state.data.push(new Badge(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useBadgeHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: BadgeCollection): BadgeCollection => {
            return state
        }
    }
}