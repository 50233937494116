import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useFeedbackHttp } from '@/core/api/feedback'
import { Feedback, FeedbackPayload } from '@/core/models/feedback'

const state: Feedback = new Feedback(<FeedbackPayload>{})

export const feedback: Module<Feedback, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Feedback, payload: FeedbackPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        getOrNew: <T>(context: any, payload: { id: number | undefined, payload: any }): Promise<T> => {
            state.unset()
            if (payload.id) {
                return context.dispatch('get', payload)
            } else {
                return new Promise<T>((resolve) => {
                    resolve({} as T)
                })
            }
        },
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useFeedbackHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        delete: <T>(context: any, payload: { id: number }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFeedbackHttp()
                    .del(payload.id)
                    .then((data: any) => {
                        context.commit('update', new Feedback(<FeedbackPayload>{}))
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Feedback): Feedback => {
            return state
        },
    }
}