<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="definition.exists() ? t('glossary_definition_title_update') : t('glossary_definition_title_create')"
            back="/glossary/definitions"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="definition.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="definition.name"
                                    :items="definition.translations['name']"
                                    @touch="(v) => definition.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_section') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="definition.glossarySectionId"
                                    :items="sections"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('glossary_section_id')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_content') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="definition.content"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('content')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="definition.content"
                                    :items="definition.translations['content']"
                                    @touch="(v) => definition.translations['content'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_goal') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="definition.goal"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('goal')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="definition.goal"
                                    :items="definition.translations['goal']"
                                    @touch="(v) => definition.translations['goal'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_strokes') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="definition.strokeIds"
                                    :items="strokes.data"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('strokes')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_video') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="definition.video"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('video')"
                                ></v-textarea>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_definition_label_sort_order') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="definition.sortOrder"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('sort_order')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_action_published') }}</div>
                            <div class="horizontal-input">
                                <v-switch
                                    class="ma-0"
                                    v-model="definition.published"
                                ></v-switch>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'glossary-definitions' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ definition.exists() ? t('general_action_save') : t('glossary_definition_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { GlossaryDefinition } from '@/core/models/glossary-definition'
    import { GlossarySection } from '@/core/models/glossary-section'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import Tinymce from '@/components/Tinymce.vue'
    import { StrokeCollection } from '@/store/modules/stroke-collection'

    export default defineComponent({
        components: {
            EditTranslationsModal,
            DatePickerModal,
            Tinymce,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id'] || undefined

            const enums = useEnums()

            const state = reactive({
                overlay: true,
            })

            const definitionModel = computed(() => <GlossaryDefinition>store.getters['glossaryDefinition/get'])
            const sections = computed(
                () => [{ text: '-', value: '' }].concat(
                    store.getters['glossarySectionCollection/get'].data.map((item: GlossarySection) => {
                        return { text: item.name, value: item.id }
                    })
                )
            )
            const strokes = computed(() => <StrokeCollection>store.getters['strokeCollection/get'])

            const form = reactive(useForm())
            const photoForm = reactive(useForm())

            const capture = () => {
                store.dispatch('glossaryDefinition/getOrNew', { id: id, payload: { relations: 'strokes', append: 'translations', published: '' } }).then(() => {
                    state.overlay = false
                })
            }

            const submit = () => {
                form.usingSubmit(store.dispatch('glossaryDefinition/updateOrCreate', {
                    id: id,
                    payload: definitionModel.value.payload()
                }))
                    .then(() => {
                        router.push({ name: 'glossary-definitions.view', params: <any>{ id: definitionModel.value.id } })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            onMounted(() => {
                store.dispatch('glossarySectionCollection/get', { sort: '-id' })
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                capture()
            })

            return {
                form,
                photoForm,
                capture,
                submit,
                definition: definitionModel.value,
                sections,
                strokes,
                moment,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
