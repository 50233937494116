<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="section.exists() ? t('glossary_section_title_update') : t('glossary_section_title_create')"
            back="/glossary"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_section_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="section.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="section.name"
                                    :items="section.translations['name']"
                                    @touch="(v) => section.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('glossary_section_label_sort_order') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="section.sortOrder"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('sort_order')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_action_published') }}</div>
                            <div class="horizontal-input">
                                <v-switch
                                    class="ma-0"
                                    v-model="section.published"
                                ></v-switch>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'glossary-sections' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ section.exists() ? t('general_action_save') : t('glossary_section_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { GlossarySection } from '@/core/models/glossary-section'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id'] || undefined

            const enums = useEnums()

            const state = reactive({
                overlay: true,
            })

            const capture = () => {
                store.dispatch('glossarySection/getOrNew', { id: id, payload: { append: 'translations', published: '' } }).then(() => {
                    state.overlay = false
                })
            }

            const sectionModel = computed(() => <GlossarySection>store.getters['glossarySection/get'])

            const form = reactive(useForm())
            const photoForm = reactive(useForm())

            const submit = () => {
                form.usingSubmit(store.dispatch('glossarySection/updateOrCreate', {
                    id: id,
                    payload: sectionModel.value.payload()
                }))
                    .then(() => {
                        router.push({ name: 'glossary-sections.view', params: <any>{ id: sectionModel.value.id } })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            onMounted(() => {
                capture()
            })

            return {
                form,
                photoForm,
                capture,
                submit,
                section: sectionModel.value,
                moment,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
