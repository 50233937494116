export interface RankPayload {
    distance: number
    distance_unit: string
    duration: string
    rank: number
    user_id: number
    user_first_name: string
    user_last_name: string
    user_photo_url: string
    progress_percentage: number
}

export class Rank {

    distance: number
    distanceUnit: string
    duration: string
    rank: number
    userId: number
    userFirstName: string
    userLastName: string
    userPhotoUrl: string
    progressPercentage: number

    constructor(data: RankPayload) {
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = data.duration
        this.rank = data.rank
        this.userId = data.user_id
        this.userFirstName = data.user_first_name
        this.userLastName = data.user_last_name
        this.userPhotoUrl = data.user_photo_url
        this.progressPercentage = data.progress_percentage
    }

    fill(data: RankPayload): this {
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = data.duration
        this.rank = data.rank
        this.userId = data.user_id
        this.userFirstName = data.user_first_name
        this.userLastName = data.user_last_name
        this.userPhotoUrl = data.user_photo_url
        this.progressPercentage = data.progress_percentage
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
