
<div>
    <app-bar :title="t('translations_title')"></app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :busy="overlay"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-7"
                    height="56"
                >
                    <v-select
                        v-model="queries.group"
                        :items="lazyTranslation.valueGroups()"
                        :label="t('translations_label_group')"
                        required
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :disabled="busy"
                        hide-details
                        class="md-200 mr-5"
                    ></v-select>

                    <v-select
                        v-model="queries.language"
                        :items="[ { text: '-', value: '' } ].concat(locales())"
                        :label="t('translations_label_language_1')"
                        required
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :disabled="busy"
                        hide-details
                        class="md-200 mr-5"
                    ></v-select>

                    <v-select
                        v-model="queries.language_dest"
                        :items="[ { text: '-', value: '' } ].concat(locales())"
                        :label="t('translations_label_language_2')"
                        required
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :disabled="busy"
                        hide-details
                        class="md-200 mr-5"
                    ></v-select>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="postExport"
                        :disabled="busy || ! changed"
                    >
                        <v-icon left>mdi-export-variant</v-icon> {{ t('translations_action_export') }}
                    </v-btn>
                </v-toolbar>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-3 mb-1"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        @keyup.enter="capture"
                        class="input-search md-200"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <v-btn
                        @click="capture"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}</v-btn>
                </v-toolbar>

                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mb-4"
                    height="56"
                >
                    <v-checkbox
                        v-model="queries.only_missing"
                        :label="t('translations_label_show_missing') + missingSuffix()"
                        hide-details
                        class="ma-0 customized-deep-primary-color"
                        :disabled="! queries.language && ! queries.language_dest"
                    />
                </v-toolbar>
            </template>

            <template #alerts>
                <v-alert
                    outlined
                    icon="mdi-information-outline"
                    class="mx-4 mb-0 customized-primary--text"
                >
                    {{ t('translations_label_group') }}: {{ queries.group }},
                    {{ t('translations_label_count') }}: {{ lazyTranslation.numTranslations }},
                    {{ t('translations_label_changed') }}: {{ lazyTranslation.numChanged }}
                </v-alert>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th>{{ t('translation_label_key') }}</th>
                    <th>{{ t('translation_label_default') }}</th>
                    <template
                        v-for="locale in lazyTranslation.locales"
                        v-if="locale !== 'xx'"
                    >
                        <th>{{ localeTitle(locale) }}</th>
                    </template>
                </tr>
                </thead>
                <tbody>
                <tr v-if="Object.keys(lazyTranslation.translations).length === 0">
                    <td class="text-center" colspan="4">No data found.</td>
                </tr>
                <template
                    v-else
                    v-for="(translations, key) in lazyTranslation.translations"
                >
                    <tr>
                        <td class="titled">
                            {{ key }}
                        </td>
                        <td
                            v-for="locale in lazyTranslation.locales"
                        >
                            <translation-modal
                                :value="translations[locale]"
                                :payload="{ key: key, group: queries.group, locale: locale }"
                                @touched="capture"
                            >
                                <template #default="{ on, attrs }">
                                    <a
                                        class="link-bordered"
                                        v-on="on"
                                        v-bind="attrs"
                                        href="javascript:"
                                        :class="{
                                            'link-uncolored': locale === 'xx',
                                            'link-status-changed': translations[locale] ? translations[locale].status === 1 : false
                                        }"
                                    >
                                        {{
                                            translations[locale] ?
                                                utils.cut(translations[locale].value ?
                                                    translations[locale].value :
                                                    '(Empty)'
                                                ) :
                                                '(Not filled)'
                                        }}
                                    </a>
                                </template>
                            </translation-modal>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
    </v-layout>
</div>
