import moment, { Moment } from 'moment/moment'

export interface BadgeSectionPayload {
    id: number
    name: string
    published?: boolean
    sort_order: number
    created_at: string
    badges_count?: number
    translations?: {}
}

export class BadgeSection {

    id: number
    name: string
    published: boolean
    sortOrder: number
    createdAt: Moment
    badgesCount: number
    translations?: {} = <any>{}

    constructor(data: BadgeSectionPayload) {
        this.id = data.id
        this.name = data.name
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.badgesCount = data.badges_count || 0
        this.capture(data)
    }

    fill(data: BadgeSectionPayload): this {
        this.id = data.id
        this.name = data.name
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.badgesCount = data.badges_count || 0
        this.capture(data)
        return this
    }

    capture(data: BadgeSectionPayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.translations = {}
    }

    payload(): BadgeSectionPayload | any {
        return {
            id: this.id,
            name: this.name,
            published: this.published,
            sort_order: this.sortOrder,
            translations: this.translations,
        }
    }
}
