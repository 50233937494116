<template>
    <div>
        <app-bar :title="t('permissions_title')">
            <template #actions>
                <div v-if="permitted('*')" class="px-10">
                    <v-btn
                        @click="change"
                        class="sm sm-h"
                        color="orange"
                        :disabled="busy"
                    >{{ t('general_action_save') }}</v-btn>
                </div>
            </template>
        </app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <v-tabs
                class="px-4 my-7"
                height="52"
            >
                <div class="tabs-line"></div>
                <v-tab
                    :to="{ name: 'users' }"
                    class="pl-0"
                >{{ t('users_title') }}</v-tab>
                <v-tab
                    :to="{ name: 'roles' }"
                >{{ t('roles_title') }}</v-tab>
                <v-tab
                    :to="{ name: 'permissions' }"
                >{{ t('permissions_title') }}</v-tab>
                <v-tabs-items>
                    <card-collection
                        class="px-0-nested"
                        :busy="overlay"
                    >
                        <template #data>
                            <thead>
                            <tr>
                                <th style="width: 200px"></th>
                                <th
                                    v-for="role in roles.data"
                                    class="text-center"
                                >{{ role.name }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template
                                v-for="permission in permissions()"
                            >
                                <tr>
                                    <td class="titled">{{ permission.text }}</td>
                                    <td
                                        v-for="role in roles.data"
                                        class="text-center"
                                    >
                                        <v-icon
                                            v-if="role.permitted(permission.value)"
                                            class="touched"
                                            color="customized-green"
                                            @click="deletePermission(role, permission.value)"
                                            :disabled="! permitted('*')"
                                        >mdi-check-circle-outline</v-icon>
                                        <v-icon
                                            v-else
                                            class="touched"
                                            color="grey lighten-1"
                                            @click="addPermission(role, permission.value)"
                                            :disabled="! permitted('*')"
                                        >mdi-check-circle-outline</v-icon>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </template>
                    </card-collection>
                </v-tabs-items>
            </v-tabs>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { useRoleHttp } from '@/core/api/roles'
    import { RoleCollection } from '@/store/modules/role-collection'
    import { Role } from '@/core/models/role'
    import CardCollection from '@/components/CardCollection.vue'

    export default defineComponent({

        components: {
            CardCollection,
        },

        /**
         * Component constructor.
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                busy: true,
            })

            const queries = reactive({
                sort: 'id',
                default: '0',
            })

            const i18n = useI18n()
            const http = useRoleHttp()
            const store = useStore()

            const roles = computed(() => <RoleCollection>store.getters['roleCollection/get'])

            const capture = () => {
                state.overlay = true
                store.dispatch('roleCollection/get', queries).then(() => {
                    state.overlay = false
                })
            }

            const addPermission = (role: Role, permission: string) => {
                role.permissions.push(permission)
                state.busy = false
            }

            const deletePermission = (role: Role, permission: string) => {
                role.permissions = role.permissions.filter(p => p !== permission)
                state.busy = false
            }

            const change = () => {
                state.overlay = true
                http.updatePermissions(roles.value.data)
                    .then(() => {
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
                    .finally(() => {
                        state.overlay = false
                        state.busy = true
                    })
            }

            onMounted(() => {
                roles.value.data = []
                capture()
            })

            return {
                roles: roles.value,
                addPermission,
                deletePermission,
                change,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
