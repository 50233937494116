
<div>
    <app-bar :title="definition.name"/>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(definition)"
                >
                    {{ definition.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <confirm-modal
                    :title="t('general_title_confirm_action')"
                    @agree="del(definition)"
                >
                    <template #default="{ on, attrs }">
                        <v-btn
                            class="sm"
                            color="orange"
                            outlined
                            v-on="on"
                            v-bind="attrs"
                        >{{ t('general_action_delete') }}</v-btn>
                    </template>
                </confirm-modal>
            </div>
            <div class="float-left mr-10" style="width: 394px; max-width: 394px">
                <div class="form-subtitle">{{ t('glossary_definition_label_content') }}:</div>
                <div class="form-subtitle-description" v-html="definition.content"></div>
                <template v-if="definition.goal">
                    <div class="form-subtitle">{{ t('glossary_definition_label_goal') }}:</div>
                    <div class="form-subtitle-description" v-html="definition.goal"></div>
                </template>
            </div>
            <div class="float-left" style="max-width: calc(100% - 434px)">
                <card-unit :items="items()">
                    <template #title>{{ t('glossary_definition_label_id') }}: {{ definition.id }}</template>
                </card-unit>
            </div>
        </div>
    </v-layout>
</div>
