<template>
    <div>
        <app-bar :title="t('program_executions_title')"></app-bar>

        <template v-if="touchedExecution.exists()">
            <v-app-bar
                color="transparent"
                height="84"
                elevation="0"
                class="mb-n6 px-6"
            >
                <v-toolbar-title>{{ touchedExecution.program.name }}</v-toolbar-title>
                <v-btn
                    @click="touchedExecution.unset()"
                    plain
                    icon
                    absolute
                    right
                    class="mr-n8"
                ><v-icon>mdi-close</v-icon></v-btn>
            </v-app-bar>

            <div class="px-10 pb-6 overflow-hidden">
                <program-execution-view :execution="touchedExecution"></program-execution-view>
            </div>
        </template>

        <v-layout>
            <template v-if="! touchedExecution.exists()">
                <program-execution-collection
                    @touched="touchExecution"
                ></program-execution-collection>
            </template>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { TrainingProgramExecution } from '@/core/models/training-program-execution'
    import ProgramExecutionCollection from '@/components/program-executions/ProgramExecutionCollection.vue'
    import ProgramExecutionView from '@/components/program-executions/ProgramExecutionView.vue'

    export default defineComponent({
        components: {
            ProgramExecutionCollection,
            ProgramExecutionView,
        },

        /**
         * @param props
         */
        setup(props) {
            const state = reactive({})

            const touchedExecution = ref(new TrainingProgramExecution(<any>{}))

            const touchExecution = (execution: TrainingProgramExecution) => {
                Object.assign(touchedExecution.value, execution)
            }

            return {
                touchedExecution: <TrainingProgramExecution>touchedExecution.value,
                touchExecution,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>