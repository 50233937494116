<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        persistent
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">
                {{ t('general_label_search') }}
                <v-btn
                    @click="cancel"
                    icon
                    absolute
                    right
                    color="white"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-text-field
                    v-model="queries.search"
                    @click:clear="clear"
                    @keyup.enter="capture"
                    class="mt-4 mb-2"
                    clearable
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    :disabled="overlay"
                    :label="t('event_placeholder_enter_user_data')"
                ></v-text-field>
                <v-list>
                    <v-list-item
                        v-for="user in collection.data"
                        :key="`user_${user.id}`"
                        class="px-0"
                    >
                        {{ user.fullName }} | {{ user.id }}
                        <v-spacer></v-spacer>
                        <template v-if="isParticipant(user.id)">
                            <v-btn
                                @click="detach(user)"
                                class="exs mt-1"
                                color="orange"
                                small
                            >{{ t('general_action_remove') }}</v-btn>
                        </template>
                        <template v-else>
                            <v-btn
                                @click="attach(user)"
                                class="exs mt-1"
                                color="primary"
                                small
                            >{{ t('event_action_add_user') }}</v-btn>
                        </template>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useLocales } from '@/core/defaults'
    import { Event } from '@/core/models/event'
    import { User } from '@/core/models/user'
    import { UserCollection } from '@/store/modules/user-collection'

    export default defineComponent({
        name: 'UserSearchModal',

        props: {
            value: {
                type: Event,
                required: true
            }
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()
            const i18n = useI18n()

            const state = reactive({
                overlay: false,
                touched: false,
            })

            const queries = reactive({
                search: undefined,
                only_active: 1,
                sort: 'first_name,last_name',
            })

            const collection = computed(() => <UserCollection>store.getters['userCollection/get'])

            const capture = () => {
                state.overlay = true
                store.dispatch('userCollection/get', queries).then(() => {
                    state.overlay = false
                })
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const isParticipant = (id: number): boolean => {
                return !! props.value.participants.find(u => u.id === id)
            }

            const attach = (user: User) => {
                props.value.participants.push(user)
            }

            const detach = (user: User) => {
                props.value.participants = props.value.participants.filter(u => u.id !== user.id)
            }

            const cancel = () => {
                state.touched = false
                context.emit('confirm')
            }

            onMounted(() => {
                capture()
            })

            return {
                queries,
                collection: collection.value,
                cancel,
                clear,
                isParticipant,
                attach,
                detach,
                capture,
                ...useLocales(),
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
