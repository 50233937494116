
<v-overlay
    :value="overlay"
    :dark="false"
    opacity="0.5"
    color="#fff"
>
    <v-progress-circular
        indeterminate
        size="48"
        color="primary"
    ></v-progress-circular>
</v-overlay>
