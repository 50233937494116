<template>
    <v-dialog
        v-model="touched"
        width="auto"
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card class="px-4">

            <v-btn
                absolute
                fab
                top
                right
                small
                text
                class="mt-8"
                color="customized-primary"
                @click="cancel"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-text class="pt-10 pb-6 px-16">
                <v-date-picker
                    v-model="date"
                    color="customized-deep-primary--text"
                ></v-date-picker>

                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    @click="submit"
                    width="290"
                    dark
                >
                    Select date
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'

    export default defineComponent({
        name: 'DatePickerModal',

        props: [
            'value'
        ],

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                touched: false,
                date: props.value
            })

            const submit = () => {
                context.emit('updated', state.date)
                state.touched = false
            }

            const cancel = () => {
                context.emit('canceled')
                state.touched = false
            }

            return {
                submit,
                cancel,
                ...toRefs(state)
            }
        }
    })
</script>
