
<v-dialog
    v-model="touched"
    max-width="480"
    persistent
    scrollable
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
        <v-card-title class="customized-primary white--text">{{ t('user_card_title_personal_data') }}</v-card-title>

        <v-card-text class="pb-0">
            <v-form class="mt-6">
                <v-text-field
                    v-model="value.id"
                    type="number"
                    :label="t('user_label_id')"
                    required
                    outlined
                    dense
                    disabled
                    :error-messages="form.errors.get('id')"
                ></v-text-field>
                <v-text-field
                    v-model="value.registeredAt.format('DD/MM/YYYY HH:mm')"
                    type="text"
                    :label="t('user_label_registration_date')"
                    required
                    outlined
                    dense
                    disabled
                    :error-messages="form.errors.get('registered_at')"
                ></v-text-field>
                <v-text-field
                    v-model="value.firstName"
                    type="text"
                    :label="t('user_label_first_name')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('first_name')"
                ></v-text-field>
                <v-text-field
                    v-model="value.lastName"
                    type="text"
                    :label="t('user_label_last_name')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('last_name')"
                ></v-text-field>
                <v-select
                    v-model="value.gender"
                    :items="genders()"
                    :label="t('user_label_gender')"
                    required
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    :error-messages="form.errors.get('gender')"
                ></v-select>
                <date-picker-modal
                    :value="value.birthDate"
                    @updated="(v) => value.birthDate = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="value.birthDate"
                                type="text"
                                :label="t('user_label_birthday')"
                                required
                                outlined
                                dense
                                readonly
                                class="input-color-inherit"
                                :error-messages="form.errors.get('birth_date')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>
                <v-text-field
                    v-model="value.weight"
                    type="number"
                    :label="t('user_label_weight')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('weight')"
                ></v-text-field>
                <v-select
                    v-model="value.weightUnit"
                    :items="weightUnits()"
                    type="text"
                    :label="t('user_label_weight_unit')"
                    required
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    :error-messages="form.errors.get('weight_unit')"
                ></v-select>
                <v-text-field
                    v-model="value.height"
                    type="number"
                    :label="t('user_label_height')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('height')"
                ></v-text-field>
                <v-select
                    v-model="value.heightUnit"
                    :items="heightUnits()"
                    type="text"
                    :label="t('user_label_height_unit')"
                    required
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    :error-messages="form.errors.get('height_unit')"
                ></v-select>
                <google-autocomplete
                    v-model="value.city"
                    id="google-address"
                    :label="t('user_label_city')"
                    @change="updateAutocompleteText"
                    @placechanged="getAddressData"
                    dense
                    outlined
                    types="(cities)"
                    placeholder="Type city"
                    :error-messages="form.errors.get('city')"
                />
                <v-text-field
                    v-model="value.countryCodeIso"
                    type="text"
                    :label="t('user_label_country')"
                    required
                    outlined
                    dense
                    disabled
                    :error-messages="form.errors.get('country_code_iso')"
                ></v-text-field>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="submit"
                width="45%"
                :loading="form.busy"
                :disabled="form.busy"
            >{{ t('general_action_update') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="orange"
                @click="cancel"
                width="45%"
                dark
            >{{ t('general_action_cancel') }}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
