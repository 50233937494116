import { useHttp } from '../http'

export const useStatsHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get('/backstats', payload)
    }

    const users = <T>(payload: any): Promise<T> => {
        return useHttp().get('/backstats/users', payload)
    }

    const totals = <T>(payload: any): Promise<T> => {
        return useHttp().get('/backstats/totals', payload)
    }

    const ranks = <T>(payload: any): Promise<T> => {
        return useHttp().get('/backstats/ranks', payload)
    }

    return {
        get,
        users,
        totals,
        ranks,
    }
}