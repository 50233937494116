<template>
    <div style="padding-bottom: 26px">
        <editor
            v-model="content"
            :init="options"
            api-key="g9s4yw3w3h70a5m6v4fwf6lzzrczn9ot88hzq9578bh14abx"
        ></editor>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import Editor from '@tinymce/tinymce-vue'

    export default defineComponent({
        name: 'Tinymce',

        components: {
            Editor
        },

        props: {
            value: String
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                content: props.value || '',
                options: {
                    height : '350',
                    language: useI18n().locale.value === 'fr' ? 'fr_FR' : undefined,
                    plugins: [
                        'advlist',
                        'autolink',
                        'link',
                        'image',
                        'lists',
                        'charmap',
                        'preview',
                        'fullscreen',
                        'code',
                    ],
                }
            })

            watch(() => props.value, (newValue) => {
                state.content = newValue || ''
            })

            watch(() => state.content, (newValue) => {
                context.emit('input', newValue)
            })

            return {
                ...toRefs(state)
            }
        }
    })
</script>
