import { useHttp } from '../http'

export const useBadgeSectionHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`badge-sections/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`badge-sections`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`badge-sections/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`badge-sections`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`badge-sections/${id}`)
    }

    return {
        find,
        create,
        update,
        get,
        del
    }
}