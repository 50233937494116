import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useNotificationHttp } from '@/core/api/notifications'
import { Notification, NotificationPayload } from '@/core/models/notification'

const state: Notification = new Notification(<NotificationPayload>{})

export const notification: Module<Notification, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Notification, payload: NotificationPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        getOrNew: <T>(context: any, payload: { id: number | undefined, payload: any }): Promise<T> => {
            state.unset()
            if (payload.id) {
                return context.dispatch('get', payload)
            } else {
                return new Promise<T>((resolve) => {
                    resolve({} as T)
                })
            }
        },
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useNotificationHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        create: <T>(context: any, payload: { payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useNotificationHttp()
                    .create(payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useNotificationHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        updateOrCreate: <T>(context: any, payload: { payload: any }): Promise<T> => {
            return payload.payload.id ?
                context.dispatch('update', { id: payload.payload.id, payload: payload.payload }) :
                context.dispatch('create', { payload: payload.payload })
        },
        photo: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useNotificationHttp()
                    .photo(payload.id, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        delete: <T>(context: any, payload: { id: number }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useNotificationHttp()
                    .del(payload.id)
                    .then((data: any) => {
                        context.commit('update', new Notification(<NotificationPayload>{}))
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Notification): Notification => {
            return state
        },
    }
}