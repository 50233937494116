import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'
import { Workout, WorkoutPayload } from '@/core/models/workout'
import { WorkoutExecution, WorkoutExecutionPayload } from '@/core/models/workout-execution'

export interface FeedbackPayload {
    id: number
    user_id: number
    workout_id: number
    workout_execution_id: number
    feeling: string
    level: string
    fit_in_time: string
    description: string
    comment: string
    rating: number
    created_at: string
    user?: UserPayload
    workout?: WorkoutPayload
    workout_execution?: WorkoutExecutionPayload
}

export class Feedback {

    id: number
    userId: number
    workoutId: number
    workoutExecutionId: number
    feeling: string
    level: string
    fitInTime: string
    description: string
    comment: string
    rating: number
    createdAt: Moment
    user: User
    workout: Workout
    workoutExecution: WorkoutExecution

    constructor(data: FeedbackPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.workoutId = data.workout_id
        this.workoutExecutionId = data.workout_execution_id
        this.feeling = data.feeling
        this.level = data.level
        this.fitInTime = data.fit_in_time
        this.description = data.description
        this.comment = data.comment
        this.rating = data.rating
        this.createdAt = moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
        this.workout = new Workout(data.workout || <WorkoutPayload>{})
        this.workoutExecution = new WorkoutExecution(data.workout_execution || <WorkoutExecutionPayload>{})
        this.capture(data)
    }

    fill(data: FeedbackPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.workoutId = data.workout_id
        this.workoutExecutionId = data.workout_execution_id
        this.feeling = data.feeling
        this.level = data.level
        this.fitInTime = data.fit_in_time
        this.description = data.description
        this.comment = data.comment
        this.rating = data.rating
        this.createdAt = moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
        this.workout = new Workout(data.workout || <WorkoutPayload>{})
        this.workoutExecution = new WorkoutExecution(data.workout_execution || <WorkoutExecutionPayload>{})
        this.capture(data)
        return this
    }

    capture(data: FeedbackPayload): this {
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }

    payload(): FeedbackPayload | any {
        return {
            id: this.id,
            feeling: this.feeling,
            level: this.level,
            fit_in_time: this.fitInTime,
            description: this.description,
            comment: this.comment,
        }
    }
}
