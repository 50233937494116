<template>
    <v-card
        class="fill-width pb-8"
        color="transparent"
        rounded="0"
        elevation="0"
        :disabled="busy"
        loader-height="2"
    >
        <slot name="filters"></slot>
        <slot name="alerts"></slot>

        <v-card-text :class="used ? 'pa-0' : 'pa-4'">
            <v-simple-table class="transparent">
                <template v-slot:default>
                    <slot name="data"></slot>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions v-if="meta && meta.total > 0">
            <v-pagination
                v-model="queries.page"
                :length="pageCount"
                total-visible="7"
            ></v-pagination>
            <span class="pagination-meta">{{ t('general_pagination_meta', { from: meta.from, to: meta.to, total: meta.total }) }}</span>
        </v-card-actions>
        <slot name="export"></slot>
    </v-card>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { MetaPayload, pageCount } from '@/core/utils/pagination'

    export default defineComponent({

        name: 'CardCollection',

        props: {
            meta: {
                type: Object as () => MetaPayload,
                default: () => undefined
            },
            busy: Boolean,
            used: Boolean
        },

        setup(props, context) {
            const state = reactive({
                pageCount: 1,
            })

            const queries = reactive({
                page: 1,
            })

            watch(() => [queries.page], () => {
                update()
            })

            watch(() => props.meta, (newValue) => {
                queries.page = newValue?.current_page > newValue?.last_page ? newValue?.last_page : newValue?.current_page
                state.pageCount = pageCount(newValue)
            })

            const update = () => {
                context.emit('updated', queries)
            }

            return {
                queries,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
