import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { usePageHttp } from '@/core/api/pages'
import { Page, PagePayload } from '@/core/models/page'

export type PageCollection = {
    data: Array<Page>,
    meta?: MetaPayload,
    links?: any
}

const state: PageCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const pageCollection: Module<PageCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: PageCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: PagePayload) => {
                state.data.push(new Page(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                usePageHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: PageCollection): PageCollection => {
            return state
        }
    }
}