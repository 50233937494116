<template>
    <v-text-field
        v-model="autocompleteText"
        type="text"
        :ref="id"
        :class="classname"
        :id="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :label="label"
        :dense="dense"
        :outlined="outlined"
        :error-messages="errorMessages"
        autocomplete="new-location"
        @change="onChange"
        @keyup="onKeyUp"
    />
</template>

<script>
    import { Loader } from '@googlemaps/js-api-loader'

    const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
        version: 'weekly',
        libraries: ['places']
    })

    const ADDRESS_COMPONENTS = {
        subpremise: 'short_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name',
    };

    /*
    |  By default, we're only including basic place data because requesting these
    |  fields place data is not additionally charged by Google. Please refer to:
    |  https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
    */
    const BASIC_DATA_FIELDS = [
        'address_components',
        'adr_address',
        'alt_id',
        'formatted_address',
        'geometry',
        'icon',
        'id',
        'name',
        'business_status',
        'photo',
        'place_id',
        'scope',
        'type',
        'url',
        'utc_offset_minutes',
        'vicinity'
    ]

    export default {
        name: 'GoogleAutocomplete',

        props: {
            id: {
                type: String,
                required: true
            },
            classname: String,
            placeholder: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            types: {
                type: String,
                default: 'address'
            },
            fields: {
                type: Array,
                default: function () {
                    return BASIC_DATA_FIELDS
                },
            },
            country: {
                type: [String, Array],
                default: null
            },
            value: String,
            label: String,
            dense: Boolean,
            outlined: Boolean,
            errorMessages: String,
            fulled: Boolean
        },

        data() {
            return {
                /**
                 * The Autocomplete object.
                 *
                 * @type {Autocomplete}
                 * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
                 */
                autocomplete: null,

                /**
                 * Autocomplete input text
                 * @type {String}
                 */
                autocompleteText: this.value,

                geolocation: {
                    /**
                     * Google Geocoder Objet
                     * @type {Geocoder}
                     * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
                     */
                    geocoder: null,

                    /**
                     * Filled after geolocate result
                     * @type {GeolocationCoordinates}
                     * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
                     */
                    loc: null,

                    /**
                     * Filled after geolocate result
                     * @type {Position}
                     * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
                     */
                    position: null
                }
            }
        },

        watch: {
            value: function (newVal, oldVal) {
                this.autocompleteText = newVal
            },
            autocompleteText: function (newVal, oldVal) {
                this.$emit('inputChange', {newVal, oldVal}, this.id)
            },
            country: function(newVal, oldVal) {
                this.autocomplete.setComponentRestrictions({
                    country: this.country === null ? [] : this.country
                });
            }
        },

        mounted: function () {
            const options = {}

            if (this.types) {
                options.types = [this.types]
            }

            if (this.country) {
                options.componentRestrictions = {
                    country: this.country
                }
            }

            /**
             * @type {HTMLElement}
             */
            let ref = document.getElementById(this.id)

            loader.load().then((google) => {
                this.autocomplete = new google.maps.places.Autocomplete(ref, options)

                this.autocomplete.setFields(this.fields)

                this.autocomplete.addListener('place_changed', this.onPlaceChanged)

                ref.placeholder = ''
            })
        },

        methods: {
            onPlaceChanged() {
                let place = this.autocomplete.getPlace()

                if (! place.geometry) {
                    // User entered the name of a Place that was not suggested and pressed the Enter key,
                    // or the Place Details request failed.
                    this.$emit('no-results-found', place, this.id)
                    return
                }

                if (place.address_components !== undefined) {
                    this.$emit('placechanged', this.formatResult(place), place, this.id)

                    let addressNumber, streetNumber, route

                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0]
                        let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
                        if (addressType === 'street_number') {
                            streetNumber = val
                        } else if (addressType === 'route') {
                            route = val
                        }
                    }

                    if (streetNumber && route) {
                        addressNumber = streetNumber + ' ' + route
                    } else if (place.name) {
                        addressNumber = place.name + (this.fulled ? ', ' + place.address_components[3].long_name : '')
                    }

                    this.autocompleteText = addressNumber
                    this.onChange()
                }
            },

            onChange(value) {
                this.$emit('change', this.autocompleteText)
            },

            onKeyUp(event) {
                this.$emit('change', this.$refs[this.id].value)
            },

            formatResult(place) {
                let returnData = {};
                for (let i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0]

                    if (ADDRESS_COMPONENTS[addressType]) {
                        returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
                    }
                }

                returnData['latitude'] = place.geometry.location.lat()
                returnData['longitude'] = place.geometry.location.lng()

                return returnData
            }
        }
    }
</script>