<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="role.exists() ? t('role_title_update') : t('role_title_create')"
            back="/users/roles"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('role_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="role.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="role.name"
                                    :items="role.translations['name']"
                                    @touch="(v) => role.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('role_label_description') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="role.description"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('description')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="role.description"
                                    :items="role.translations['description']"
                                    @touch="(v) => role.translations['description'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'roles' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ role.exists() ? t('general_action_save') : t('role_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { Role } from '@/core/models/role'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id'] || undefined

            const enums = useEnums()

            const state = reactive({
                overlay: true,
            })

            const roleModel = computed(() => <Role>store.getters['role/get'])

            const form = reactive(useForm())

            const capture = () => {
                store.dispatch('role/getOrNew', { id: id, payload: { append: 'translations' } }).then(() => {
                    state.overlay = false
                })
            }

            const submit = () => {
                form.usingSubmit(store.dispatch('role/updateOrCreate', {
                    id: id,
                    payload: roleModel.value.payload()
                }))
                    .then(() => {
                        router.push({ name: 'roles' })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            onMounted(() => {
                capture()
            })

            return {
                form,
                capture,
                submit,
                role: roleModel.value,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
