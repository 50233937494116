<template>
    <v-toolbar
        :class="{ dense: dense }"
        class="stat"
        max-height="68"
        max-width="202"
    >
        <div class="stat-name" v-html="name"></div>
        <v-spacer></v-spacer>
        <div class="stat-count" v-text="count"></div>
    </v-toolbar>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'

    export default defineComponent({
        name: 'Stat',

        props: {
            name: {
                type: String,
                required: true
            },
            count: {
                required: true
            },
            dense: Boolean
        },

        setup(props, context) {
            const state = reactive({})

            return {
                ...toRefs(state)
            }
        }
    })
</script>
