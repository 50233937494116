
<v-text-field
    v-model="autocompleteText"
    type="text"
    :ref="id"
    :class="classname"
    :id="id"
    :placeholder="placeholder"
    :disabled="disabled"
    :label="label"
    :dense="dense"
    :outlined="outlined"
    :error-messages="errorMessages"
    autocomplete="new-location"
    @change="onChange"
    @keyup="onKeyUp"
/>
