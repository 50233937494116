
<v-flex fill-height>

    <v-navigation-drawer
        class="app-navigation-drawer"
        fixed
        permanent
        :v-scroll="false"
        width="220"
    >
        <template v-slot:prepend>
            <v-list>
                <v-list-item class="pt-3">
                    <a
                        href="/"
                        @click.prevent="go({ name: 'home' })"
                    >
                        <img
                            src="/images/logo.svg"
                            width="158"
                            height="37"
                        />
                    </a>
                </v-list-item>
            </v-list>
        </template>

        <v-list>
            <v-list-item-group
                active-class="app-navigation-drawer-touched"
                class="fill-height overflow-x-auto"
            >
                <template v-for="item in items()">
                    <v-list-group
                        v-if="item.pages"
                        class="mb-5 subpages"
                        v-model="touched[item.to]"
                    >
                        <template v-slot:activator>
                            <v-list-item
                                :key="`menu_${item.to}`"
                                :to="item.to"
                                :ripple="false"
                                @click="touched[item.to] = true"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </template>
                        <v-list-item
                            v-for="page in item.pages"
                            :key="`page_${page.to}`"
                            :to="page.to"
                        >
                            <v-list-item-title>{{ page.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        :key="`menu_${item.to}`"
                        :to="item.to"
                        :exact="item.exact"
                        class="mb-5"
                        @click="touched = {}"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </template>

                <filer v-if="permitted('admin_file_manager')">
                    <template #default="{ on, attrs }">
                        <v-list-item
                            href="javascript:"
                            :key="`menu_file_manager`"
                            class="mb-5"
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-google-drive</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ t('file_manager_title') }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </filer>
                <v-list-item
                    v-if="permitted('admin_params')"
                    :key="`menu_params`"
                    to="/params"
                    class="mb-5"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ t('params_title') }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <template v-slot:append>
            <v-list>
                <v-list-item
                    to="/logout"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ t('logout_button') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>

    <v-flex
        style="margin-left: 220px"
        fill-height
    >
        <router-view />
    </v-flex>

    <v-snackbar-queue
        :items="notifications"
        @remove="readNotification"
        top
        right
        close-button-icon="mdi-close"
    ></v-snackbar-queue>
</v-flex>
