<template>
    <v-flex>
        <v-progress-linear
            v-if="overlay && ! (!! user)"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :class="{ 'px-0-nested': !! user }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    :class="{ 'mt-7 mb-1': ! (!! user) }"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-select
                        v-model="queries.status"
                        :items="complaintStatuses()"
                        class="md ml-5"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('complaint_label_status')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="user_id"
                            @touched="onSort"
                        >{{ t('complaint_label_user_id') }}</sort-field>
                    </th>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="id"
                            @touched="onSort"
                        >{{ t('complaint_label_id') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="first_name"
                            @touched="onSort"
                        >{{ t('complaint_label_first_name') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="last_name"
                            @touched="onSort"
                        >{{ t('complaint_label_last_name') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="email"
                            @touched="onSort"
                        >{{ t('complaint_label_email') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="created_at"
                            @touched="onSort"
                        >{{ t('complaint_label_creation_date') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="status"
                            @touched="onSort"
                        >{{ t('complaint_label_status') }}</sort-field>
                    </th>
                    <th class="text-center">{{ t('general_label_action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="8">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="complaint in collection.data">
                    <tr>
                        <td>
                            <a
                                @click="go({ name: 'trainees.show', params: { id: complaint.userId } })"
                            >#{{ complaint.userId }}</a>
                        </td>
                        <td>
                            <a
                                :href="`/complaints/${complaint.id}/view`"
                                @click.prcomplaint="view(complaint)"
                            >{{ complaint.id }}</a>
                        </td>
                        <td>{{ complaint.firstName }}</td>
                        <td>{{ complaint.lastName }}</td>
                        <td>{{ complaint.email }}</td>
                        <td>{{ complaint.createdAt.format('DD/MM/YYYY HH:mm') }}</td>
                        <td
                            style="font-weight: 600"
                            :class="{
                                'orange--text text--darken-4': complaint.status === 'new',
                                'customized-primary--text': complaint.status === 'in_progress',
                            }"
                        >{{ complaintStatusTitle(complaint.status) }}</td>
                        <td
                            class="text-center"
                        >
                            <v-btn
                                icon
                                :to="`/complaints/${complaint.id}/view`"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
            <template #export>
                <v-btn
                    @click="_export"
                    class="exs mt-4 ml-4"
                    color="primary"
                    small
                >{{ t('general_action_export') }}</v-btn>
            </template>
        </card-collection>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useRouter } from '@/composition-api'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { ComplaintCollection } from '@/store/modules/complaint-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { Complaint } from '@/core/models/complaint'
    import { User } from '@/core/models/user'
    import { useComplaintHttp } from '@/core/api/complaints'

    export default defineComponent({
        name: 'ComplaintCollection',

        components: {
            DatePickerModal,
            CardCollection,
            SortField,
        },

        props: {
            user: {
                type: User
            },
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined,
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                user_id: props.user?.id,
                date_from: undefined,
                date_to: undefined,
                status: undefined,
            })

            const reset = () => {
                queries.search = undefined
                queries.date_from = undefined
                queries.date_to = undefined
                queries.status = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <ComplaintCollection>store.getters['complaintCollection/get'])

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const view = (complaint: Complaint) => {
                router.push({ name: 'complaints.view', params: <any>{ id: complaint.id } })
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('complaintCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const _export = () => {
                state.overlay = true
                useComplaintHttp()._export(queries).then((data: any) => {
                    state.overlay = false
                    const e = document.createElement('a')
                    e.href = window.URL.createObjectURL(data)
                    e.setAttribute('download', 'Complaints.csv')
                    document.body.appendChild(e)
                    e.click()
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            const go = (route: any) => {
                router.push(route)
            }

            watch(() => [
                queries.sort,
                queries.status,
            ], () => {
                capture()
            })

            onMounted(() => {
                collection.value.data = []
                capture()
            })

            return {
                time,
                queries,
                collection: collection.value,
                moment,
                updated,
                onSort,
                search,
                clear,
                reset,
                view,
                go,
                _export,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>