
<div class="page-customized">
    <user-statistic-collection
        v-if="collect"
        used
        @unused="unused"
    ></user-statistic-collection>

    <template v-else>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="notification.exists() ? t('notification_title_update') : t('notification_title_create')"
            back="/notifications"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_title') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="notification.title"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('title')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="notification.title"
                                    :items="notification.translations['title']"
                                    @touch="(v) => notification.translations['title'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_message') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="notification.message"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('message')"
                                ></v-textarea>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="notification.message"
                                    :items="notification.translations['message']"
                                    @touch="(v) => notification.translations['message'] = v"
                                    text
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="notification.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="notification.photoUrl ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="form.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="notification.photo = undefined; photoForm.errors.clear('photo')"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_send_at') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="sendAt"
                                    type="datetime-local"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('send_at')"
                                ></v-text-field>
                            </div>
                        </div>
                        <v-switch
                            class="ma-0"
                            v-model="notification.cta"
                            :label="t('notification_label_cta')"
                        ></v-switch>
                        <template v-if="notification.cta">
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('notification_label_cta_text') }}</div>
                                <div class="horizontal-input">
                                    <v-text-field
                                        v-model="notification.ctaText"
                                        type="text"
                                        required
                                        outlined
                                        dense
                                        :error-messages="form.errors.get('cta_text')"
                                    ></v-text-field>
                                </div>
                                <div class="horizontal-actions">
                                    <edit-translations-modal
                                        v-model="notification.ctaText"
                                        :items="notification.translations['cta_text']"
                                        @touch="(v) => notification.translations['cta_text'] = v"
                                    ></edit-translations-modal>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('notification_label_cta_params') }}</div>
                                <div class="horizontal-input">
                                    <v-textarea
                                        v-model="notification.ctaParamsStr"
                                        required
                                        outlined
                                        dense
                                        :error-messages="form.errors.get('cta_params')"
                                    ></v-textarea>
                                </div>
                            </div>
                        </template>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_role_id') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="notification.roleId"
                                    :items="roles"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('role_id')"
                                ></v-select>
                            </div>
                        </div>
                        <v-switch
                            class="ma-0"
                            v-model="notification.force"
                            :label="t('notification_label_force')"
                        ></v-switch>

                        <v-tabs v-if="! notification.roleId" height="52">
                            <div class="tabs-line"></div>
                            <v-tab class="pl-0">{{ t('users_title') }}</v-tab>
                            <v-tab-item class="py-5">
                                <v-btn
                                    @click="collect = true"
                                    v-ripple="false"
                                    plain
                                    color="orange darken-4"
                                    class="add mr-3"
                                >
                                    <v-icon left size="16">mdi-plus-circle</v-icon>{{ t('notification_action_add_user') }}
                                </v-btn>

                                <card-collection
                                    :meta="meta"
                                    :busy="overlay"
                                    used
                                >
                                    <template #data>
                                        <tbody>
                                        <tr v-if="users.data.length === 0">
                                            <td class="text-center" colspan="9">{{ t('general_text_no_data') }}</td>
                                        </tr>
                                        <template v-else v-for="(user) in users.data">
                                            <tr>
                                                <td class="titled">{{ user.id }}</td>
                                                <td class="titled">{{ user.fullName }}</td>
                                                <td>{{ user.email }}</td>
                                                <td>{{ user.registeredAt.format('DD/MM/YYYY') }}</td>
                                                <td>{{ localeTitle(user.preferredLocale) }}</td>
                                                <td>{{ user.countryCodeIso }}</td>
                                                <td>{{ user.city }}</td>
                                                <td>{{ providerTitle(user.provider) }}</td>
                                                <td class="text-center">
                                                    <v-menu
                                                        offset-y
                                                        left
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list dense>
                                                            <v-list-item
                                                                @click.prevent="detach(user)"
                                                            >
                                                                <v-list-item-title
                                                                    class="orange--text"
                                                                >{{ t('general_action_delete') }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>
                                    </template>
                                </card-collection>
                            </v-tab-item>
                        </v-tabs>
                    </v-form>
                </v-card-text>
                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'notifications' }"
                        class="sm mr-4"
                        color="orange"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="primary"
                        class="sm mr-4"
                        @click.prnotification="submit('send')"
                    >{{ t('general_action_save') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        class="sm"
                        color="primary"
                        outlined
                        @click.prnotification="submit('draft')"
                    >{{ t('general_action_draft') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </template>
</div>
