<template>
    <v-card class="app-card">
        <v-card-title v-if="! untitled">
            <slot name="title">Info</slot>
        </v-card-title>
        <v-card-text>
            <v-list>
                <v-list-item
                    :key="`card_${item.value}_${index}`"
                    v-for="(item, index) in items"
                >
                    <template
                        v-if="item.route && ! item.value"
                    >
                        <a
                            class="customized-deep-primary--text text-decoration-none"
                            href="javascript:"
                            @click="go(item.route)"
                        >{{ item.text }}</a>
                    </template>
                    <template
                        v-else
                    >
                        <span :class="{'py-4': ! item.value && ! item.route}">{{ item.text }}</span><template v-if="item.value !== undefined">:</template>
                        <v-spacer></v-spacer>
                        <template v-if="item.route">
                            <a
                                class="customized-primary--text text-decoration-none"
                                href="javascript:"
                                @click="go(item.route)"
                            ><strong>{{ item.value }}</strong></a>
                        </template>
                        <template v-else-if="item.value instanceof Array">
                            <div class="text-right">
                                <template
                                    v-for="(v, i) in item.value"
                                >
                                    <a
                                        class="customized-primary--text text-decoration-none"
                                        href="javascript:"
                                        @click="go(v.route)"
                                    ><strong class="pl-0">{{ v.value }}</strong></a>
                                    <template
                                        v-if="item.value.length > i + 1"
                                    ><strong class="pl-1 pr-1">/</strong></template>
                                </template>
                            </div>
                        </template>
                        <strong v-else>{{ item.value }}</strong>
                    </template>
                    <slot :name="`unit_${index}`"></slot>
                </v-list-item>
            </v-list>
        </v-card-text>
        <slot name="actions"></slot>
    </v-card>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useRouter } from '@/composition-api'

    export default defineComponent({

        name: 'CardUnit',

        props: {
            items: {
                type: Array as () => {
                    text: string,
                    value?: string | number | { value: any, route: any }[],
                    route?: any,
                }[],
                default: () => []
            },
            untitled: Boolean,
        },

        setup(props, context) {
            const router = useRouter()

            const state = reactive({})

            const go = (route: any) => {
                router.push(route)
                context.emit('routed')
            }

            return {
                go,
                ...toRefs(state)
            }
        }
    })
</script>
