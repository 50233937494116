import { useHttp } from '../http'

export const useFeedbackHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`feedback/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`feedback`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`feedback/${id}`)
    }

    const _export = <T>(payload: any): Promise<T> => {
        return useHttp().get(`feedback/export`, payload, { responseType: 'blob' })
    }

    return {
        find,
        get,
        del,
        _export
    }
}