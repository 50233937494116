import moment, { Moment } from 'moment/moment'

export interface RolePayload {
    id: number
    name: string
    description: string
    default: number
    permissions?: string[]
    created_at?: string
    translations?: {}
}

export class Role {

    id: number
    name: string
    description: string
    default: number
    permissions: string[]
    createdAt?: Moment
    translations?: {} = <any>{}

    constructor(data: RolePayload) {
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.default = data.default
        this.permissions = data.permissions || []
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
    }

    fill(data: RolePayload): this {
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.default = data.default
        this.permissions = data.permissions || []
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
        return this
    }

    capture(data: RolePayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    permitted(permission: string): boolean {
        return !! this.permissions.find(e => e === permission)
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.translations = {}
        return this
    }

    payload(): RolePayload {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            default: this.default,
            permissions: this.permissions,
            translations: this.translations,
        }
    }
}