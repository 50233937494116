
<div class="fill-height">
    <app-bar :title="t('dashboard_title')"></app-bar>

    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <div class="dashboard">
        <div class="dashboard-units">
            <div class="dashboard-stats">
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i class="ai ai-registration"></i>{{ t('dashboard_title_registration') }}</div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ t('dashboard_stat_day') }}</td>
                            <td><div></div></td>
                            <td>{{ registrations.day }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_week') }}</td>
                            <td><div></div></td>
                            <td>{{ registrations.week }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_month') }}</td>
                            <td><div></div></td>
                            <td>{{ registrations.month }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_year') }}</td>
                            <td><div></div></td>
                            <td>{{ registrations.year }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_total') }}</td>
                            <td><div></div></td>
                            <td>{{ statTotal.totalRegistrations }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i class="ai ai-workout"></i>{{ t('dashboard_title_workout_executions') }}</div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ t('dashboard_stat_day') }}</td>
                            <td><div></div></td>
                            <td>{{ workoutExecutions.day }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_week') }}</td>
                            <td><div></div></td>
                            <td>{{ workoutExecutions.week }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_month') }}</td>
                            <td><div></div></td>
                            <td>{{ workoutExecutions.month }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_year') }}</td>
                            <td><div></div></td>
                            <td>{{ workoutExecutions.year }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_total') }}</td>
                            <td><div></div></td>
                            <td>{{ statTotal.totalWorkoutExecutions }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dashboard-stat mr-0">
                    <div class="dashboard-stat-title"><i class="ai ai-program"></i>{{ t('dashboard_title_program_executions') }}</div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ t('dashboard_stat_day') }}</td>
                            <td><div></div></td>
                            <td>{{ programExecutions.day }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_week') }}</td>
                            <td><div></div></td>
                            <td>{{ programExecutions.week }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_month') }}</td>
                            <td><div></div></td>
                            <td>{{ programExecutions.month }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_year') }}</td>
                            <td><div></div></td>
                            <td>{{ programExecutions.year }}</td>
                        </tr>
                        <tr>
                            <td>{{ t('dashboard_stat_total') }}</td>
                            <td><div></div></td>
                            <td>{{ statTotal.totalProgramExecutions }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="dashboard-stat mt-10 ml-9">
                <div class="dashboard-stat-title"><i class="ai ai-swimmer"></i>{{ t('dashboard_title_top_swimmers') }}</div>
                <ul class="dashboard-stat-rankings">
                    <li v-for="rank in ranks.data">
                        <a
                            :href="`/trainees/${rank.userId}`"
                            @click.prevent="viewUser(rank.userId)"
                        >
                            <span>#{{ rank.rank }}</span>{{ rank.userFirstName }} {{ rank.userLastName }} - {{ Math.round(rank.distance / 1000) }}km
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="dashboard-charts">
            <div class="dashboard-chart">
                <div class="dashboard-chart-title" v-html="t('dashboard_title_total_distance')"></div>
                <div class="dashboard-chart-bar">
                    <div class="dashboard-chart-bar-step blue"></div>
                    <div class="dashboard-chart-bar-value">{{ statTotal._totalDistance }}</div>
                </div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title" v-html="t('dashboard_title_total_duration')"></div>
                <div class="dashboard-chart-bar">
                    <div class="dashboard-chart-bar-step purple"></div>
                    <div class="dashboard-chart-bar-value">{{ statTotal._totalDuration }}</div>
                </div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title">{{ t('dashboard_title_genders') }}</div>
                <div class="dashboard-chart-body" id="gender-chart"></div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title">{{ t('dashboard_title_ages') }}</div>
                <div class="dashboard-chart-body" id="age-chart"></div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title">{{ t('dashboard_title_levels') }}</div>
                <div class="dashboard-chart-body" id="level-chart"></div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title">{{ t('dashboard_title_strokes') }}</div>
                <div class="dashboard-chart-body" id="stroke-chart"></div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title dashboard-chart-title-v2 pb-10">{{ t('dashboard_title_top_countries') }}</div>
                <div class="dashboard-chart-body" id="country-chart"></div>
            </div>
            <div class="dashboard-chart">
                <div class="dashboard-chart-title dashboard-chart-title-v2 pb-10">{{ t('dashboard_title_top_cities') }}</div>
                <div class="dashboard-chart-body" id="city-chart"></div>
            </div>
        </div>
    </div>
</div>
