<template>
    <div>
        <app-bar :title="t('programs_title')"></app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <card-collection
                :meta="meta"
                :busy="overlay"
                @updated="updated"
            >
                <template #filters>
                    <v-toolbar
                        v-if="permitted('admin_program_management')"
                        elevation="0"
                        color="transparent"
                        class="mt-7 mb-n3"
                        height="56"
                    >
                        <v-btn
                            :to="{ name: 'programs.create' }"
                            class="sm"
                            color="orange"
                        >{{ t('general_action_create_new') }}</v-btn>
                    </v-toolbar>
                    <v-toolbar
                        elevation="0"
                        color="transparent"
                        class="mt-7 mb-1"
                        height="56"
                    >
                        <v-text-field
                            v-model="queries.search"
                            @click:clear="clear"
                            class="input-search md mr-2"
                            clearable
                            outlined
                            dense
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :label="t('general_label_search')"
                        ></v-text-field>

                        <v-spacer></v-spacer>

                        <v-select
                            v-model="queries.workout_type"
                            :items="[ { text: '-', value: '' } ].concat(workoutTypes())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('programs_label_workout_type')"
                        ></v-select>

                        <v-spacer></v-spacer>

                        <v-select
                            v-model="queries.goal_id"
                            :items="goals"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('programs_label_goals')"
                        ></v-select>

                        <v-spacer></v-spacer>

                        <v-select
                            v-model="queries.level"
                            :items="[ { text: '-', value: '' } ].concat(levels())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('programs_label_level')"
                        ></v-select>

                        <v-spacer></v-spacer>

                        <v-select
                            v-model="queries.calendar_duration"
                            :items="[ { text: '-', value: '' } ].concat(calendarDurationValues())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('programs_label_duration')"
                        ></v-select>

                        <v-spacer></v-spacer>

                        <v-select
                            v-model="queries.user_id"
                            :items="users"
                            class="md"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('program_label_coach')"
                        ></v-select>

                        <v-btn
                            @click="search"
                            class="sm mx-5"
                            color="primary"
                        >{{ t('general_action_search') }}</v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                            v-ripple="false"
                            plain
                            color="orange darken-4"
                            @click="reset"
                        >{{ t('general_action_reset_filters') }}</v-btn>

                    </v-toolbar>

                    <v-toolbar
                        elevation="0"
                        color="transparent"
                        class="mb-0"
                        height="56"
                    >
                        <v-checkbox
                            v-model="queries.only_suspended"
                            :label="t('general_label_show_suspended')"
                            hide-details
                            class="ma-0 mr-5 customized-deep-primary-color"
                        />
                        <v-checkbox
                            v-model="queries.archived"
                            :label="t('workout_label_show_archived')"
                            hide-details
                            class="ma-0 customized-deep-primary-color"
                        />
                    </v-toolbar>
                </template>

                <template #data>
                    <thead>
                    <tr>
                        <th class="touched">
                            <sort-field
                                v-model="queries.sort"
                                field="id"
                                @touched="onSort"
                            >{{ t('program_label_id') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="name"
                                @touched="onSort"
                            >{{ t('program_label_name') }}</sort-field>
                        </th>
                        <th>{{ t('program_label_created_by') }}</th>
                        <th>{{ t('program_label_workout_types') }}</th>
                        <th>{{ t('program_label_goals') }}</th>
                        <th>{{ t('program_label_duration') }}</th>
                        <th class="text-center">{{ t('general_label_action') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="collection.data.length === 0">
                        <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                    </tr>
                    <template v-else v-for="program in collection.data">
                        <tr>
                            <td>
                                <a
                                    :href="`/programs/${program.id}/view`"
                                    @click.prevent="view(program)"
                                    :class="{ 'orange--text': ! program.enabled }"
                                >
                                    {{ program.id }}
                                </a>
                            </td>
                            <td>
                                <a
                                    :class="levelColor(program.level) + '--text'"
                                    :href="`/programs/${program.id}/view`"
                                    @click.prevent="view(program)"
                                >
                                    {{ program.name }}
                                </a>
                            </td>
                            <td>
                                <template v-if="! program.userId">{{ creatorTitle(program.creator) }}</template>
                                <a
                                    v-else
                                    @click="go({ name: 'trainees.show', params: { id: program.userId }, query: { tab: '2' } })"
                                >#{{ program.user.fullName }}</a>
                            </td>
                            <td>
                                {{ workoutTypesStrTitle(program.workoutTypes) }}
                            </td>
                            <td>
                                {{ program.goalsStrTitle }}
                            </td>
                            <td>
                                {{ program.calendarDuration }} {{ calendarDurationUnitTitle(program.calendarDurationUnit).toLowerCase() }}
                            </td>
                            <td class="text-center">
                                <v-menu
                                    offset-y
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            @click.prevent="view(program)"
                                        >
                                            <v-list-item-title
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_details') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="permitted('admin_program_management')"
                                            @click.prevent="edit(program)"
                                        >
                                            <v-list-item-title
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_edit') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="permitted('admin_program_management') && ! program.archived"
                                            @click.prevent="suspend(program)"
                                        >
                                            <v-list-item-title
                                                v-if="program.enabled"
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_suspend') }}</v-list-item-title>
                                            <v-list-item-title
                                                v-else
                                                class="orange--text"
                                            >{{ t('general_action_unsuspend') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="permitted('admin_program_management')"
                                            @click.prevent="archived(program)"
                                        >
                                            <v-list-item-title
                                                v-if="! program.archived"
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_archived') }}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                v-else
                                                class="orange--text"
                                            >{{ t('general_action_unarchived') }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <confirm-modal
                                            v-if="permitted('admin_program_management')"
                                            :title="t('general_title_confirm_action')"
                                            @agree="del(program)"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    href
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </confirm-modal>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </template>
            </card-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { ProgramCollection } from '@/store/modules/program-collection'
    import { TrainingProgram } from '@/core/models/training-program'
    import { Goal } from '@/core/models/goal'
    import { User } from '@/core/models/user'
    import moment from 'moment/moment'
    import CardCollection from '@/components/CardCollection.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import SortField from '@/components/SortField.vue'

    export default defineComponent({
        components: {
            CardCollection,
            ConfirmModal,
            SortField,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                meta: undefined,
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: 'name',
                workout_type: undefined,
                calendar_duration: undefined,
                goal_id: undefined,
                user_id: undefined,
                level: undefined,
                only_suspended: undefined,
                archived: undefined,
                relations: 'user,stroke',
            })

            const reset = () => {
                queries.workout_type = undefined
                queries.calendar_duration = undefined
                queries.goal_id = undefined
                queries.user_id = undefined
                queries.level = undefined
                queries.search = undefined
                queries.only_suspended = undefined
                queries.archived = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <ProgramCollection>store.getters['programCollection/get'])
            const goals = computed(
                () => [{ text: '-', value: '' }].concat(
                    store.getters['goalCollection/get'].data.map((item: Goal) => {
                        return { text: item.name, value: item.id.toString() }
                    })
                )
            )
            const users = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['userCollection/get'].data.map((item: User) => {
                    return { text: item.fullName, value: item.id }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('programCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const view = (programModel: TrainingProgram) => {
                router.push({ name: 'programs.view', params: <any>{ id: programModel.id } })
            }

            const edit = (programModel: TrainingProgram) => {
                router.push({ name: 'programs.show', params: <any>{ id: programModel.id } })
            }

            const suspend = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/update', { id: programModel.id, payload: { enabled: ! programModel.enabled, only: 'enabled' } }).then(() => {
                    store.dispatch('notify', { color: 'success', message: 'Data updated successfully!' })
                    capture()
                })
            }

            const archived = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/update', { id: programModel.id, payload: { archived: ! programModel.archived, only: 'archived' } }).then(() => {
                    capture()
                })
            }

            const del = (programModel: TrainingProgram) => {
                state.overlay = true
                store.dispatch('program/delete', { id: programModel.id, force: true }).then(() => {
                    capture()
                })
            }

            const search = () => {
                queries.page = 1
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = 'name'
                }
                queries.sort = value
            }

            const go = (route: any) => {
                router.push(route)
            }

            watch(() => [
                queries.sort,
                queries.only_suspended,
                queries.archived,
                queries.workout_type,
                queries.calendar_duration,
                queries.goal_id,
                queries.user_id,
                queries.level,
            ], () => {
                capture()
            })

            onMounted(() => {
                store.dispatch('goalCollection/get', { sort: 'sort_order' })
                store.dispatch('userCollection/get', { sort: 'first_name', coach: 1 })
                capture()
            })

            return {
                queries,
                collection: collection.value,
                goals,
                users,
                moment,
                view,
                edit,
                suspend,
                archived,
                del,
                updated,
                onSort,
                search,
                clear,
                reset,
                go,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
