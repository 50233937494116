import moment, { Moment } from 'moment/moment'
import { TrainingProgram, TrainingProgramPayload } from '@/core/models/training-program'
import { User, UserPayload } from '@/core/models/user'

export interface TrainingProgramExecutionPayload {
    id: number
    user_id: number
    training_program_id: number
    total_distance: number
    total_distance_unit: string
    total_duration: string
    total_rest_time: string
    total_time: string
    total_calories_burned: number
    total_steps: number
    progress_percentage: number
    avg_pace: string
    status: string
    started_at: string
    finished_at: string
    user?: UserPayload
    training_program?: TrainingProgramPayload
}

export class TrainingProgramExecution {

    id: number
    userId: number
    trainingProgramId: number
    totalDistance: number
    totalDistanceUnit: string
    totalDuration: string
    totalRestTime: string
    totalTime: string
    totalCaloriesBurned: number
    totalSteps: number
    progressPercentage: number
    avgPace: string
    status: string
    startedAt: Moment
    finishedAt: Moment
    user: User
    program?: TrainingProgram

    constructor(data: TrainingProgramExecutionPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.trainingProgramId = data.training_program_id
        this.totalDistance = data.total_distance
        this.totalDistanceUnit = data.total_distance_unit
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.totalCaloriesBurned = data.total_calories_burned
        this.totalSteps = data.total_steps
        this.progressPercentage = data.progress_percentage
        this.avgPace = data.avg_pace
        this.status = data.status
        this.startedAt = moment(data.started_at)
        this.finishedAt = moment(data.finished_at)
        this.user = new User(data.user || <any>{})
        this.capture(data)
    }

    fill(data: TrainingProgramExecutionPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.trainingProgramId = data.training_program_id
        this.totalDistance = data.total_distance
        this.totalDistanceUnit = data.total_distance_unit
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.totalCaloriesBurned = data.total_calories_burned
        this.totalSteps = data.total_steps
        this.progressPercentage = data.progress_percentage
        this.avgPace = data.avg_pace
        this.status = data.status
        this.startedAt = moment(data.started_at)
        this.finishedAt = moment(data.finished_at)
        this.user = new User(data.user || <any>{})
        this.capture(data)
        return this
    }

    capture(data: TrainingProgramExecutionPayload): this {
        if (data.training_program) {
            this.program = new TrainingProgram(data.training_program || <any>{})
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
