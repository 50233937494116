import { WorkoutSet, WorkoutSetPayload } from '@/core/models/workout-set'

export interface WorkoutSetExecutionPayload {
    repetition_number: number
    distance: number
    distance_unit: string
    duration: string
    rest_time: string
    total_time: string
    calories_burned: number
    max_speed: number
    average_speed: number
    max_heartrate: number
    average_heartrate: number
    set?: WorkoutSetPayload
}

export class WorkoutSetExecution {

    repetitionNumber: number
    distance: number
    distanceUnit: string
    duration: string
    restTime: string
    totalTime: string
    caloriesBurned: number
    maxSpeed: number
    averageSpeed: number
    maxHeartrate: number
    averageHeartrate: number
    set: WorkoutSet

    constructor(data: WorkoutSetExecutionPayload) {
        this.repetitionNumber = data.repetition_number
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = this.convertToShortTime(data.duration)
        this.restTime = this.convertToShortTime(data.rest_time)
        this.totalTime = this.convertToShortTime(data.total_time)
        this.caloriesBurned = data.calories_burned
        this.maxSpeed = data.max_speed
        this.averageSpeed = data.average_speed
        this.maxHeartrate = data.max_heartrate
        this.averageHeartrate = data.average_heartrate
        this.set = new WorkoutSet(data.set || <any>{})
    }

    fill(data: WorkoutSetExecutionPayload): this {
        this.repetitionNumber = data.repetition_number
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.duration = this.convertToShortTime(data.duration)
        this.restTime = this.convertToShortTime(data.rest_time)
        this.totalTime = this.convertToShortTime(data.total_time)
        this.caloriesBurned = data.calories_burned
        this.maxSpeed = data.max_speed
        this.averageSpeed = data.average_speed
        this.maxHeartrate = data.max_heartrate
        this.averageHeartrate = data.average_heartrate
        this.set = new WorkoutSet(data.set || <any>{})
        return this
    }

    _duration(): string {
        return this.convertToFullTime(this.duration)
    }

    _restTime(): string {
        return this.convertToFullTime(this.restTime)
    }

    _totalTime(): string {
        return this.convertToFullTime(this.totalTime)
    }

    convertToFullTime(time?: string): string {
        let converted = '00:00:00.000'
        if (time && time.length === 5) {
            converted = '00:' + time + '.000'
        }
        return converted
    }

    convertToShortTime(time?: string): string {
        let converted = '00:00'
        if (time && time.length === 12) {
            converted = time.slice(3, -4)
        }
        return converted
    }

    unset(): this {
        this.fill(<WorkoutSetExecutionPayload>{})
        return this
    }
}
