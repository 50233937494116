import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'

export interface ILocationCoordinates {
    latitude: number
    longitude: number
}

export interface GroupPayload {
    id: number
    user_id: number
    creator: string
    name: string
    description: string
    photo_url: string
    photos: string[]
    group_type: string
    location: string | null
    location_coordinates: ILocationCoordinates | null
    members_count: number
    hide_users: boolean
    private: boolean
    enabled: boolean
    created_at?: string
    user?: UserPayload
    members: UserPayload[]
    translations?: {}
}

export class Group {

    id: number
    userId: number
    creator: string
    name: string
    description: string
    photo?: File
    photoUrl: string
    photoUps?: File[]
    photos: string[]
    groupType: string
    location: string | null
    locationCoordinates: ILocationCoordinates | null
    membersCount: number
    hideUsers: boolean
    private: boolean
    enabled: boolean
    createdAt?: Moment
    user: User
    members: User[] = <User[]>[]
    translations?: {} = <any>{}

    constructor(data: GroupPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.creator = data.creator
        this.name = data.name
        this.description = data.description
        this.photoUrl = data.photo_url
        this.photos = data.photos
        this.groupType = data.group_type
        this.location = data.location
        this.locationCoordinates = data.location_coordinates
        this.membersCount = data.members_count
        this.hideUsers = data.hide_users
        this.private = data.private
        this.enabled = data.enabled
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
        this.user = new User(data.user || <UserPayload>{})
        this.capture(data)
    }

    fill(data: GroupPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.creator = data.creator
        this.name = data.name
        this.description = data.description
        this.photoUrl = data.photo_url
        this.photos = data.photos
        this.groupType = data.group_type
        this.location = data.location
        this.locationCoordinates = data.location_coordinates
        this.membersCount = data.members_count
        this.hideUsers = data.hide_users
        this.private = data.private
        this.enabled = data.enabled
        this.createdAt = data.created_at ? moment(data.created_at) : undefined
        this.user = new User(data.user || <UserPayload>{})
        this.capture(data)
        return this
    }

    capture(data: GroupPayload): this {
        if (data.members) {
            this.members = <User[]>[]
            data.members.map(item => this.members.push(new User(item)))
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    public get coordinatesTitle(): string {
        if (this.locationCoordinates) {
            return this.locationCoordinates.latitude + ' / ' +  this.locationCoordinates.longitude
        }
        return '-'
    }

    delPhoto(number: number): this {
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 1)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    updatePhotoNumber(currentNumber: number, number: number): this {
        const set = JSON.parse(JSON.stringify(this.photos[currentNumber]))
        this.photos.splice(currentNumber, 1)
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 0, set)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.photo = undefined
        this.photoUps = undefined
        this.members = <any>[]
        this.translations = {}
        return this
    }

    __members(): UserPayload[] {
        const members = <UserPayload[]>[]
        this.members.map(item => members.push(<UserPayload>item.payload()))
        return members
    }

    payload(): GroupPayload | any {
        return {
            id: this.id,
            user_id: this.userId,
            creator: this.creator,
            name: this.name,
            description: this.description,
            photos: this.photos,
            group_type: this.groupType,
            location: this.location,
            location_coordinates: this.locationCoordinates || null,
            hide_users: this.hideUsers,
            private: this.private,
            enabled: this.enabled,
            members: this.__members(),
            translations: this.translations,
        }
    }
}
