import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { useRankHttp } from '@/core/api/ranks'
import { Rank, RankPayload } from '@/core/models/rank'

export type RankCollection = {
    data: Array<Rank>
}

const state: RankCollection = {
    data: <any>[]
}

export const rankCollection: Module<RankCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: RankCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: RankPayload) => {
                state.data.push(new Rank(item))
            })
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useRankHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: RankCollection): RankCollection => {
            return state
        }
    }
}