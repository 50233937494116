
<div>
    <app-bar :title="t('roles_title')">
        <template #actions>
            <div v-if="permitted('*')" class="px-10">
                <v-btn
                    :to="{ name: 'roles.create' }"
                    class="sm sm-h"
                    color="orange"
                >{{ t('role_title_add') }}</v-btn>
            </div>
        </template>
    </app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-tabs
            class="px-4 my-7"
            height="52"
        >
            <div class="tabs-line"></div>
            <v-tab
                :to="{ name: 'users' }"
                class="pl-0"
            >{{ t('users_title') }}</v-tab>
            <v-tab
                :to="{ name: 'roles' }"
            >{{ t('roles_title') }}</v-tab>
            <v-tab
                :to="{ name: 'permissions' }"
            >{{ t('permissions_title') }}</v-tab>

            <v-tabs-items>
                <card-collection
                    class="px-0-nested"
                    :meta="meta"
                    :busy="overlay"
                    @updated="updated"
                >
                    <template #data>
                        <thead>
                        <tr>
                            <th class="touched">{{ t('role_label_id') }}</th>
                            <th>{{ t('role_label_name') }}</th>
                            <th>{{ t('role_label_description') }}</th>
                            <th v-if="permitted('*')" class="text-center">{{ t('general_label_action') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="collection.data.length === 0">
                            <td class="text-center" :colspan="permitted('*') ? 4 : 3">{{ t('general_text_no_data') }}</td>
                        </tr>
                        <template v-else v-for="role in collection.data">
                            <tr>
                                <td>{{ role.id }}</td>
                                <td>{{ role.name }}</td>
                                <td>{{ role.description }}</td>
                                <td v-if="permitted('*')" class="text-center">
                                    <v-menu
                                        offset-y
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click.prevent="edit(role)"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_edit') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <confirm-modal
                                                :title="t('general_title_confirm_action')"
                                                @agree="del(role)"
                                            >
                                                <template #default="{ on, attrs }">
                                                    <v-list-item
                                                        href
                                                        v-on="on"
                                                        v-bind="attrs"
                                                    >
                                                        <v-list-item-title
                                                            class="customized-deep-primary--text"
                                                        >{{ t('general_action_delete') }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </confirm-modal>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </card-collection>
            </v-tabs-items>
        </v-tabs>
    </v-layout>
</div>
