import { useHttp } from '../http'

export const useNotificationHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`notification-schedules/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`notification-schedules`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`notification-schedules/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`notification-schedules`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`notification-schedules/${id}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`notification-schedules/${id}/photo`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo,
    }
}