
<div>
    <app-bar :title="t('pages_title')"></app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-7"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}</v-btn>
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                    <tr>
                        <th class="touched">
                            <sort-field
                                v-model="queries.sort"
                                field="id"
                                @touched="onSort"
                            >{{ t('page_label_id') }}</sort-field>
                        </th>
                        <th>{{ t('page_label_name') }}</th>
                        <th>{{ t('page_label_slug') }}</th>
                        <th>{{ t('page_label_published') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="collection.data.length === 0">
                        <td class="text-center" colspan="4">{{ t('general_text_no_data') }}</td>
                    </tr>
                    <template v-else v-for="page in collection.data">
                        <tr>
                            <td>
                                <a
                                    :href="`/pages/${page.id}`"
                                    @click.prevent="show(page)"
                                    :class="{ 'orange--text': ! page.published }"
                                >
                                    {{ page.id }}
                                </a>
                            </td>
                            <td>
                                <a
                                    :href="`/pages/${page.id}`"
                                    @click.prevent="show(page)"
                                >
                                    {{ page.name }}
                                </a>
                            </td>
                            <td>
                                {{ page.slug }}
                            </td>
                            <td>
                                <v-icon
                                    :color="page.published ? 'green' : 'red'"
                                >mdi-check-circle-outline</v-icon>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </card-collection>
    </v-layout>
</div>
