export interface TranslationPayload {
    id: number
    status: number
    locale: string
    group: string
    key: string
    value: string
}

export class Translation {

    id: number
    status: number
    locale: string
    group: string
    key: string
    value: string

    constructor(data: TranslationPayload) {
        this.id = data.id
        this.status = data.status
        this.locale = data.locale
        this.group = data.group
        this.key = data.key
        this.value = data.value
    }

    fill(data: TranslationPayload): this {
        this.id = data.id
        this.status = data.status
        this.locale = data.locale
        this.group = data.group
        this.key = data.key
        this.value = data.value
        return this
    }

    forceFill(data: any): this {
        if (data.id) {
            this.id = data.id
        }
        if (data.status) {
            this.status = data.status
        }
        if (data.locale) {
            this.locale = data.locale
        }
        if (data.group) {
            this.group = data.group
        }
        if (data.key) {
            this.key = data.key
        }
        if (data.value) {
            this.value = data.value
        }
        return this
    }

    payload(): TranslationPayload {
        return {
            id: this.id,
            status: this.status,
            locale: this.locale,
            group: this.group,
            key: this.key,
            value: this.value,
        }
    }
}