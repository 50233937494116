
<div>
    <app-bar :title="program.name"/>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div v-if="permitted('admin_program_management')" class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}</v-btn>
                <v-btn
                    v-if="! program.archived"
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(program)"
                >
                    {{ program.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="customized-orange"
                    outlined
                    @click.prevent="archived(program)"
                >
                    {{ program.archived ? t('general_action_unarchived') : t('general_action_archived') }}
                </v-btn>
                <v-btn
                    class="sm"
                    color="orange"
                    outlined
                    @click.prevent="del(program)"
                >{{ t('general_action_delete') }}</v-btn>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <img
                    class=""
                    v-if="program.photoUrl"
                    :src="program.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('program_label_description') }}:</div>
                <div class="form-subtitle-description">{{ program.description }}</div>
                <div class="form-subtitle">{{ t('program_label_coach_advice') }}:</div>
                <div class="form-subtitle-description">{{ program.coachAdvice }}</div>
                <card-unit :items="items()">
                    <template #title>{{ t('program_label_id') }}: {{ program.id }}</template>
                </card-unit>
                <card-unit
                    v-if="participants.data.length > 0"
                    :items="participants.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                    <template #title>{{ t('program_label_users') }}:</template>
                    <template #actions>
                        <v-card-actions
                            class="px-0 mx-n3 mt-4"
                            v-if="participantMeta && participantMeta.total > 0"
                        >
                            <v-pagination
                                v-model="participantQueries.page"
                                :length="participantPageCount"
                                total-visible="7"
                            ></v-pagination>
                            <span class="pagination-meta">{{ t('general_pagination_meta', { from: participantMeta.from, to: participantMeta.to, total: participantMeta.total }) }}</span>
                        </v-card-actions>
                    </template>
                </card-unit>
            </div>
            <div class="float-left" style="max-width: calc(100% - 434px)">
                <v-tabs
                    v-if="program.weekSchedules.length"
                    v-model="currentTab"
                    height="52"
                >
                    <div class="tabs-line"></div>
                    <v-tabs-slider></v-tabs-slider>

                    <template v-for="(weekSchedule, index) in program.weekSchedules">
                        <v-tab :class="{ 'pl-0': index === 0 }">{{ t('program_workout_week_number_prefix') }} {{ index + 1 }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                class="pb-7"
                                v-for="(schedule, number) in weekSchedule"
                                :key="`schedule_${index}_${number}`"
                                untitled
                                :items="[
                                    { text: t('program_workout_prefix'), value: schedule.workoutOrderNumber },
                                    { text: t('workout_label_id'), value: schedule.workoutId },
                                    { text: t('workout_label_name'), value: schedule.workout.name },
                                    { text: t('workout_label_workout_types'), value: workoutTypesStrTitle(schedule.workout.workoutTypes) },
                                    { text: t('workout_label_duration'), value: schedule.workout.totalTime },
                                    { text: t('workout_label_distance'), value: schedule.workout.distance + ' m' },
                                ]"
                            >
                            </card-unit>
                        </v-tab-item>
                    </template>
                </v-tabs>
            </div>
        </div>
    </v-layout>
</div>
