import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { MetaPayload } from '@/core/utils/pagination'
import { useStrokeHttp } from '@/core/api/strokes'
import { Stroke, StrokePayload } from '@/core/models/stroke'

export type StrokeCollection = {
    data: Array<Stroke>,
    meta?: MetaPayload,
    links?: any
}

const state: StrokeCollection = {
    data: <any>[],
    meta: <any>[],
    links: <any>[],
}

export const strokeCollection: Module<StrokeCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: StrokeCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: StrokePayload) => {
                state.data.push(new Stroke(item))
            })
            state.meta = payload.meta
            state.links = payload.links
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useStrokeHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: StrokeCollection): StrokeCollection => {
            return state
        }
    }
}