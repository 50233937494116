
<v-flex>
    <div class="float-left mr-10">
        <card-unit :items="[
            { text: t('program_label_id'), value: execution.program.id },
            { text: t('program_execution_label_started_at'), value: execution.startedAt.format('DD/MM/YYYY HH:mm') },
            { text: t('program_execution_label_finished_at'), value: execution.finishedAt.format('DD/MM/YYYY HH:mm') },
            { text: t('programs_label_duration'), value: execution.program.calendarDuration + ' ' + calendarDurationUnitTitle(execution.program.calendarDurationUnit).toLowerCase() },
            { text: t('program_label_workout_types'), value: workoutTypesStrTitle(execution.program.workoutTypes) },
            { text: t('program_label_goals'), value: execution.program.goalsStrTitle },
            { text: t('program_label_level'), value: levelTitle(execution.program.level) },
            { text: t('program_label_workouts'), value: execution.program.workoutSchedules.length },
        ]">
            <template #title>{{ t('program_execution_card_title_general') }}</template>
        </card-unit>
    </div>
    <div class="float-left">
        <card-unit :items="[
            { text: t('program_label_stroke'), value: execution.program.stroke.name },
            { text: t('program_execution_total_distance'), value: execution.totalDistance / 1000 + ' km' },
            { text: t('program_execution_total_duration'), value: execution.totalTime },
            { text: t('program_execution_avg_pace'), value: execution.avgPace },
            { text: t('general_label_total_calories_burned'), value: execution.totalCaloriesBurned },
            { text: t('general_label_steps'), value: execution.totalSteps },
        ]">
            <template #title>{{ t('program_execution_card_title_technical') }}</template>
        </card-unit>
    </div>
</v-flex>
