<template>
    <v-app-bar
        color="white"
        height="84"
        elevation="0"
        class="px-6"
    >
        <v-toolbar-title v-html="title"></v-toolbar-title>

        <slot name="actions"></slot>

        <template v-if="back">
            <v-btn
                :to="back"
                plain
                icon
                absolute
                right
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

        <template v-else>
            <v-spacer></v-spacer>

            <v-btn
                v-ripple="false"
                plain
            >
                <span class="mr-4 black--text">{{ user().fullName }}</span>
                <v-img
                    :src="user().photo"
                    width="40"
                    height="40"
                    class="rounded-xl"
                ></v-img>
            </v-btn>

            <div class="pl-3 mr-n3">
                <language-switcher></language-switcher>
            </div>
        </template>

    </v-app-bar>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useAuth } from '@/plugins/auth'

    export default defineComponent({
        name: 'AppBar',

        props: {
            title: {
                type: String,
                default: 'Back Office'
            },
            back: {
                type: String,
                default: ''
            }
        },

        setup(props) {
            const state = reactive({})

            return {
                ...useAuth(),
                ...toRefs(state)
            }
        }
    })
</script>
