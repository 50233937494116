<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="section.exists() ? t('health_section_title_update') : t('health_section_title_create')"
            back="/health"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_section_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="section.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="section.name"
                                    :items="section.translations['name']"
                                    @touch="(v) => section.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_section_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="section.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="section.icon ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="photoForm.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="section.photo = undefined"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('health_section_label_sort_order') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="section.sortOrder"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('sort_order')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_action_published') }}</div>
                            <div class="horizontal-input">
                                <v-switch
                                    class="ma-0"
                                    v-model="section.published"
                                ></v-switch>
                            </div>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4">
                    <v-btn
                        :to="{ name: 'sections' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ section.exists() ? t('general_action_save') : t('health_section_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { HealthSection } from '@/core/models/health-section'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id'] || undefined

            const enums = useEnums()

            const state = reactive({
                overlay: true,
            })

            const capture = () => {
                store.dispatch('healthSection/getOrNew', { id: id, payload: { append: 'translations', published: '' } }).then(() => {
                    state.overlay = false
                })
            }

            const sectionModel = computed(() => <HealthSection>store.getters['healthSection/get'])

            const form = reactive(useForm())
            const photoForm = reactive(useForm())

            const submit = () => {
                form.usingSubmit(store.dispatch('healthSection/updateOrCreate', {
                    id: id,
                    payload: sectionModel.value.payload()
                }))
                    .then(() => {
                        const id = <any>sectionModel.value.id
                        const next = () => router.push({ name: 'sections.view', params: <any>{ id: id } })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                        if (sectionModel.value.photo) {
                            const data = new FormData()
                            data.append('photo', sectionModel.value.photo)
                            photoForm.usingSubmit(store.dispatch('healthSection/photo', { id: id, payload: data }).then(() => {
                                next()
                            }))
                        } else {
                            next()
                        }
                    })
            }

            const viewPhoto = () => {
                window.open(sectionModel.value.icon)
            }

            onMounted(() => {
                capture()
            })

            return {
                form,
                photoForm,
                capture,
                viewPhoto,
                submit,
                section: sectionModel.value,
                moment,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
