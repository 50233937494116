
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="group.exists() ? t('group_title_update') : t('group_title_create')"
        back="/groups"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('group_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="group.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="group.name"
                                :items="group.translations['name']"
                                @touch="(v) => group.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('group_label_description') }}</div>
                        <div class="horizontal-input">
                            <tinymce
                                key="description"
                                v-model="group.description"
                            ></tinymce>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="group.description"
                                :items="group.translations['description']"
                                @touch="(v) => group.translations['description'] = v"
                                editor
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('general_label_created_by') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="group.creator"
                                :items="communityCreators()"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                :error-messages="form.errors.get('creator')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('group_label_photo') }}</div>
                        <div class="horizontal-input">
                            <v-file-input
                                v-model="group.photo"
                                required
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                :append-outer-icon="group.photoUrl ? 'mdi-eye-outline' : ''"
                                dense
                                :error-messages="form.errors.get('photo')"
                                @click:append-outer="viewPhoto()"
                                @click:clear="group.photo = undefined; photoForm.errors.clear('photo')"
                            ></v-file-input>
                        </div>
                    </div>
                    <div class="form-group-horizontal form-group-horizontal-col-1">
                        <div class="horizontal-label">{{ t('group_label_location') }}</div>
                        <div class="horizontal-input">
                            <google-autocomplete
                                v-model="group.location"
                                id="google-address"
                                @change="updateAutocompleteText"
                                @placechanged="getAddressData"
                                dense
                                outlined
                                fulled
                                types="(cities)"
                                placeholder="Type city"
                                :error-messages="form.errors.get('location_coordinates')"
                                :disabled="groupTypeVirtual"
                            />
                        </div>
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="groupTypeVirtual"
                        :label="t('group_label_virtual')"
                    ></v-switch>
                    <v-switch
                        class="ma-0"
                        v-model="group.private"
                        :label="t('group_label_private')"
                    ></v-switch>
                    <div class="form-group-horizontal" v-if="group.photos && group.photos.length">
                        <v-sheet
                            v-for="(photo, i) in group.photos"
                            :key="photo"
                            color="white"
                            elevation="0"
                            height="200"
                            width="200"
                            class="float-left mr-8 mb-8 bordered position-relative"
                        >
                            <v-img
                                :lazy-src="photo"
                                :src="photo"
                                max-height="150"
                                max-width="150"
                                class="mx-auto mt-6"
                            ></v-img>
                            <confirm-modal
                                :title="t('file_manager_label_confirm_delete')"
                                @agree="group.delPhoto(i)"
                            >
                                <template #default="{ on, attrs }">
                                    <v-btn
                                        plain
                                        icon
                                        absolute
                                        top
                                        right
                                        class="mt-n5 mr-n5"
                                        v-on="on"
                                        v-bind="attrs"
                                    ><v-icon color="customized-red">mdi-close</v-icon></v-btn>
                                </template>
                            </confirm-modal>
                            <v-btn
                                v-if="i !== 0"
                                @click="group.updatePhotoNumber(i, (i - 1))"
                                plain
                                icon
                                absolute
                                bottom
                                left
                                class="mb-n5 ml-n5"
                            ><v-icon color="customized-primary">mdi-arrow-left</v-icon></v-btn>
                            <v-btn
                                v-if="i !== group.photos.length - 1"
                                @click="group.updatePhotoNumber(i, (i + 1))"
                                plain
                                icon
                                absolute
                                bottom
                                right
                                class="mb-n5 mr-n5"
                            ><v-icon color="customized-primary">mdi-arrow-right</v-icon></v-btn>
                        </v-sheet>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('group_label_photos') }}</div>
                        <div class="horizontal-input">
                            <v-file-input
                                v-model="group.photoUps"
                                required
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                dense
                                multiple
                                :placeholder="t('file_manager_label_browse_files')"
                                :error-messages="photoForm.errors.get('photos.0') || photoForm.errors.get('photos')"
                                @click:clear="group.photoUps = undefined; photoForm.errors.clear('photos.0'); photoForm.errors.clear('photos')"
                            ></v-file-input>
                        </div>
                        {{ }}
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="group.hideUsers"
                        :label="t('group_label_hide_users')"
                    ></v-switch>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('group_label_members') }}</div>
                        <div class="horizontal-input">
                            <user-search-modal
                                v-model="group"
                            >
                                <template #default="{ on, attrs }">
                                    <v-btn
                                        class="exs mt-1"
                                        color="primary"
                                        small
                                        v-on="on"
                                        v-bind="attrs"
                                    >{{ t('general_action_add_new') }}</v-btn>
                                </template>
                            </user-search-modal>
                        </div>
                    </div>
                    <v-card
                        v-if="group.members.length"
                        class="app-card app-card-bordered mt-6"
                    >
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="member in group.members"
                                    :key="`member_${member.id}`"
                                >
                                    {{ member.fullName }} | {{ member.id }}
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="detach(member)"
                                        v-ripple="false"
                                        plain
                                        color="orange darken-4"
                                        style="font-size: 12px"
                                    >{{ t('general_action_remove') }}</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4 pt-8">
                <v-btn
                    :to="{ name: 'groups' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prgroup="submit"
                >{{ group.exists() ? t('general_action_save') : t('group_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
