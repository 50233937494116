import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useFilerHttp } from '@/core/api/filer'
import { Filer, FilerPayload } from '@/core/models/filer'

const state: Filer = new Filer(<FilerPayload>{})

export const filer: Module<Filer, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Filer, payload: FilerPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { path: string }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFilerHttp()
                    .get(payload.path)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        create: <T>(context: any, payload: { path: string, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFilerHttp()
                    .create(payload.path, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        createDirectory: <T>(context: any, payload: { path: string }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFilerHttp()
                    .createDirectory(payload.path)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { path: string, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFilerHttp()
                    .update(payload.path, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        delete: <T>(context: any, payload: { path: string }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useFilerHttp()
                    .del(payload.path)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Filer): Filer => {
            return state
        }
    }
}