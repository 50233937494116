<template>
    <v-flex>
        <div class="float-left mr-10">
            <card-unit :items="items()">
                <template #title>{{ t('workout_execution_card_title_general') }}</template>
            </card-unit>

            <v-tabs
                style="max-width: 394px"
                v-if="execution.workoutSetExecutions.length"
                v-model="currentTab"
                height="52"
                class="mt-7"
            >
                <div class="tabs-line"></div>
                <v-tabs-slider></v-tabs-slider>

                <template v-for="(exec, index) in execution.workoutSetExecutions">
                    <v-tab :class="{ 'pl-0': index === 0 }">
                        {{ t('workout_set_prefix') }} {{ exec.set.number }} / {{ exec.repetitionNumber }}
                    </v-tab>
                    <v-tab-item class="pt-7">
                        <card-unit
                            untitled
                            :items="[
                                { text: t('workout_set_label_phase'), value: phaseTitle(exec.set.phase) },
                                { text: t('workout_set_label_repetition_numbers'), value: exec.set.repetitionNumbers },
                                { text: t('workout_set_label_distance'), value: exec.distance + ' m' },
                                { text: t('workout_set_label_stroke_type'), value: exec.set.stroke.name },
                                { text: t('workout_set_label_duration'), value: exec.duration },
                                { text: t('workout_set_label_intensity'), value: intensityTitle(exec.set.intensity) },
                                { text: t('workout_set_label_rest_time'), value: exec.restTime },
                                { text: t('workout_set_label_accessories'), value: accessoriesStrTitle(exec.set.accessories) },
                            ]">
                        </card-unit>
                    </v-tab-item>
                </template>
            </v-tabs>
        </div>
        <div class="float-left">
            <card-unit :items="[
                { text: t('workout_label_stroke'), value: execution.workout.stroke.name },
                { text: t('workout_label_distance'), value: execution.totalDistance / 1000 + ' km' },
                { text: t('workout_label_duration'), value: execution.totalTime },
                { text: t('workout_label_swimming_time'), value: execution.totalDuration },
                { text: t('general_label_avg_pace'), value: execution.avgPace },
                { text: t('workout_label_sets'), value: execution.workout.sets.length },
                { text: t('workout_label_rest_time'), value: execution.totalRestTime },
                { text: t('general_label_total_calories_burned'), value: execution.totalCaloriesBurned },
                { text: t('general_label_steps'), value: execution.totalSteps },
                { text: t('general_label_average_speed'), value: execution.averageSpeed },
                { text: t('general_label_max_speed'), value: execution.maxSpeed },
                { text: t('general_label_average_heartrate'), value: execution.averageHeartrate },
                { text: t('general_label_max_heartrate'), value: execution.maxHeartrate },
            ]">
                <template #title>{{ t('workout_execution_card_title_technical') }}</template>
            </card-unit>
            <template>
                <card-unit :items="[
                    { text: t('feedback_label_id'), value: feedback.exists() ? feedback.id : '-', route: feedback.exists() ? { name: 'feedback', query: { feedback_id: feedback.id } } : undefined },
                    { text: t('feedback_label_workout_id'), value: feedback.exists() ? feedback.workoutId : '-' },
                    { text: t('feedback_label_creation_date'), value: feedback.exists() ? feedback.createdAt.format('DD/MM/YYYY HH:mm') : '-' },
                    { text: t('feedback_label_feeling'), value: feedback.exists() ? feedbackFeelingTitle(feedback.feeling) : '-' },
                    { text: t('feedback_label_level'), value: feedback.exists() ? feedbackLevelTitle(feedback.level) : '-' },
                    { text: t('feedback_label_fit_in_time'), value: feedback.exists() ? feedbackFitInTimeTitle(feedback.fitInTime) : '-' },
                    { text: t('feedback_label_description'), value: feedback.exists() ? feedbackDescriptionTitle(feedback.description) : '-' },
                    { text: t('feedback_label_rating'), value: feedback.exists() ? feedback.rating : '-' },
                ]">
                    <template #title>{{ t('feedback_card_title_general') }}</template>
                </card-unit>
                <card-unit :items="[
                    { text: feedback.exists() ? feedback.comment : '-' },
                ]">
                    <template #title>{{ t('feedback_card_title_comment') }}</template>
                </card-unit>
            </template>
        </div>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useEnums } from '@/core/defaults'
    import { WorkoutExecution } from '@/core/models/workout-execution'
    import { Feedback } from '@/core/models/feedback'
    import { User } from '@/core/models/user'
    import CardUnit from '@/components/CardUnit.vue'
    import moment from 'moment/moment'

    export default defineComponent({
        name: 'WorkoutExecutionView',

        components: {
            CardUnit,
        },

        props: {
            execution: {
                type: WorkoutExecution,
                required: true
            },
            user: {
                type: User,
            }
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                currentTab: null,
                feedback: props.execution.feedback || new Feedback(<any>{})
            })

            const enums = useEnums()
            const i18n = useI18n()

            const items = () => {
                let items = <any>[
                    { text: i18n.t('user_label_id'), value: props.execution.userId },
                    { text: i18n.t('workout_label_id'), value: props.execution.workout.id },
                    { text: i18n.t('workout_execution_label_id'), value: props.execution.id },
                    { text: i18n.t('workout_execution_label_finished_at'), value: props.execution.finishedAt ? props.execution.finishedAt.format('DD/MM/YYYY HH:mm') : '-' },

                ]
                if (props.execution.users.length) {
                    items = items.concat([{
                        text: i18n.t('workout_execution_label_friends'),
                        value: props.execution.users.map((u) => u.fullName).join(' / '),
                    }])
                }
                items = items.concat([
                    { text: i18n.t('workout_label_swimming_location'), value: enums.locationTitle(props.execution.workout.swimmingLocation) },
                    { text: i18n.t('workout_label_workout_types'), value: enums.workoutTypesStrTitle(props.execution.workout.workoutTypes) },
                    { text: i18n.t('workout_label_goals'), value: props.execution.workout.goalsStrTitle },
                    { text: i18n.t('workout_label_level'), value: enums.levelTitle(props.execution.workout.level) },
                    { text: i18n.t('general_label_created_by'), value: enums.creatorTitle(props.execution.workout.creator) },
                    { text: i18n.t('workout_label_provider'), value: enums.activityProviderTitle(props.execution.workout.provider) },
                    { text: i18n.t('workout_execution_label_device'), value: props.execution.device || '-' }
                ])
                return items
            }

            return {
                moment,
                items,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>