import { useHttp } from '../http'

export const useUserStatsHttp = () => {

    const get = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`users/${id}/backstats`, payload)
    }

    return {
        get,
    }
}