
<div>
    <app-bar
        :title="t('complaint_label_id') + ': ' +  complaint.id"
    />

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pb-12 px-8">
            <div class="float-left mr-10" style="max-width: 394px">
                <card-unit :items="items()">
                    <template #title>{{ t('complaint_label_details') }}</template>
                </card-unit>
                <div class="form-subtitle">{{ t('complaint_label_message') }}:</div>
                <div class="form-subtitle-description" v-html="complaint.message"></div>
                <div class="form-subtitle">{{ t('complaint_label_message_description') }}:</div>
                <div class="form-subtitle-description" v-html="complaint.messageDescription"></div>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <template v-if="complaint.photos && complaint.photos.length">
                    <div class="form-subtitle mb-3">{{ t('complaint_label_photos') }}:</div>
                    <v-row>
                        <v-col
                            v-for="photo in complaint.photos"
                            :key="photo"
                            cols="6"
                        >
                            <v-sheet
                                color="white"
                                elevation="0"
                                height="185"
                                width="185"
                                rounded="lg"
                                class="pt-6 position-relative"
                            >
                                <v-img
                                    :lazy-src="photo"
                                    :src="photo"
                                    max-height="135"
                                    max-width="135"
                                    class="mx-auto"
                                ></v-img>
                                <v-btn
                                    plain
                                    icon
                                    absolute
                                    bottom
                                    right
                                    class="mb-n5 mr-n5"
                                    @click.prevent="viewPhoto(photo)"
                                ><v-icon color="customized-primary">mdi-eye-outline</v-icon></v-btn>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </template>
            </div>
            <div class="float-left" style="max-width: 394px">
                <div class="form-subtitle">{{ t('complaint_label_manage') }}:</div>
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-input">
                            <v-select
                                v-model="complaint.status"
                                :items="complaintStatuses()"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                style="width: 394px"
                                :error-messages="form.errors.get('status')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="complaint.description"
                                required
                                outlined
                                dense
                                style="width: 394px"
                                :error-messages="form.errors.get('description')"
                            ></v-textarea>
                        </div>
                    </div>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="primary"
                        @click.prevent="submit"
                    >{{ t('general_action_save') }}</v-btn>
                </v-form>
            </div>
        </div>

    </v-layout>
</div>
