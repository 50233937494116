import moment, { Moment } from 'moment/moment'

export interface GlossarySectionPayload {
    id: number
    name: string
    published?: boolean
    sort_order: number
    created_at: string
    definitions_count?: number
    translations?: {}
}

export class GlossarySection {

    id: number
    name: string
    published: boolean
    sortOrder: number
    createdAt: Moment
    definitionsCount: number
    translations?: {} = <any>{}

    constructor(data: GlossarySectionPayload) {
        this.id = data.id
        this.name = data.name
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.definitionsCount = data.definitions_count || 0
        this.capture(data)
    }

    fill(data: GlossarySectionPayload): this {
        this.id = data.id
        this.name = data.name
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.definitionsCount = data.definitions_count || 0
        this.capture(data)
        return this
    }

    capture(data: GlossarySectionPayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.translations = {}
    }

    payload(): GlossarySectionPayload | any {
        return {
            id: this.id,
            name: this.name,
            published: this.published,
            sort_order: this.sortOrder,
            translations: this.translations,
        }
    }
}
