
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="section.exists() ? t('badge_section_title_update') : t('badge_section_title_create')"
        back="/badges"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_section_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="section.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="section.name"
                                :items="section.translations['name']"
                                @touch="(v) => section.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_section_label_sort_order') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="section.sortOrder"
                                type="number"
                                step="1"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('sort_order')"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('general_action_published') }}</div>
                        <div class="horizontal-input">
                            <v-switch
                                class="ma-0"
                                v-model="section.published"
                            ></v-switch>
                        </div>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4">
                <v-btn
                    :to="{ name: 'badge-sections' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prevent="submit"
                >{{ section.exists() ? t('general_action_save') : t('badge_section_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
