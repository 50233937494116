import Vue, { CreateElement } from 'vue'
import VueCompositionAPI, { createApp } from '@vue/composition-api'
import { createI18n } from 'vue-i18n-composable'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import VuetifySnackbarQueue from '@/plugins/vuetify-snackbar-queue'
import Maska from 'maska'
import { useAuth } from '@/plugins/auth'
import Overlay from '@/components/Overlay.vue'
import AppBar from '@/components/AppBar.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import Filer from '@/components/Filer.vue'
import App from '@/App.vue'

import 'codemirror/lib/codemirror.css'

Vue.use(VueCompositionAPI)
Vue.use(VuetifySnackbarQueue)
Vue.use(Maska)
Vue.component('overlay', <any>Overlay)
Vue.component('app-bar', <any>AppBar)
Vue.component('language-switcher', <any>LanguageSwitcher)
Vue.component('filer', <any>Filer)

const guard = useAuth()

store.dispatch('getDefaults').finally(() => {
    guard
        .auth()
        .finally(() => {
            const i18n = createI18n({
                locale: store.getters['preferredLocale'],
                messages: {
                    [store.getters['preferredLocale']]: store.getters['translations']
                }
            })

            const app = createApp({
                i18n,
                store,
                router,
                vuetify,
                render: (h: CreateElement) => h(App),
            })

            app.mount('#app')
        })
})

guard.watchToken()