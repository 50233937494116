
<v-dialog
    v-model="touched"
    max-width="480"
    persistent
    scrollable
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
        <v-card-title class="customized-primary white--text">{{ t('user_card_title_crm') }}</v-card-title>

        <v-card-text class="pb-0">
            <v-form class="mt-6">
                <v-select
                    v-model="value.preferredLocale"
                    :items="locales()"
                    :label="t('user_label_language')"
                    required
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    :error-messages="form.errors.get('preferred_locale')"
                ></v-select>
                <v-text-field
                    v-model="value.email"
                    type="email"
                    :label="t('user_label_email')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('email')"
                ></v-text-field>
                <v-text-field
                    :value="value.emailVerifiedAt ? t('general_text_yes') : t('general_text_no')"
                    type="text"
                    :label="t('user_label_email_confirmed')"
                    required
                    outlined
                    dense
                    disabled
                    :error-messages="form.errors.get('email_verified_at')"
                ></v-text-field>
                <v-text-field
                    v-model="value.phone"
                    type="text"
                    :label="t('user_label_phone')"
                    required
                    outlined
                    dense
                    :error-messages="form.errors.get('phone')"
                ></v-text-field>
                <v-switch
                    class="ma-0"
                    v-model="value.allowNotifications"
                    :label="t('user_label_push_notifications')"
                    disabled
                ></v-switch>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="submit"
                width="45%"
                :loading="form.busy"
                :disabled="form.busy"
            >{{ t('general_action_update') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="orange"
                @click="cancel"
                width="45%"
                dark
            >{{ t('general_action_cancel') }}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
