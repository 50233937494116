export const useFilters = () => {

    const build = (args: { [key: string]: string | undefined }): string => {
        let queries = <any>[]
        Object.keys(args).forEach((key: string) => {
            if (args[key]) {
                queries.push(`${key}:${args[key]}`)
            }
        })
        return queries.join(',')
    }

    return {
        build
    }
}
