import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'
import authGuard from './core/middleware/auth-guard'
import authSignedGuard from './core/middleware/auth-signed-guard'
import permissionsGuard from './core/middleware/permissions-guard'
import Login from './pages/login.vue'
import Logout from './pages/logout.vue'
import Home from './pages/index.vue'
import Trainees from './pages/trainees/index.vue'
import TraineeShow from './pages/trainees/[id].vue'
import Workouts from './pages/workouts/index.vue'
import WorkoutShow from './pages/workouts/[id].vue'
import WorkoutView from './pages/workouts/view.vue'
import WorkoutExecutions from './pages/workout-executions/index.vue'
import Feedback from './pages/feedback/index.vue'
import Programs from './pages/programs/index.vue'
import ProgramShow from './pages/programs/[id].vue'
import ProgramView from './pages/programs/view.vue'
import ProgramExecutions from './pages/program-executions/index.vue'
import Races from './pages/races/index.vue'
import HealthSections from './pages/health/sections.vue'
import HealthSectionShow from './pages/health/section.[id].vue'
import HealthSectionView from './pages/health/section.view.vue'
import HealthArticles from './pages/health/articles.vue'
import HealthArticleShow from './pages/health/article.[id].vue'
import HealthArticleView from './pages/health/article.view.vue'
import GlossarySections from './pages/glossary/sections.vue'
import GlossarySectionShow from './pages/glossary/section.[id].vue'
import GlossarySectionView from './pages/glossary/section.view.vue'
import GlossaryDefinitions from './pages/glossary/definitions.vue'
import GlossaryDefinitionShow from './pages/glossary/definition.[id].vue'
import GlossaryDefinitionView from './pages/glossary/definition.view.vue'
import BadgeSections from './pages/badges/sections.vue'
import BadgeSectionShow from './pages/badges/section.[id].vue'
import BadgeSectionView from './pages/badges/section.view.vue'
import Badges from './pages/badges/badges.vue'
import BadgeShow from './pages/badges/badge.[id].vue'
import BadgeView from './pages/badges/badge.view.vue'
import Events from './pages/events/index.vue'
import EventShow from './pages/events/[id].vue'
import EventView from './pages/events/view.vue'
import Groups from './pages/groups/index.vue'
import GroupShow from './pages/groups/[id].vue'
import GroupView from './pages/groups/view.vue'
import Email from './pages/email/index.vue'
import Statistic from './pages/statistic/index.vue'
import UserStatistic from './pages/statistic/user-statistic.vue'
import Notifications from './pages/notifications/index.vue'
import NotificationShow from './pages/notifications/[id].vue'
import NotificationView from './pages/notifications/view.vue'
import Complaints from './pages/complaints/index.vue'
import ComplaintView from './pages/complaints/view.vue'
import Translations from './pages/translations/index.vue'
import Users from './pages/users/users.vue'
import Roles from './pages/users/roles.vue'
import RoleShow from './pages/users/role.[id].vue'
import Permissions from './pages/users/permissions.vue'
import Params from './pages/params/index.vue'
import Pages from './pages/pages/index.vue'
import PageShow from './pages/pages/[id].vue'
import PageNotFound from './pages/[...all].vue'
import PageForbidden from './pages/403.vue'

Vue.use(Router)

const routes = [
    {
        name: 'login',
        path: '/login',
        component: Login,
        beforeEnter: multiguard([authSignedGuard]),
        meta: {
            layout: 'auth'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout,
        beforeEnter: multiguard([authGuard])
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_dashboard']
        }
    },
    {
        name: 'trainees',
        path: '/trainees',
        component: Trainees,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_trainees']
        }
    },
    {
        name: 'trainees.show',
        path: '/trainees/:id',
        component: TraineeShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_trainees']
        }
    },
    {
        name: 'workouts',
        path: '/workouts',
        component: Workouts,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_workouts']
        }
    },
    {
        name: 'workout-executions',
        path: '/workouts/workout-executions',
        component: WorkoutExecutions,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_workout_executions']
        }
    },
    {
        name: 'feedback',
        path: '/workouts/feedback',
        component: Feedback,
        beforeEnter: multiguard([authGuard]),
    },
    {
        name: 'workouts.create',
        path: '/workouts/create',
        component: WorkoutShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_workouts']
        }
    },
    {
        name: 'workouts.show',
        path: '/workouts/:id',
        component: WorkoutShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_workouts']
        }
    },
    {
        name: 'workouts.view',
        path: '/workouts/:id/view',
        component: WorkoutView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_workouts']
        }
    },
    {
        name: 'programs',
        path: '/programs',
        component: Programs,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_programs']
        }
    },
    {
        name: 'program-executions',
        path: '/programs/program-executions',
        component: ProgramExecutions,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_program_executions']
        }
    },
    {
        name: 'programs.create',
        path: '/programs/create',
        component: ProgramShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_programs']
        }
    },
    {
        name: 'programs.show',
        path: '/programs/:id',
        component: ProgramShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_programs']
        }
    },
    {
        name: 'programs.view',
        path: '/programs/:id/view',
        component: ProgramView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_programs']
        }
    },
    {
        name: 'races',
        path: '/races',
        component: Races,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_races']
        }
    },
    {
        name: 'sections',
        path: '/health',
        component: HealthSections,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'sections.create',
        path: '/health/sections/create',
        component: HealthSectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'sections.show',
        path: '/health/sections/:id',
        component: HealthSectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'sections.view',
        path: '/health/sections/:id/view',
        component: HealthSectionView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'articles',
        path: '/health/articles',
        component: HealthArticles,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'articles.create',
        path: '/health/articles/create',
        component: HealthArticleShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'articles.show',
        path: '/health/articles/:id',
        component: HealthArticleShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'articles.view',
        path: '/health/articles/:id/view',
        component: HealthArticleView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_health']
        }
    },
    {
        name: 'glossary-sections',
        path: '/glossary',
        component: GlossarySections,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-sections.create',
        path: '/glossary/sections/create',
        component: GlossarySectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-sections.show',
        path: '/glossary/sections/:id',
        component: GlossarySectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-sections.view',
        path: '/glossary/sections/:id/view',
        component: GlossarySectionView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-definitions',
        path: '/glossary/definitions',
        component: GlossaryDefinitions,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-definitions.create',
        path: '/glossary/definitions/create',
        component: GlossaryDefinitionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-definitions.show',
        path: '/glossary/definitions/:id',
        component: GlossaryDefinitionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'glossary-definitions.view',
        path: '/glossary/definitions/:id/view',
        component: GlossaryDefinitionView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_glossary']
        }
    },
    {
        name: 'badge-sections',
        path: '/badges',
        component: BadgeSections,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badge-sections.create',
        path: '/badges/sections/create',
        component: BadgeSectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badge-sections.show',
        path: '/badges/sections/:id',
        component: BadgeSectionShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badge-sections.view',
        path: '/badges/sections/:id/view',
        component: BadgeSectionView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badges',
        path: '/badges/badges',
        component: Badges,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badges.create',
        path: '/badges/badges/create',
        component: BadgeShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badges.show',
        path: '/badges/badges/:id',
        component: BadgeShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'badges.view',
        path: '/badges/badges/:id/view',
        component: BadgeView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_badges']
        }
    },
    {
        name: 'events',
        path: '/events',
        component: Events,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_events']
        }
    },
    {
        name: 'events.create',
        path: '/events/create',
        component: EventShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_events']
        }
    },
    {
        name: 'events.show',
        path: '/events/:id',
        component: EventShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_events']
        }
    },
    {
        name: 'events.view',
        path: '/events/:id/view',
        component: EventView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_events']
        }
    },
    {
        name: 'groups',
        path: '/groups',
        component: Groups,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_groups']
        }
    },
    {
        name: 'groups.create',
        path: '/groups/create',
        component: GroupShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_groups']
        }
    },
    {
        name: 'groups.show',
        path: '/groups/:id',
        component: GroupShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_groups']
        }
    },
    {
        name: 'groups.view',
        path: '/groups/:id/view',
        component: GroupView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_groups']
        }
    },
    {
        name: 'email',
        path: '/email',
        component: Email,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_email']
        }
    },
    {
        name: 'statistic',
        path: '/statistic',
        component: Statistic,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_statistic']
        }
    },
    {
        name: 'user-statistic',
        path: '/statistic/user-statistic',
        component: UserStatistic,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_statistic']
        }
    },
    {
        name: 'notifications',
        path: '/notifications',
        component: Notifications,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_notifications']
        }
    },
    {
        name: 'notifications.create',
        path: '/notifications/create',
        component: NotificationShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_notifications']
        }
    },
    {
        name: 'notifications.show',
        path: '/notifications/:id',
        component: NotificationShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_notifications']
        }
    },
    {
        name: 'notifications.view',
        path: '/notifications/:id/view',
        component: NotificationView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_notifications']
        }
    },
    {
        name: 'translations',
        path: '/translations',
        component: Translations,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_translations']
        }
    },
    {
        name: 'complaints',
        path: '/complaints',
        component: Complaints,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_complaints']
        }
    },
    {
        name: 'complaints.view',
        path: '/complaints/:id/view',
        component: ComplaintView,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_complaints']
        }
    },
    {
        name: 'users',
        path: '/users',
        component: Users,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_users']
        }
    },
    {
        name: 'roles',
        path: '/users/roles',
        component: Roles,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_users']
        }
    },
    {
        name: 'roles.create',
        path: '/users/roles/create',
        component: RoleShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['*']
        }
    },
    {
        name: 'roles.show',
        path: '/users/roles/:id',
        component: RoleShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['*']
        }
    },
    {
        name: 'permissions',
        path: '/users/permissions',
        component: Permissions,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_users']
        }
    },
    {
        name: 'pages',
        path: '/pages',
        component: Pages,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_pages']
        }
    },
    {
        name: 'pages.show',
        path: '/pages/:id',
        component: PageShow,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_pages']
        }
    },
    {
        name: 'params',
        path: '/params',
        component: Params,
        beforeEnter: multiguard([authGuard]),
        meta: {
            permissions: ['admin_params']
        }
    },
    {
        name: '403',
        path: '/page-403',
        component: PageForbidden,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            layout: 'empty'
        }
    }
]

const createRouter = () => new Router({
    mode: 'history',
    routes
})

const router = createRouter()

router.beforeEach(multiguard([permissionsGuard]))

export default router