
<v-menu
    offset-y
>
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-ripple="false"
            plain
            color="orange darken-4"
            v-bind="attrs"
            v-on="on"
        >
            {{ preferredLocale.toUpperCase() }}<v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
    </template>
    <v-list dense>
        <v-list-item
            v-for="locale in locales(true)"
            v-if="locale.value !== preferredLocale"
            :key="locale.value"
            link
            color="primary"
            @click="touch(locale.value)"
        >
            <v-list-item-content>
                <v-list-item-title>
                    {{ locale.value.toUpperCase() }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</v-menu>
