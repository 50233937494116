import { useHttp } from '../http'

export const useRaceExecutionHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`race-executions`, payload)
    }

    return {
        get,
    }
}