<template>
    <v-menu
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-ripple="false"
                plain
                color="orange darken-4"
                v-bind="attrs"
                v-on="on"
            >
                {{ preferredLocale.toUpperCase() }}<v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item
                v-for="locale in locales(true)"
                v-if="locale.value !== preferredLocale"
                :key="locale.value"
                link
                color="primary"
                @click="touch(locale.value)"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        {{ locale.value.toUpperCase() }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
    import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useStore } from '@/store'
    import { useLocalizer } from '@/core/localizer'
    import { useLocales } from '@/core/defaults'
    import { useAuth } from '@/plugins/auth'

    export default defineComponent({
        name: 'LanguageSwitcher',

        /**
         * Component constructor.
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({})
            const store = useStore()
            const localizer = useLocalizer()
            const auth = useAuth()

            const preferredLocale = computed(() => <string>store.getters['preferredLocale'])

            const touch = (locale: string) => {
                localizer.set(locale)
                store.dispatch('getDefaults').finally(() => {
                    if (auth.signed()) {
                        store.dispatch('user/update', { id: auth.user().id, payload: { preferred_locale: locale } })
                            .finally(() => {
                                window.location.reload()
                            })
                    } else {
                        window.location.reload()
                    }
                })
            }

            return {
                preferredLocale,
                touch,
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>
