<template>
    <v-flex>
        <h1 class="app-title">
            {{ t('login_title') }}
        </h1>
        <v-spacer class="pa-4" />
        <v-form>
            <v-row class="mb-n8">
                <v-col>
                    <v-text-field
                        v-model="form.email"
                        type="email"
                        :label="t('login_label_email')"
                        required
                        outlined
                        dense
                        autocomplete="current-email"
                        :error-messages="form.errors.get('email')"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-n14">
                <v-col>
                    <v-text-field
                        v-model="form.password"
                        type="password"
                        :label="t('login_label_password')"
                        required
                        outlined
                        dense
                        autocomplete="current-password"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mb-n8">
                <v-col>
                    <v-checkbox
                        v-model="form.remember_me"
                        :label="t('login_label_remember_me')"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        :disabled="form.busy"
                        :loading="form.busy"
                        color="primary"
                        class="sm mr-4"
                        large
                        @click.prevent="submit()"
                    >
                        {{ t('login_button_sign_in') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useAuthService } from '@/core/api/auth'
    import Form, { useForm } from '@/core/form'

    type LoginForm = {
        email: string,
        password: string,
        remember_me: undefined,
    }

    export default defineComponent({
        /**
         * Component constructor.
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const authService = useAuthService()
            const i18n = useI18n()

            const form = reactive(useForm({
                email: '',
                password: '',
                remember_me: undefined,
            }))

            const submit = () => {
                form.usingSubmit(authService.login(form.data()))
                    .then(() => {
                        router.push({ name: 'home' })
                        store.dispatch('notify', { color: 'success', message: i18n.t('login_message_welcome') })
                    })
            }

            return {
                form: <LoginForm | Form>form,
                submit,
                ...useI18n(),
            }

        }
    })
</script>
