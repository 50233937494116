import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useUserHttp } from '@/core/api/users'
import { User, UserPayload } from '@/core/models/user'

const state: User = new User(<UserPayload>{})

export const user: Module<User, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: User, payload: UserPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useUserHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { id: number, payload: UserPayload }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useUserHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        delete: <T>(context: any, payload: { id: number }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useUserHttp()
                    .del(payload.id)
                    .then((data: any) => {
                        context.commit('update', new User(<any>{}))
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        resendVerification: <T>(context: any, payload: { id: number }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useUserHttp()
                    .resendVerification(payload.id)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: User): User => {
            return state
        }
    }
}