import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'

export interface ComplaintPayload {
    id: number
    user_id: number
    first_name: string
    last_name: string
    email: string
    message: string
    message_description: string
    photos: string[]
    description: string
    status: string
    created_at: string
    user?: UserPayload
}

export class Complaint {

    id: number
    userId: number
    firstName: string
    lastName: string
    email: string
    message: string
    messageDescription: string
    photoUps?: File[]
    photos: string[]
    description: string
    status: string
    createdAt: Moment
    user: User

    constructor(data: ComplaintPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.email = data.email
        this.message = data.message
        this.messageDescription = data.message_description
        this.photos = data.photos
        this.description = data.description
        this.status = data.status
        this.createdAt =  moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
    }

    fill(data: ComplaintPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.firstName = data.first_name
        this.lastName = data.last_name
        this.email = data.email
        this.message = data.message
        this.messageDescription = data.message_description
        this.photos = data.photos
        this.description = data.description
        this.status = data.status
        this.createdAt =  moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
        return this
    }

    delPhoto(number: number): this {
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 1)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    updatePhotoNumber(currentNumber: number, number: number): this {
        const set = JSON.parse(JSON.stringify(this.photos[currentNumber]))
        this.photos.splice(currentNumber, 1)
        const photos = this.photos.map(i => JSON.parse(JSON.stringify(i)))
        photos.splice(number, 0, set)
        this.photos = []
        photos.map(i => this.photos.push(i))
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.photoUps = undefined
        return this
    }

    payload(): ComplaintPayload | any {
        return {
            id: this.id,
            user_id: this.userId,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            message: this.message,
            message_description: this.messageDescription,
            photos: this.photos,
            description: this.description,
            status: this.status,
        }
    }
}
