
<v-flex>
    <card-collection
        :class="{ 'px-0-nested': !! user }"
        :meta="meta"
        :busy="overlay"
        @updated="updated"
    >
        <template #filters>
            <v-toolbar
                elevation="0"
                color="transparent"
                class="mt-7 mb-n3"
                height="56"
            >
                <v-text-field
                    v-model="queries.search"
                    @click:clear="clear"
                    class="input-search md mr-2"
                    clearable
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    :label="t('general_label_search')"
                ></v-text-field>

                <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                <date-picker-modal
                    :value="queries.date_from"
                    @updated="(v) => queries.date_from = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.date_from"
                                class="xs input-color-inherit"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_from')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <span class="sub-label px-2">-</span>

                <date-picker-modal
                    :value="queries.date_to"
                    @updated="(v) => queries.date_to = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.date_to"
                                class="xs input-color-inherit"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_to')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <v-spacer></v-spacer>

                <v-select
                    v-model="filters.swimming_location"
                    :items="[ { text: '-', value: '' } ].concat(locations())"
                    class="md mx-2"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('race_execution_label_swimming_location')"
                ></v-select>

                <v-spacer></v-spacer>

                <v-select
                    v-model="filters.stroke_id"
                    :items="strokes"
                    class="md mr-2"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('race_execution_label_stroke')"
                ></v-select>

                <v-btn
                    @click="search"
                    class="sm mx-5"
                    color="primary"
                >{{ t('general_action_search') }}
                </v-btn>

                <v-btn
                    v-ripple="false"
                    plain
                    color="orange darken-4"
                    @click="reset"
                >{{ t('general_action_reset_filters') }}
                </v-btn>

            </v-toolbar>
        </template>

        <template #data>
            <thead>
            <tr>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="id"
                        @touched="onSort"
                    >{{ t('race_execution_label_id') }}</sort-field>
                </th>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="name"
                        @touched="onSort"
                    >{{ t('race_execution_label_name') }}</sort-field>
                </th>
                <th>{{ t('race_execution_label_swimming_location') }}</th>
                <th>{{ t('race_execution_label_stroke') }}</th>
                <th>
                    <sort-field
                        v-model="queries.sort"
                        field="distance"
                        @touched="onSort"
                    >{{ t('race_execution_label_distance') }}</sort-field>
                </th>
                <th>
                    <sort-field
                        v-model="queries.sort"
                        field="duration"
                        @touched="onSort"
                    >{{ t('race_execution_label_duration') }}</sort-field>
                </th>
                <th>{{ t('race_execution_label_avg_pace') }}</th>
                <th>
                    <sort-field
                        v-model="queries.sort"
                        field="raced_at"
                        @touched="onSort"
                    >{{ t('race_execution_label_raced_at') }}</sort-field>
                </th>
                <th v-if="! user">{{ t('race_execution_label_user_name') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="collection.data.length === 0">
                <td class="text-center" colspan="9">{{ t('general_text_no_data') }}</td>
            </tr>
            <template v-else v-for="execution in collection.data">
                <tr>
                    <td>{{ execution.id }}</td>
                    <td>{{ execution.name }}</td>
                    <td>{{ locationTitle(execution.swimmingLocation) }}</td>
                    <td>{{ execution.stroke.name }}</td>
                    <td>{{ execution.distance }} m</td>
                    <td>{{ time.format(execution.duration) }}</td>
                    <td>{{ time.format(execution.avgPace) }}</td>
                    <td>{{ execution.racedAt.format('DD/MM/YYYY HH:mm') }}</td>
                    <td v-if="! user" class="touched">
                        <a
                            href="javascript:"
                            @click.prevent="viewUser(execution.user)"
                        >{{ execution.user.fullName }}</a>
                    </td>
                </tr>
            </template>
            </tbody>
        </template>
    </card-collection>
</v-flex>
