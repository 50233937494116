import moment, { Moment } from 'moment/moment'

export interface HealthSectionPayload {
    id: number
    name: string
    icon: string
    published?: boolean
    sort_order: number
    created_at: string
    articles_count?: number
    translations?: {}
}

export class HealthSection {

    id: number
    name: string
    icon: string
    photo?: File
    published: boolean
    sortOrder: number
    createdAt: Moment
    articlesCount: number
    translations?: {} = <any>{}

    constructor(data: HealthSectionPayload) {
        this.id = data.id
        this.name = data.name
        this.icon = data.icon
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.articlesCount = data.articles_count || 0
        this.capture(data)
    }

    fill(data: HealthSectionPayload): this {
        this.id = data.id
        this.name = data.name
        this.icon = data.icon
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.articlesCount = data.articles_count || 0
        this.capture(data)
        return this
    }

    capture(data: HealthSectionPayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.photo = undefined
        this.translations = {}
    }

    payload(): HealthSectionPayload | any {
        return {
            id: this.id,
            name: this.name,
            published: this.published,
            sort_order: this.sortOrder,
            translations: this.translations,
        }
    }
}
