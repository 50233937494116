import { useHttp } from '../http'

export const useBadgeHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`badges/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`badges`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`badges/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`badges`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`badges/${id}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`badges/${id}/photo`, payload)
    }

    const process = <T>(): Promise<T> => {
        return useHttp().post(`badges/process`)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo,
        process
    }
}