import { useHttp } from '../http'

export const useTranslationHttp = () => {

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`translations/${id}`, payload)
    }

    const updateOrCreate = <T>(payload: any): Promise<T> => {
        return useHttp().patch(`translations`, payload)
    }

    return {
        update,
        updateOrCreate
    }
}