import * as time from '@/core/utils/time-helper'
import * as distance from '@/core/utils/distance-helper'

export interface UserStatPayload {
    programs_total: number
    programs_quit_total: number
    programs_avg_completion_percentage: number
    workouts_total: number
    workouts_openswim_total: number
    workouts_customized_total: number
    workouts_reported_total: number
    workouts_distance_total: number
    workouts_duration_total: string
    groups_member: number
    groups_created: number
    events_involved: number
    events_future: number
    events_past: number
    events_created: number
}

export class UserStat {

    programsTotal: number
    programsQuitTotal: number
    programsAvgCompletionPercentage: number
    workoutsTotal: number
    workoutsOpenswimTotal: number
    workoutsCustomizedTotal: number
    workoutsReportedTotal: number
    workoutsDistanceTotal: number
    workoutsDurationTotal: string
    groupsMember: number
    groupsCreated: number
    eventsInvolved: number
    eventsFuture: number
    eventsPast: number
    eventsCreated: number

    constructor(data: UserStatPayload) {
        this.programsTotal = data.programs_total
        this.programsQuitTotal = data.programs_quit_total
        this.programsAvgCompletionPercentage = data.programs_avg_completion_percentage
        this.workoutsTotal = data.workouts_total
        this.workoutsOpenswimTotal = data.workouts_openswim_total
        this.workoutsCustomizedTotal = data.workouts_customized_total
        this.workoutsReportedTotal = data.workouts_reported_total
        this.workoutsDistanceTotal = data.workouts_distance_total
        this.workoutsDurationTotal = data.workouts_duration_total
        this.groupsMember = data.groups_member
        this.groupsCreated = data.groups_created
        this.eventsInvolved = data.events_involved
        this.eventsFuture = data.events_future
        this.eventsPast = data.events_past
        this.eventsCreated = data.events_created
    }

    fill(data: UserStatPayload): this {
        this.programsTotal = data.programs_total
        this.programsQuitTotal = data.programs_quit_total
        this.programsAvgCompletionPercentage = data.programs_avg_completion_percentage
        this.workoutsTotal = data.workouts_total
        this.workoutsOpenswimTotal = data.workouts_openswim_total
        this.workoutsCustomizedTotal = data.workouts_customized_total
        this.workoutsReportedTotal = data.workouts_reported_total
        this.workoutsDistanceTotal = data.workouts_distance_total
        this.workoutsDurationTotal = data.workouts_duration_total
        this.groupsMember = data.groups_member
        this.groupsCreated = data.groups_created
        this.eventsInvolved = data.events_involved
        this.eventsFuture = data.events_future
        this.eventsPast = data.events_past
        this.eventsCreated = data.events_created
        return this
    }

    _workoutsDistanceTotal(): string {
        return distance.format(this.workoutsDistanceTotal)
    }

    _workoutsDurationTotal(): string {
        return time.format(this.workoutsDurationTotal, true)
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
