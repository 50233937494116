import { useHttp } from '../http'

export const useParamHttp = () => {

    const find = <T>(id: number): Promise<T> => {
        return useHttp().get(`params/${id}`)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`params/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`params`, payload)
    }

    return {
        find,
        update,
        get,
    }
}