import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useWorkoutExecutionHttp } from '@/core/api/workout-executions'
import { WorkoutExecution, WorkoutExecutionPayload } from '@/core/models/workout-execution'

const state: WorkoutExecution = new WorkoutExecution(<WorkoutExecutionPayload>{})

export const workoutExecution: Module<WorkoutExecution, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: WorkoutExecution, payload: WorkoutExecutionPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        getOrNew: <T>(context: any, payload: { id: number | undefined, payload: any }): Promise<T> => {
            state.unset()
            if (payload.id) {
                return context.dispatch('get', payload)
            } else {
                return new Promise<T>((resolve) => {
                    resolve({} as T)
                })
            }
        },
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useWorkoutExecutionHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        delete: <T>(context: any, payload: { id: number, force: boolean }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useWorkoutExecutionHttp()
                    .del(payload.id)
                    .then((data: any) => {
                        context.commit('update', new WorkoutExecution(<WorkoutExecutionPayload>{}))
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: WorkoutExecution): WorkoutExecution => {
            return state
        }
    }
}