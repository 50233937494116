import { useHttp } from '../http'

export const useFilerHttp = () => {

    const create = <T>(path: string, payload: any): Promise<T> => {
        return useHttp().post(`filer?path=${path}`, payload)
    }

    const createDirectory = <T>(path: string): Promise<T> => {
        return useHttp().patch(`filer?path=${path}`)
    }

    const update = <T>(path: string, payload: any): Promise<T> => {
        return useHttp().put(`filer?path=${path}`, payload)
    }

    const del = <T>(path: string): Promise<T> => {
        return useHttp().del(`filer?path=${path}`)
    }

    const get = <T>(path: string): Promise<T> => {
        return useHttp().get(`filer?path=${path}`)
    }

    return {
        create,
        createDirectory,
        update,
        del,
        get,
    }
}