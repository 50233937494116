<template>
    <div>
        <app-bar :title="section.name"/>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}
                    </v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(section)"
                    >
                        {{ section.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <confirm-modal
                        :title="t('general_title_confirm_action')"
                        @agree="del(section)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm"
                                color="orange"
                                outlined
                                v-on="on"
                                v-bind="attrs"
                            >{{ t('general_action_delete') }}</v-btn>
                        </template>
                    </confirm-modal>
                </div>
                <div class="float-left mr-10" style="width: 394px; max-width: 394px">
                    <img
                        v-if="section.icon"
                        :src="section.icon"
                        width="394"
                    />
                </div>
                <div class="float-left" style="max-width: calc(100% - 434px)">
                    <card-unit :items="[
                        { text: t('health_section_label_name'), value: section.name },
                        { text: t('general_label_creation_date'), value: section.createdAt.format('DD/MM/YYYY') },
                        { text: t('health_section_label_published'), value: section.published ? t('general_text_yes') : t('general_text_no') },
                        { text: t('health_section_label_sort_order'), value: section.sortOrder },
                        { text: t('health_section_label_articles'), value: section.articlesCount },
                    ]">
                        <template #title>{{ t('health_section_label_id') }}: {{ section.id }}</template>
                    </card-unit>
                </div>
            </div>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { HealthSection } from '@/core/models/health-section'
    import moment from 'moment/moment'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            ConfirmModal,
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
            })

            const capture = () => {
                store.dispatch('healthSection/get', { id: id, payload: { append: 'articles_count', published: '' } }).then(() => {
                    state.overlay = false
                })
            }

            const sectionModel = computed(() => <HealthSection>store.getters['healthSection/get'])

            const suspend = (sectionModel: HealthSection) => {
                state.overlay = true
                store.dispatch('healthSection/update', {
                    id: sectionModel.id,
                    payload: { published: ! sectionModel.published }
                }).then(() => {
                    store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    capture()
                })
            }

            const edit = () => router.push({ name: 'sections.show', params: { id: <any>sectionModel.value.id } })

            const del = (sectionModel: HealthSection) => {
                state.overlay = true
                store.dispatch('healthSection/delete', { id: sectionModel.id }).then(() => {
                    router.push({ name: 'sections' })
                })
            }

            onMounted(() => {
                capture()
            })

            return {
                capture,
                section: sectionModel.value,
                moment,
                edit,
                del,
                suspend,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
