import moment, { Moment } from 'moment/moment'
import { BadgeSection, BadgeSectionPayload } from '@/core/models/badge-section'
import { Event, EventPayload } from '@/core/models/event'

export interface BadgePayload {
    id: number
    badge_section_id: number
    name: string
    description: string
    motto: string
    photo_url: string
    trigger: string
    stat: string
    published?: boolean
    sort_order: number
    created_at: string
    section?: BadgeSectionPayload
    events?: EventPayload[]
    translations?: {}
}

export class Badge {

    id: number
    badgeSectionId: number
    name: string
    description: string
    motto: string
    photoUrl: string
    photo?: File
    trigger: string
    stat: string
    published: boolean
    sortOrder: number
    createdAt: Moment
    section: BadgeSection
    events: Event[] = <Event[]>[]
    translations?: {} = <any>{}

    constructor(data: BadgePayload) {
        this.id = data.id
        this.badgeSectionId = data.badge_section_id
        this.name = data.name
        this.description = data.description
        this.motto = data.motto
        this.photoUrl = data.photo_url
        this.trigger = data.trigger
        this.stat = data.stat
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.section = new BadgeSection(data.section || <any>{})
        this.capture(data)
    }

    fill(data: BadgePayload): this {
        this.id = data.id
        this.badgeSectionId = data.badge_section_id
        this.name = data.name
        this.description = data.description
        this.motto = data.motto
        this.photoUrl = data.photo_url
        this.trigger = data.trigger
        this.stat = data.stat
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.section = new BadgeSection(data.section || <any>{})
        this.capture(data)
        return this
    }

    capture(data: BadgePayload): this {
        if (data.events) {
            this.events = <Event[]>[]
            data.events.map((item) => this.events.push(new Event(item)))
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.photo = undefined
        this.events = <Event[]>[]
        this.translations = {}
    }

    payload(): BadgePayload | any {
        return {
            id: this.id,
            badge_section_id: this.badgeSectionId,
            name: this.name,
            description: this.description,
            motto: this.motto,
            trigger: this.trigger,
            stat: this.stat,
            published: this.published,
            sort_order: this.sortOrder,
            translations: this.translations,
        }
    }
}
