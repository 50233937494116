import { useHttp } from '../http'

export const useTrainingProgramHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`training-programs/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`training-programs`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`training-programs/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`training-programs`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`training-programs/${id}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`training-programs/${id}/photo`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo
    }
}