import { useHttp } from '../http'

export const useWorkoutHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`workouts/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`workouts`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`workouts/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`workouts`, payload)
    }

    const del = <T>(id: number, force: boolean = false): Promise<T> => {
        return useHttp().del(`workouts/${id}${force ? '?force=1' : ''}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`workouts/${id}/photo`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo
    }
}