import { useHttp } from '@/core/http'
import { useAuth } from '@/plugins/auth'
import { useToken } from '@/core/utils/token'

export const useAuthService = () => {

    const tokenHelper = useToken()

    const login = <T>(payload: any): Promise<T> => {
        return new Promise<T>((resolve, reject) => {
            useHttp()
                .post('/auth/login?full_access', payload)
                .then((data: any) => {
                    tokenHelper.setToken(data)
                    useAuth().auth()
                        .then(() => {
                            resolve(data as T)
                        })
                        .catch((response) => {
                            tokenHelper.clearToken()
                            reject(response as T)
                        })
                })
                .catch((response: any) => {
                    tokenHelper.clearToken()
                    reject(response as T)
                })
        })
    }

    const logout = (): boolean => {
        return tokenHelper.clearToken()
    }

    const getUser = <T>(): Promise<T> => {
        return new Promise<T>((resolve, reject) => {
            useHttp()
                .get('/auth/user')
                .then((data: any) => {
                    resolve(data as T)
                })
                .catch((response: any) => {
                    tokenHelper.clearToken()
                    reject(response as T)
                })
        })
    }

    return {
        login,
        getUser,
        logout
    }
}