import { useHttp } from '../http'

export const useRankHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`ranks`, payload)
    }

    const _export = <T>(payload: any): Promise<T> => {
        return useHttp().get(`ranks/export`, payload, { responseType: 'blob' })
    }

    return {
        get,
        _export,
    }
}