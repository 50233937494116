<template>
    <div
        class="touched"
        :class="{ 'primary--text': isTouched() }"
        @click="touch"
    >
        <slot></slot>
        <template v-if="touched === field">
            <v-icon size="12">mdi-chevron-down</v-icon>
        </template>
        <template v-else-if="touched === '-' + field">
            <v-icon size="12">mdi-chevron-up</v-icon>
        </template>
        <template v-else>
            <v-icon size="12">mdi-sort</v-icon>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api'

    export default defineComponent({
        name: 'SortField',

        /**
         * Component props.
         */
        props: {
            field: {
                type: String,
                required: true
            },
            value: String
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                touched: <string | undefined>props.value
            })

            const touch = () => {
                switch (state.touched) {
                    case undefined:
                        state.touched = props.field
                        break
                    case props.field:
                        state.touched = '-' + props.field
                        break
                    case '-' + props.field:
                        state.touched = undefined
                        break
                    default:
                        state.touched = props.field
                }
                context.emit('touched', state.touched)
            }

            const isTouched = (): boolean => {
                return state.touched === props.field || state.touched === '-' + props.field
            }

            watch(() => props.value, (newValue) => {
                state.touched = newValue
            });

            return {
                touch,
                isTouched,
                ...toRefs(state)
            }
        }
    })
</script>
