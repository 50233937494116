import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useParamHttp } from '@/core/api/params'
import { Param, ParamPayload } from '@/core/models/param'

const state: Param = new Param(<ParamPayload>{})

export const param: Module<Param, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Param, payload: ParamPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { id: number }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useParamHttp()
                    .find(payload.id)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { id: number, payload: ParamPayload }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useParamHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Param): Param => {
            return state
        }
    }
}