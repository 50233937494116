import { useHttp } from '../http'

export const useGroupHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`groups/${id}`, payload)
    }

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`groups`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`groups/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`groups`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`groups/${id}`)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`groups/${id}/photo`, payload)
    }

    return {
        find,
        create,
        update,
        get,
        del,
        photo,
    }
}