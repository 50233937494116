import { filter, IPayload } from '@/core/utils/payload-helper'
import { Stroke, StrokePayload } from '@/core/models/stroke'
import { WorkoutSchedule, WorkoutSchedulePayload } from '@/core/models/workout-schedule'
import { Goal, GoalPayload } from '@/core/models/goal'
import { Workout } from '@/core/models/workout'
import { TrainingProgramExecution, TrainingProgramExecutionPayload } from '@/core/models/training-program-execution'
import { User, UserPayload } from '@/core/models/user'

export interface TrainingProgramPayload {
    id: number
    user_id: number
    stroke_id: number
    next_program_id: number
    creator: string
    name: string
    description: string
    coach_advice: string
    photo_url: string
    level: string
    calendar_duration: number
    calendar_duration_unit: string
    sport_type: string
    workout_types: string[]
    workout_count: number
    enabled: boolean
    archived: boolean
    user?: UserPayload
    stroke?: StrokePayload
    training_program_goals?: GoalPayload[]
    workout_schedules?: WorkoutSchedulePayload[]
    current_user_execution?: TrainingProgramExecutionPayload
    translations?: {}
}

export class TrainingProgram {

    id: number
    userId: number
    strokeId: number
    nextProgramId: number
    creator: string
    name: string
    description: string
    coachAdvice: string
    photo?: File
    photoUrl: string
    level: string
    calendarDuration: number
    calendarDurationUnit: string
    sportType: string
    workoutTypes: string[]
    workoutCount: number
    enabled: boolean
    archived: boolean
    user: User
    stroke: Stroke
    goals: Goal[] = <Goal[]>[]
    goalIds: number[] = []
    workoutSchedules: WorkoutSchedule[] = <WorkoutSchedule[]>[]
    weekSchedules: WorkoutSchedule[][] = []
    currentUserExecution?: TrainingProgramExecution
    translations?: {} = <any>{}

    constructor(data: TrainingProgramPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.nextProgramId = data.next_program_id
        this.creator = data.creator || 'openswim'
        this.name = data.name
        this.description = data.description
        this.coachAdvice = data.coach_advice
        this.photoUrl = data.photo_url
        this.level = data.level
        this.calendarDuration = data.calendar_duration
        this.calendarDurationUnit = data.calendar_duration_unit
        this.sportType = data.sport_type
        this.workoutTypes = data.workout_types
        this.workoutCount = data.workout_count
        this.enabled = data.enabled
        this.archived = data.archived
        this.user = new User(data.user || <any>{})
        this.stroke = new Stroke(data.stroke || <any>{})
        this.capture(data)
    }

    fill(data: TrainingProgramPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.nextProgramId = data.next_program_id
        this.creator = data.creator || 'openswim'
        this.name = data.name
        this.description = data.description
        this.coachAdvice = data.coach_advice
        this.photoUrl = data.photo_url
        this.level = data.level
        this.calendarDuration = data.calendar_duration
        this.calendarDurationUnit = data.calendar_duration_unit
        this.sportType = data.sport_type
        this.workoutTypes = data.workout_types
        this.workoutCount = data.workout_count
        this.enabled = data.enabled
        this.archived = data.archived
        this.user = new User(data.user || <any>{})
        this.stroke = new Stroke(data.stroke || <any>{})
        this.capture(data)
        return this
    }

    capture(data: TrainingProgramPayload): this {
        if (data.workout_schedules) {
            this.workoutSchedules = <WorkoutSchedule[]>[]
            data.workout_schedules.map(item => this.workoutSchedules.push(new WorkoutSchedule(item)))
        }
        if (data.training_program_goals) {
            this.goals = <Goal[]>[]
            this.goalIds = <number[]>[]
            data.training_program_goals.map(item => {
                this.goals.push(new Goal(item))
                if (item.enabled) {
                    this.goalIds.push(item.id)
                }
            })
        }
        this.weekSchedules = []
        for (let i = 0; i < this.calendarDuration; i++) {
            this.weekSchedules[i] = this.weekSchedules[i] || []
            this.workoutSchedules.map(item => {
                if (item.weekNumber === i + 1) {
                    this.weekSchedules[i].push(item)
                }
            })
        }
        if (data.translations) {
            this.translations = data.translations
        }

        if (data.current_user_execution) {
            this.currentUserExecution = new TrainingProgramExecution(data.current_user_execution)
        }
        return this
    }

    addWeek(): this {
        this.weekSchedules.push([])
        return this
    }

    delWeek(week: number): this {
        this.weekSchedules.splice(week, 1)
        return this
    }

    moveWeek(currentWeek: number, week: number): this {
        let schedules = this.weekSchedules[currentWeek]
        this.delWeek(currentWeek)
        this.weekSchedules.splice(week, 0, schedules)
        return this
    }

    duplicateWeek(week: number): this {
        const schedules = this.weekSchedules[week].map((item: WorkoutSchedule | any) => {
            item.id = undefined
            item.weekNumber = week
            return item
        })
        this.weekSchedules.push(schedules)
        return this
    }

    addWorkouts(week: number, workouts: Workout[] = []): this {
        if (workouts) {
            workouts.map(item => {
                this.weekSchedules[week].push(<any>{
                    id: undefined,
                    workoutId: item.id,
                    workoutOrderNumber: this.weekSchedules[week].length + 1,
                    weekNumber: week + 1,
                    workout: item
                })
            })
        }
        return this
    }

    delWorkout(week: number, number: number): this {
        this.weekSchedules[week].splice(number, 1)
        this.weekSchedules.push([])
        this.weekSchedules.pop()
        return this
    }

    moveWorkout(week: number, currentNumber: number, number: number): this {
        let workout = this.weekSchedules[week][currentNumber]
        this.delWorkout(week, currentNumber)
        this.weekSchedules[week].splice(number, 0, workout)
        return this
    }

    public get goalsStrTitle(): string {
        let titles = <any>[]
        this.goals.map(item => {
            if (item.enabled) {
                titles.push(item.name)
            }
        })
        return titles.join(' / ')
    }

    schedulesPayload(): WorkoutSchedulePayload[] {
        const items = <WorkoutSchedulePayload[]>[]
        this.weekSchedules.map((item, i) => {
            item.map(sch => {
                items.push(<any>{
                    id: sch.id,
                    workout_id: sch.workoutId,
                    week_number: i + 1,
                })
            })
        })
        return items
    }

    goalsPayload(): GoalPayload[] {
        const goals = <GoalPayload[]>[]
        if (this.goalIds.length) {
            this.goalIds.map(id => {
                goals.push(<GoalPayload>{
                    id: id,
                    name: '',
                    sort_order: 0,
                    enabled: true
                })
            })
        } else {
            this.goals.map(item => {
                if (item.enabled) {
                    goals.push(item.payload())
                }
            })
        }
        return goals
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<TrainingProgramPayload>{})
        this.photo = undefined
        this.workoutSchedules = <WorkoutSchedule[]>[]
        this.goals = <Goal[]>[]
        this.goalIds = <number[]>[]
        this.weekSchedules = []
        this.translations = {}
        return this
    }

    payload(fillable: string[] = []): TrainingProgramPayload | IPayload {
        let payload = {
            id: this.id,
            user_id: this.userId,
            stroke_id: this.strokeId,
            next_program_id: this.nextProgramId,
            creator: this.creator,
            name: this.name,
            description: this.description,
            coach_advice: this.coachAdvice,
            level: this.level,
            calendar_duration: this.weekSchedules.filter(item => item.length).length,
            calendar_duration_unit: this.calendarDurationUnit,
            sport_type: this.sportType,
            workout_types: this.workoutTypes,
            workout_count: this.workoutCount,
            enabled: this.enabled,
            archived: this.archived,
            workout_schedules: this.schedulesPayload(),
            training_program_goals: this.goalsPayload(),
            translations: this.translations,
        }
        return filter(payload, fillable)
    }
}
