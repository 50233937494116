import { useHttp } from '../http'

export const useComplaintHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`complaints/${id}`, payload)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`complaints/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`complaints`, payload)
    }

    const photo = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().post(`complaints/${id}/photo`, payload)
    }

    const _export = <T>(payload: any): Promise<T> => {
        return useHttp().get(`complaints/export`, payload, { responseType: 'blob' })
    }

    return {
        find,
        update,
        get,
        photo,
        _export
    }
}