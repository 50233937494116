import { Stroke, StrokePayload } from '@/core/models/stroke'
import { GlossaryDefinition, GlossaryDefinitionPayload } from '@/core/models/glossary-definition'
import moment from "moment";

export interface WorkoutSetPayload {
    id: number
    stroke_id: number
    number: number
    intensity: string
    phase: string
    interval: string
    distance: number
    distance_unit: string
    duration: string
    rest_time: string
    total_time: string
    calories_burned: number
    steps: number
    repetition_numbers: number
    accessories: string[]
    description: string
    stroke?: StrokePayload
    definitions?: GlossaryDefinitionPayload[]
    translations?: {}
}

export class WorkoutSet {

    id: number
    strokeId: number
    number: number
    intensity: string
    phase: string
    interval: string
    distance: number
    distanceUnit: string
    duration: string
    restTime: string
    totalTime: string
    caloriesBurned: number
    steps: number
    repetitionNumbers: number
    accessories: string[]
    description: string
    stroke: Stroke
    definitions: GlossaryDefinition[] = <GlossaryDefinition[]>[]
    definitionIds: number[] = []
    translations?: {} = <any>{}

    constructor(data: WorkoutSetPayload) {
        this.id = data.id
        this.strokeId = data.stroke_id
        this.number = data.number
        this.intensity = data.intensity
        this.phase = data.phase
        this.interval = data.interval || 'classic'
        this.distance = data.distance || 25
        this.distanceUnit = data.distance_unit
        this.duration = this.convertToShortTime(data.duration)
        this.restTime = this.convertToShortTime(data.rest_time)
        this.totalTime = this.convertToShortTime(data.total_time)
        this.caloriesBurned = data.calories_burned
        this.steps = data.steps
        this.repetitionNumbers = data.repetition_numbers || 1
        this.accessories = data.accessories || []
        this.description = data.description
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.capture(data)
    }

    fill(data: WorkoutSetPayload): this {
        this.id = data.id
        this.strokeId = data.stroke_id
        this.number = data.number
        this.intensity = data.intensity
        this.phase = data.phase
        this.interval = data.interval || 'classic'
        this.distance = data.distance || 25
        this.distanceUnit = data.distance_unit
        this.duration = this.convertToShortTime(data.duration)
        this.restTime = this.convertToShortTime(data.rest_time)
        this.totalTime = this.convertToShortTime(data.total_time)
        this.caloriesBurned = data.calories_burned
        this.steps = data.steps
        this.repetitionNumbers = data.repetition_numbers || 1
        this.accessories = data.accessories || []
        this.description = data.description
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.capture(data)
        return this
    }

    capture(data: WorkoutSetPayload): this {
        if (data.definitions) {
            this.definitions = []
            this.definitionIds = []
            data.definitions.map(v => {
                this.definitions.push(new GlossaryDefinition(v))
                this.definitionIds.push(v.id)
            })
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    _duration(): string {
        return this.convertToFullTime(this.duration)
    }

    _restTime(): string {
        return this.convertToFullTime(this.restTime)
    }

    _totalTime(): string {
        return this.convertToFullTime(this.totalTime)
    }

    convertToFullTime(time?: string): string {
        let converted = '00:00:00.000'
        if (time && time.length === 5) {
            converted = '00:' + time + '.000'
        }
        return converted
    }

    convertToShortTime(time?: string): string {
        let converted = '00:00'
        if (time && time.length === 12) {
            converted = time.slice(3, -4)
        }
        return converted
    }

    convertToMS(time?: string): number {
        if (time && time.length === 5) {
            let args = moment('2000-01-01 00:' + time)
            return (args.minutes() * 60 + args.seconds()) * 1000
        }
        return 0
    }

    convertMsToShortTime(ms: number = 0): string {
        let seconds = Math.floor(ms / 1000)
        let minutes = Math.floor(seconds / 60)
        seconds = seconds % 60
        minutes = minutes % 60
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    increaseDistance() {
        this.distance = (this.distance * 1) + 25
    }

    reduceDistance() {
        this.distance -= 25
        if (this.distance < 25) {
            this.distance = 25
        }
    }

    increaseDuration() {
        this.duration = this.convertMsToShortTime(this.convertToMS(this.duration) + 5000)
    }

    reduceDuration() {
        let ms = this.convertToMS(this.duration) - 5000
        if (ms < 5000) {
            ms = 5000
        }
        this.duration = this.convertMsToShortTime(ms)
    }

    increaseRest() {
        this.restTime = this.convertMsToShortTime(this.convertToMS(this.restTime) + 5000)
    }

    reduceRest() {
        let ms = this.convertToMS(this.restTime) - 5000
        if (ms < 0) {
            ms = 0
        }
        this.restTime = this.convertMsToShortTime(ms)
    }

    increaseRepetition() {
        this.repetitionNumbers = (this.repetitionNumbers * 1) + 1
    }

    reduceRepetition() {
        this.repetitionNumbers -= 1
        if (this.repetitionNumbers < 1) {
            this.repetitionNumbers = 1
        }
    }

    captureInterval() {
        if (this.interval !== 'no_time') {
            let ms = this.convertToMS(this.duration)
            if (ms < 5000) {
                ms = 5000
            }
            this.duration = this.convertMsToShortTime(ms)
        }
    }

    unset(): this {
        this.fill(<WorkoutSetPayload>{})
        this.translations = {}
        return this
    }

    __definitions(): any[] {
        const definitions = <any>[]
        if (this.definitionIds.length) {
            this.definitionIds.map(v => definitions.push({ id: v }))
        }
        return definitions
    }

    payload(): WorkoutSetPayload | any {
        return {
            id: this.id,
            stroke_id: this.strokeId,
            number: this.number,
            intensity: this.intensity,
            phase: this.phase,
            interval: this.interval,
            distance: this.distance,
            distance_unit: this.distanceUnit,
            duration: this._duration(),
            rest_time: this._restTime(),
            repetition_numbers: this.repetitionNumbers,
            accessories: this.accessories,
            description: this.description,
            definitions: this.__definitions(),
            translations: this.translations,
        }
    }
}
