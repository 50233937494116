<template>
    <v-flex>
        <div class="float-left mr-10">
            <card-unit :items="[
                { text: t('user_label_id'), value: feedback.userId, route: { name: 'trainees.show', params: { id: feedback.userId } } },
                { text: t('user_label_registration_date'), value: feedback.user.registeredAt.format('DD/MM/YYYY') },
                { text: t('user_label_email'), value: feedback.user.email },
                { text: t('user_label_first_name'), value: feedback.user.firstName },
                { text: t('user_label_last_name'), value: feedback.user.lastName },
            ]">
                <template #title>{{ t('feedback_card_title_user') }}</template>
            </card-unit>

            <card-unit :items="[
                { text: feedback.comment },
            ]">
                <template #title>{{ t('feedback_card_title_comment') }}</template>
            </card-unit>
        </div>
        <div class="float-left">
            <card-unit :items="[
                { text: t('feedback_label_id'), value: feedback.id },
                { text: t('feedback_label_workout_id'), value: feedback.workoutId, route: { name: 'workout-executions', query: { workout_execution_id: feedback.workoutExecutionId } } },
                { text: t('feedback_label_creation_date'), value: feedback.createdAt.format('DD/MM/YYYY HH:mm') },
                { text: t('feedback_label_feeling'), value: feedbackFeelingTitle(feedback.feeling) },
                { text: t('feedback_label_level'), value: feedbackLevelTitle(feedback.level) },
                { text: t('feedback_label_fit_in_time'), value: feedbackFitInTimeTitle(feedback.fitInTime) },
                { text: t('feedback_label_description'), value: feedbackDescriptionTitle(feedback.description) },
                { text: t('feedback_label_rating'), value: feedback.rating },
            ]">
                <template #title>{{ t('feedback_card_title_general') }}</template>
            </card-unit>
        </div>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useEnums } from '@/core/defaults'
    import { Feedback } from '@/core/models/feedback'
    import CardUnit from '@/components/CardUnit.vue'
    import moment from 'moment/moment'

    export default defineComponent({
        name: 'FeedbackView',

        components: {
            CardUnit,
        },

        props: {
            feedback: {
                type: Feedback,
                required: true
            }
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                currentTab: null
            })

            return {
                moment,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>