
<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>Oops!</h1>
            <h2>404 - The Page can't be found</h2>
        </div>
        <v-btn
            to="/"
            large
            color="error"
        >Go to homepage</v-btn>
    </div>
</div>
