import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import { preferredLocale } from './localizer'
import { useToken } from '@/core/utils/token'
import { useFilters } from '@/core/filters'
import { useStore } from '@/store'

export const useHttp = () => {

    const store = useStore()
    const token = useToken().getToken()

    const http = axios.create({
        baseURL: <string>import.meta.env.VITE_API_URL,
        headers: {
            'Accept': 'application/json',
            'Accept-Language': preferredLocale(),
            'Content-Timezone': moment.tz.guess(),
            'Authorization': token ? `Bearer ${token}` : '',
        }
    })

    const get = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('get', url, payload, config)
    }

    const post = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('post', url, payload, config)
    }

    const put = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('put', url, payload, config)
    }

    const patch = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('patch', url, payload, config)
    }

    const del = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return request('delete', url, config)
    }

    const request = <T>(method: any, url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {

        if (payload?.hasOwnProperty('filters')) {
            payload.filters = useFilters().build(payload?.filters)
        }

        const requestConfig: AxiosRequestConfig = {
            url: url,
            method,
            [method === 'get' ? 'params' : 'data']: payload,
            ...config
        }

        return new Promise<T>((resolve, reject) => {
            http.request(requestConfig)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status !== 422) {
                        store.commit('notify', { color: 'error', message: error.message })
                    }
                    reject(error.response)
                })
        })
    }

    return {
        get,
        post,
        put,
        patch,
        del,
        request
    }
}
