import { deepCopy } from './utils'
import { useHttp } from './http'
import Errors from './errors'

export const useForm = (data: any = {}) => {
    return new Form(data)
}

class Form {

    public busy: boolean
    public successful: boolean
    public errors: Errors
    public originalData: any

    public routes = <any>{}
    public errorMessage = 'Something went wrong. Please try again.'
    public ignore = [ 'busy', 'successful', 'errors', 'originalData', 'ignore', 'routes', 'errorMessage' ]


    /**
     * Create a new form instance.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        this.busy = false
        this.successful = false
        this.errors = new Errors()
        this.originalData = deepCopy(data)

        Object.assign(this, data)
    }

    [key: string]: any

    /**
     * Fill form data.
     *
     * @param {Object} data
     */
    fill(data: any) {
        this.keys().forEach((key: string) => {
            this[key] = data[key]
        })
    }

    /**
     * Get the form data.
     *
     * @return {Object}
     */
    data() {
        return this.keys().reduce((data, key: string) => (
            { ...data, [key]: this[key] }
        ), {})
    }

    /**
     * Get the form data keys.
     *
     * @return {Array}
     */
    keys() {
        return Object.keys(this)
            .filter(key => ! this.ignore.includes(key))
    }

    /**
     * Start processing the form.
     */
    startProcessing() {
        this.errors.clear()
        this.busy = true
        this.successful = false
    }

    /**
     * Finish processing the form.
     */
    finishProcessing() {
        this.busy = false
        this.successful = true
    }

    /**
     * Clear the form errors.
     */
    clear() {
        this.errors.clear()
        this.successful = false
    }

    /**
     * Reset the form fields.
     */
    reset() {
        Object.keys(this)
            .filter(key => ! this.ignore.includes(key))
            .forEach(key => {
                this[key] = deepCopy(this.originalData[key])
            })
    }

    /**
     * Submit the form via a GET request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    get(url: string, config = {}) {
        return this.submit('get', url, config)
    }

    /**
     * Submit the form via a POST request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    post(url: string, config = {}) {
        return this.submit('post', url, config)
    }

    /**
     * Submit the form via a PATCH request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    patch(url: string, config = {}) {
        return this.submit('patch', url, config)
    }

    /**
     * Submit the form via a PUT request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    put(url: string, config = {}) {
        return this.submit('put', url, config)
    }

    /**
     * Submit the form via a DELETE request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    delete(url: string, config = {}) {
        return this.submit('delete', url, config)
    }

    /**
     * Submit the form data via an HTTP request.
     *
     * @param  {String} method (get, post, patch, put)
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    submit<T>(method: any, url: string, config = <any>{}): Promise<T> {
        this.startProcessing()

        const data = method === 'get'
            ? { params: this.data() }
            : this.data()

        const http = useHttp()

        return this.usingSubmit(http.request(method, this.route(url), data, config))
    }

    /**
     * Submit the form data via a Promise.
     *
     * @param  {Promise} promise
     * @return {Promise}
     */
    usingSubmit<T>(promise: Promise<T>): Promise<T> {
        this.startProcessing()

        return new Promise<T>((resolve, reject) => {
            promise
                .then((data) => {
                    this.finishProcessing()

                    resolve(data as T)
                })
                .catch((response) => {
                    this.busy = false

                    this.errors.set(this.extractErrors(response))

                    reject(response as T)
                })
        })
    }

    /**
     * Extract the errors from the response object.
     *
     * @param  {Object} response
     * @return {Object}
     */
    extractErrors(response: any) {
        if (! response.data || typeof response.data !== 'object') {
            return { error: this.errorMessage }
        }

        if (response.data.errors) {
            return { ...response.data.errors }
        }

        if (response.data.message) {
            return { error: response.data.message }
        }

        return { ...response.data }
    }

    /**
     * Get a named route.
     *
     * @param  {String} name
     * @param  {Object} parameters
     * @return {String}
     */
    route(name: string, parameters = <any>{}) {
        let url = name

        if (this.routes.hasOwnProperty(name)) {
            url = decodeURI(this.routes[name])
        }

        if (typeof parameters !== 'object') {
            parameters = { id: parameters }
        }

        Object.keys(parameters).forEach(key => {
            url = url.replace(`{${key}}`, parameters[key])
        })

        return url
    }

    /**
     * Clear errors on keydown.
     *
     * @param {KeyboardEvent} event
     */
    onKeydown(event: any) {
        if (event.target.name) {
            this.errors.clear(event.target.name)
        }
    }
}

export default Form