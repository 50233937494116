export interface IStat {
    key: string
    value: number
}

export interface IStatPeriod {
    day: number
    week: number
    month: number
    year: number
}

export interface StatUserPayload {
    user_genders: IStat[]
    user_ages: IStat[]
    user_levels: IStat[]
    user_strokes: IStat[]
    user_countries: IStat[]
    user_cities: IStat[]
    user_registrations: IStatPeriod
}

export class StatUser {
    userGenders: IStat[]
    userAges: IStat[]
    userLevels: IStat[]
    userStrokes: IStat[]
    userCountries: IStat[]
    userCities: IStat[]
    userRegistrations: IStatPeriod  = { day: 0, week: 0, month: 0, year: 0 }

    constructor(data: StatUserPayload) {
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
        this.userRegistrations = data.user_registrations
    }

    fill(data: StatUserPayload): this {
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
        this.userRegistrations = data.user_registrations
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
