
<div>
    <app-bar
        :title="workout.name"
    />

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div v-if="permitted('admin_workout_management')" class="pb-10">
                <v-btn
                    v-if="workout.creator !== 'system'"
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}</v-btn>
                <v-btn
                    v-if="! workout.archived"
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(workout)"
                >
                    {{ workout.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="customized-orange"
                    outlined
                    @click.prevent="archived(workout)"
                >
                    {{ workout.archived ? t('general_action_unarchived') : t('general_action_archived') }}
                </v-btn>
                <confirm-modal
                    :title="t('general_title_confirm_action')"
                    @agree="del(workout)"
                >
                    <template #default="{ on, attrs }">
                        <v-btn
                            class="sm"
                            color="orange"
                            outlined
                            v-on="on"
                            v-bind="attrs"
                        >{{ t('general_action_delete') }}</v-btn>
                    </template>
                </confirm-modal>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <img
                    class=""
                    v-if="workout.photoUrl"
                    :src="workout.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('workout_label_description') }}:</div>
                <div class="form-subtitle-description">{{ workout.description }}</div>
                <div class="form-subtitle">{{ t('workout_label_coach_advice') }}:</div>
                <div class="form-subtitle-description">{{ workout.coachAdvice }}</div>
                <card-unit :items="items()">
                    <template #title>{{ t('workout_label_id') }}: {{ workout.id }}</template>
                </card-unit>
            </div>
            <div class="float-left mr-10">
                <v-tabs
                    v-if="workout.sets.length"
                    v-model="currentTab"
                    height="52"
                >
                    <div class="tabs-line"></div>
                    <v-tabs-slider></v-tabs-slider>

                    <template v-for="(set, index) in workout.sets">
                        <v-tab :class="{ 'pl-0': index === 0 }">{{ t('workout_set_prefix') }} {{ index + 1 }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                untitled
                                :items="[
                                { text: t('workout_set_label_phase'), value: phaseTitle(set.phase) },
                                { text: t('workout_set_label_interval'), value: intervalTitle(set.interval) },
                                { text: t('workout_set_label_repetition_numbers'), value: set.repetitionNumbers },
                                { text: t('workout_set_label_distance'), value: set.distance + ' m' },
                                { text: t('workout_set_label_stroke_type'), value: set.stroke.name },
                                { text: t('workout_set_label_duration'), value: set.duration },
                                { text: t('workout_set_label_intensity'), value: intensityTitle(set.intensity) },
                                { text: t('workout_set_label_rest_time'), value: set.restTime },
                                { text: t('workout_set_label_calories_burned'), value: set.caloriesBurned * set.repetitionNumbers },
                                { text: t('workout_set_label_steps'), value: set.steps * set.repetitionNumbers },
                                { text: t('workout_set_label_accessories'), value: accessoriesStrTitle(set.accessories) },
                                { text: t('workout_set_label_description'), value: set.description },
                            ]">
                            </card-unit>
                        </v-tab-item>
                    </template>
                </v-tabs>
            </div>
        </div>

    </v-layout>
</div>
