<template>
    <v-flex>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-app-bar
            v-if="used"
            color="white"
            height="84"
            elevation="0"
            class="px-6"
        >
            <v-toolbar-title>{{ t('workouts_title') }}</v-toolbar-title>
            <v-btn
                @click="unused"
                plain
                icon
                absolute
                right
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>

        <card-collection
            :class="{ 'px-6': used }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    v-if="! used && permitted('admin_workout_management')"
                    elevation="0"
                    color="transparent"
                    class="mt-7 mb-n3"
                    height="56"
                >
                    <v-btn
                        :to="{ name: 'workouts.create' }"
                        class="sm"
                        color="orange"
                    >{{ t('general_action_create_new') }}
                    </v-btn>
                </v-toolbar>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    :class="{ 'mt-7 mb-1': ! used }"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <v-spacer></v-spacer>

                    <v-select
                        v-if="! used"
                        v-model="queries.creator"
                        :items="[ { text: '-', value: '' } ].concat(creators())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('general_label_created_by')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="queries.swimming_location"
                        :items="[ { text: '-', value: '' } ].concat(locations())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_swimming_location')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="queries.workout_type"
                        :items="[ { text: '-', value: '' } ].concat(workoutTypes())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_workout_type')"
                    ></v-select>

                    <v-spacer></v-spacer>



                    <v-select
                        v-model="queries.user_id"
                        :items="users"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workout_label_coach')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                    :class="{ 'mt-2': ! used }"
                >
                    <v-select
                        v-model="queries.goal_id"
                        :items="goals"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_goals')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="queries.distance"
                        :items="[ { text: '-', value: '' } ].concat(distanceValues())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_distance')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="queries.duration"
                        :items="[ { text: '-', value: '' } ].concat(durationValues())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_duration')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="queries.stroke_id"
                        :items="strokes"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_stroke')"
                    ></v-select>

                    <v-select
                        v-model="queries.level"
                        :items="[ { text: '-', value: '' } ].concat(levels())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workouts_label_level')"
                    ></v-select>

                    <v-select
                        v-model="queries.provider"
                        :items="[ { text: '-', value: '' } ].concat(activityProviders())"
                        class="md"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('workout_label_provider')"
                    ></v-select>
                </v-toolbar>
                <v-toolbar
                    v-if="! used"
                    elevation="0"
                    color="transparent"
                    class="mb-0"
                    height="56"
                >
                    <v-checkbox
                        v-model="queries.only_suspended"
                        :label="t('general_label_show_suspended')"
                        hide-details
                        class="ma-0 mr-5 customized-deep-primary-color"
                    />
                    <v-checkbox
                        v-if="! used"
                        v-model="queries.archived"
                        :label="t('workout_label_show_archived')"
                        hide-details
                        class="ma-0 mr-5 customized-deep-primary-color"
                    />
                    <v-checkbox
                        v-if="! used"
                        v-model="queries.not_system"
                        :label="t('workout_label_hide_system')"
                        hide-details
                        class="ma-0 customized-deep-primary-color"
                    />
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th
                        v-if="used"
                        class="pl-2"
                    >
                        <v-checkbox
                            v-model="fully"
                            class="ma-0 pa-0"
                            dense
                            hide-details
                        ></v-checkbox>
                    </th>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="id"
                            @touched="onSort"
                        >{{ t('workout_label_id') }}
                        </sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="name"
                            @touched="onSort"
                        >{{ t('workout_label_name') }}
                        </sort-field>
                    </th>
                    <th v-if="! used">{{ t('workout_label_created_by') }}</th>
                    <th>{{ t('workout_label_swimming_location') }}</th>
                    <th>{{ t('workout_label_stroke') }}</th>
                    <th>{{ t('workout_label_workout_types') }}</th>
                    <th>{{ t('workout_label_goals') }}</th>
                    <th>{{ t('workout_label_distance') }}</th>
                    <th>{{ t('workout_label_duration') }}</th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="executions"
                            @touched="onSort"
                        >{{ t('workout_label_executions_count') }}
                        </sort-field>
                    </th>
                    <th
                        v-if="! used"
                        class="text-center"
                    >{{ t('general_label_action') }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" :colspan="used ? 10 : 11">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="workout in collection.data">
                    <tr>
                        <td
                            v-if="used"
                            class="pl-2"
                        >
                            <v-checkbox
                                v-model="ids"
                                :value="workout.id"
                                class="ma-0 pa-0"
                                dense
                                multiple
                                hide-details
                            ></v-checkbox>
                        </td>
                        <td>
                            <a
                                :href="`/workouts/${workout.id}/view`"
                                @click.prevent="view(workout)"
                                :class="{ 'orange--text': ! workout.enabled }"
                            >
                                {{ workout.id }}<template v-if="workout.archived">/a</template>
                            </a>
                        </td>
                        <td>
                            <a
                                :class="levelColor(workout.level) + '--text'"
                                :href="`/workouts/${workout.id}/view`"
                                @click.prevent="view(workout)"
                            >{{ workout.name }}</a>
                        </td>
                        <td v-if="! used">
                            <template v-if="! workout.userId">{{ creatorTitle(workout.creator) }}</template>
                            <a
                                v-else
                                @click="go({ name: 'trainees.show', params: { id: workout.userId }, query: { tab: '3' } })"
                            >#{{ workout.user.fullName }}</a>
                        </td>
                        <td>
                            {{ locationTitle(workout.swimmingLocation) }}
                        </td>
                        <td>
                            {{ workout.stroke.name }}
                        </td>
                        <td>
                            {{ workoutTypesStrTitle(workout.workoutTypes) }}
                        </td>
                        <td>
                            {{ workout.goalsStrTitle }}
                        </td>
                        <td>
                            {{ workout.workoutDistance }} m
                        </td>
                        <td>
                            {{ time.format(workout.workoutTotalTime) }}
                        </td>
                        <td>
                            {{ workout.executions.length }}
                        </td>
                        <td
                            v-if="! used"
                            class="text-center"
                        >
                            <v-menu
                                offset-y
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click.prevent="view(workout)"
                                    >
                                        <v-list-item-title
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_details') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="permitted('admin_workout_management') && ! workout.archived"
                                        @click.prevent="suspend(workout)"
                                    >
                                        <v-list-item-title
                                            v-if="workout.enabled"
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_suspend') }}
                                        </v-list-item-title>
                                        <v-list-item-title
                                            v-else
                                            class="orange--text"
                                        >{{ t('general_action_unsuspend') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="permitted('admin_workout_management')"
                                        @click.prevent="archived(workout)"
                                    >
                                        <v-list-item-title
                                            v-if="! workout.archived"
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_archived') }}
                                        </v-list-item-title>
                                        <v-list-item-title
                                            v-else
                                            class="orange--text"
                                        >{{ t('general_action_unarchived') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="permitted('admin_workout_management')"
                                        :to="{ name: 'workouts.create', query: { id: workout.id } }"
                                    >
                                        <v-list-item-title
                                            class="customized-deep-primary--text"
                                        >{{ t('general_action_duplicate') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <confirm-modal
                                        v-if="permitted('admin_workout_management')"
                                        :title="t('general_title_confirm_action')"
                                        @agree="del(workout)"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                href
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </confirm-modal>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
        <div
            v-if="used"
            class="pb-6 px-12"
        >
            <v-btn
                @click="unused"
                class="mr-4"
                color="primary"
            >{{ t('general_action_cancel') }}
            </v-btn>
            <v-btn
                @click="unused(true)"
                v-if="collection.data.length"
                color="orange"
                :disabled="! ids.length"
            >{{ t('program_workout_button_add_selected') }}
            </v-btn>
        </div>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { Workout } from '@/core/models/workout'
    import { WorkoutCollection } from '@/store/modules/workout-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import SortField from '@/components/SortField.vue'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { Stroke } from '@/core/models/stroke'
    import { Goal } from '@/core/models/goal'
    import { User } from '@/core/models/user'

    export default defineComponent({
        name: 'WorkoutCollection',

        components: {
            DatePickerModal,
            CardCollection,
            ConfirmModal,
            SortField,
        },

        props: {
            used: Boolean
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined,
                ids: <number[]>[],
                fully: false,
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: 'name',
                only_suspended: undefined,
                not_system: true,
                archived: undefined,
                relations: 'user,stroke,executions',
                creator: props.used ? 'openswim' : undefined,
                swimming_location: undefined,
                workout_type: undefined,
                distance: undefined,
                duration: undefined,
                stroke_id: undefined,
                goal_id: undefined,
                user_id: undefined,
                level: undefined,
                provider: undefined,
            })

            const reset = () => {
                queries.search = undefined
                queries.only_suspended = undefined
                queries.not_system = true
                queries.archived = undefined
                queries.creator = props.used ? 'openswim' : undefined
                queries.swimming_location = undefined
                queries.workout_type = undefined
                queries.distance = undefined
                queries.duration = undefined
                queries.stroke_id = undefined
                queries.goal_id = undefined
                queries.user_id = undefined
                queries.level = undefined
                queries.provider = undefined
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <WorkoutCollection>store.getters['workoutCollection/get'])
            const strokes = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))
            const goals = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['goalCollection/get'].data.map((item: Goal) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))
            const users = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['userCollection/get'].data.map((item: User) => {
                    return { text: item.fullName, value: item.id }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('workoutCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const view = (workoutModel: Workout) => {
                const route = { name: 'workouts.view', params: <any>{ id: workoutModel.id } }
                props.used ? window.open(router.resolve(route).href, '_blank') : router.push(route)
            }

            const suspend = (workoutModel: Workout) => {
                state.overlay = true
                store.dispatch('workout/update', {
                    id: workoutModel.id,
                    payload: { enabled: ! workoutModel.enabled, only: 'enabled' }
                }).then(() => {
                    capture()
                })
            }

            const archived = (workoutModel: Workout) => {
                state.overlay = true
                store.dispatch('workout/update', { id: workoutModel.id, payload: { archived: ! workoutModel.archived, only: 'archived' } }).then(() => {
                    capture()
                })
            }

            const del = (workout: Workout) => {
                state.overlay = true
                store.dispatch('workout/delete', { id: workout.id, force: true }).then(() => {
                    capture()
                })
            }

            const search = () => {
                queries.page = 1
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = 'name'
                }
                queries.sort = value
            }

            const unused = (capture: boolean = false) => {
                let workouts = <Workout[]>[]
                if (capture) {
                    workouts = collection.value.data.filter(item => state.ids.includes(item.id))
                }
                context.emit('unused', workouts)
            }

            const go = (route: any) => {
                router.push(route)
            }

            watch(() => [
                queries.sort,
                queries.only_suspended,
                queries.not_system,
                queries.archived,
                queries.creator,
                queries.swimming_location,
                queries.workout_type,
                queries.distance,
                queries.duration,
                queries.stroke_id,
                queries.goal_id,
                queries.user_id,
                queries.level,
                queries.provider,
            ], () => {
                capture()
            })

            watch(() => [ state.fully ], () => {
                state.ids = []
                if (state.fully) {
                    collection.value.data.map(item => state.ids.push(item.id))
                }
            })

            onMounted(() => {
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                store.dispatch('goalCollection/get', { sort: 'sort_order' })
                store.dispatch('userCollection/get', { sort: 'first_name', coach: 1 })
                capture()
            })

            return {
                time,
                queries,
                collection: collection.value,
                goals,
                strokes,
                users,
                moment,
                view,
                suspend,
                archived,
                updated,
                del,
                onSort,
                search,
                clear,
                reset,
                unused,
                go,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>