
<v-dialog
    v-model="touched"
    max-width="480"
    persistent
    scrollable
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
        <v-card-title class="customized-primary white--text">
            {{ t('general_label_search') }}
            <v-btn
                @click="cancel"
                icon
                absolute
                right
                color="white"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-text-field
                v-model="queries.search"
                @click:clear="clear"
                @keyup.enter="capture"
                class="mt-4 mb-2"
                clearable
                outlined
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                :disabled="overlay"
                :label="t('event_placeholder_enter_user_data')"
            ></v-text-field>
            <v-list>
                <v-list-item
                    v-for="user in collection.data"
                    :key="`user_${user.id}`"
                    class="px-0"
                >
                    {{ user.fullName }} | {{ user.id }}
                    <v-spacer></v-spacer>
                    <template v-if="isParticipant(user.id)">
                        <v-btn
                            @click="detach(user)"
                            class="exs mt-1"
                            color="orange"
                            small
                        >{{ t('general_action_remove') }}</v-btn>
                    </template>
                    <template v-else>
                        <v-btn
                            @click="attach(user)"
                            class="exs mt-1"
                            color="primary"
                            small
                        >{{ t('event_action_add_user') }}</v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</v-dialog>
