<template>
    <div>
        <app-bar :title="t('health_title')">
            <template #actions>
                <div class="px-10">
                    <v-btn
                        :to="{ name: 'sections.create' }"
                        class="sm sm-h"
                        color="orange"
                    >{{ t('health_section_title_add') }}</v-btn>
                </div>
            </template>
        </app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <v-tabs
                class="px-4 my-7"
                height="52"
            >
                <div class="tabs-line"></div>
                <v-tab
                    :to="{ name: 'sections' }"
                    class="pl-0"
                >{{ t('health_tab_sections') }}</v-tab>
                <v-tab
                    :to="{ name: 'articles' }"
                >{{ t('health_tab_articles') }}</v-tab>

                <v-tabs-items>
                    <card-collection
                        class="px-0-nested"
                        :meta="meta"
                        :busy="overlay"
                        @updated="updated"
                    >
                        <template #data>
                            <thead>
                            <tr>
                                <th class="touched">
                                    <sort-field
                                        v-model="queries.sort"
                                        field="id"
                                        @touched="onSort"
                                    >{{ t('health_section_label_id') }}
                                    </sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="name"
                                        @touched="onSort"
                                    >{{ t('health_section_label_name') }}
                                    </sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="created_at"
                                        @touched="onSort"
                                    >{{ t('general_label_creation_date') }}
                                    </sort-field>
                                </th>
                                <th>{{ t('health_section_label_articles') }}</th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="published"
                                        @touched="onSort"
                                    >{{ t('health_section_label_published') }}
                                    </sort-field>
                                </th>
                                <th>
                                    <sort-field
                                        v-model="queries.sort"
                                        field="sort_order"
                                        @touched="onSort"
                                    >{{ t('health_section_label_sort_order') }}
                                    </sort-field>
                                </th>
                                <th class="text-center">{{ t('general_label_action') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="collection.data.length === 0">
                                <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                            </tr>
                            <template v-else v-for="section in collection.data">
                                <tr>
                                    <td>
                                        <a
                                            :href="`/health/sections/${section.id}/view`"
                                            :class="{ 'orange--text': ! section.published }"
                                            @click.prevent="touch(section)"
                                        >{{ section.id }}</a>
                                    </td>
                                    <td>
                                        <a
                                            :href="`/health/sections/${section.id}/view`"
                                            @click.prevent="touch(section)"
                                        >{{ section.name }}</a>
                                    </td>
                                    <td>
                                        {{ section.createdAt.format('DD/MM/YYYY') }}
                                    </td>
                                    <td>{{ section.articlesCount }}</td>
                                    <td>
                                        {{ section.published ? t('general_text_yes') : t('general_text_no') }}
                                    </td>
                                    <td>{{ section.sortOrder }}</td>
                                    <td class="text-center">
                                        <v-menu
                                            offset-y
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item
                                                    @click.prevent="edit(section)"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_edit') }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click.prevent="suspend(section)"
                                                >
                                                    <v-list-item-title
                                                        v-if="section.published"
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_suspend') }}
                                                    </v-list-item-title>
                                                    <v-list-item-title
                                                        v-else
                                                        class="orange--text"
                                                    >{{ t('general_action_unsuspend') }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <confirm-modal
                                                    :title="t('general_title_confirm_action')"
                                                    @agree="del(section)"
                                                >
                                                    <template #default="{ on, attrs }">
                                                        <v-list-item
                                                            href
                                                            v-on="on"
                                                            v-bind="attrs"
                                                        >
                                                            <v-list-item-title
                                                                class="customized-deep-primary--text"
                                                            >{{ t('general_action_delete') }}</v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                </confirm-modal>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </template>
                    </card-collection>
                </v-tabs-items>
            </v-tabs>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { HealthSectionCollection } from '@/store/modules/health-section-collection'
    import { HealthSection } from '@/core/models/health-section'
    import CardCollection from '@/components/CardCollection.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import SortField from '@/components/SortField.vue'

    export default defineComponent({

        components: {
            CardCollection,
            ConfirmModal,
            SortField,
        },

        /**
         * Component constructor.
         * @param props
         */
        setup(props) {
            const state = reactive({
                overlay: true,
                meta: undefined,
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                append: 'articles_count',
                published: '',
            })

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <HealthSectionCollection>store.getters['healthSectionCollection/get'])

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const capture = () => {
                state.overlay = true
                store.dispatch('healthSectionCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const touch = (section: HealthSection) => {
                router.push({ name: 'sections.view', params: <any>{ id: section.id } })
            }

            const edit = (section: HealthSection) => {
                router.push({ name: 'sections.show', params: <any>{ id: section.id } })
            }

            const suspend = (section: HealthSection) => {
                state.overlay = true
                store.dispatch('healthSection/update', {
                    id: section.id,
                    payload: { published: ! section.published }
                }).then(() => {
                    capture()
                })
            }

            const del = (sectionModel: HealthSection) => {
                state.overlay = true
                store.dispatch('healthSection/delete', { id: sectionModel.id }).then(() => {
                    capture()
                })
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
            ], () => {
                capture()
            })

            onMounted(() => {
                collection.value.data = []
                capture()
            })

            return {
                queries,
                collection: collection.value,
                updated,
                touch,
                edit,
                suspend,
                del,
                onSort,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
