
<div>
    <app-bar :title="t('badges_title')">
        <template #actions>
            <div class="px-10">
                <v-btn
                    :to="{ name: 'badges.create' }"
                    class="sm sm-h"
                    color="orange"
                >{{ t('badge_title_add') }}</v-btn>
            </div>
        </template>
    </app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <v-tabs
            class="px-4 my-7"
            height="52"
        >
            <div class="tabs-line"></div>
            <v-tab
                :to="{ name: 'badge-sections' }"
                class="pl-0"
            >{{ t('badges_tab_sections') }}</v-tab>
            <v-tab
                :to="{ name: 'badges' }"
            >{{ t('badges_tab_badges') }}</v-tab>
            <v-tabs-items>
                <card-collection
                    class="px-0-nested"
                    :meta="meta"
                    :busy="overlay"
                    @updated="updated"
                >
                    <template #filters>
                        <v-toolbar
                            elevation="0"
                            color="transparent"
                            class="mt-7"
                            height="56"
                        >
                            <v-text-field
                                v-model="queries.search"
                                @click:clear="clear"
                                class="input-search md mr-2"
                                clearable
                                outlined
                                dense
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                :label="t('general_label_search')"
                            ></v-text-field>

                            <v-spacer></v-spacer>

                            <v-select
                                v-model="filters.badge_section_id"
                                :items="sections"
                                class="md mr-2"
                                outlined
                                dense
                                append-icon="mdi-chevron-down"
                                hide-details
                                :label="t('badge_label_section')"
                            ></v-select>

                            <v-spacer></v-spacer>

                            <v-select
                                v-model="queries.published"
                                :items="[ { text: '-', value: '' }, { text: t('general_text_yes'), value: '1' }, { text: t('general_text_no'), value: '0' } ]"
                                class="md mr-2"
                                outlined
                                dense
                                append-icon="mdi-chevron-down"
                                hide-details
                                :label="t('badge_label_published')"
                            ></v-select>

                            <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                            <date-picker-modal
                                :value="queries.date_from"
                                @updated="(v) => queries.date_from = v"
                            >
                                <template #default="{ on, attrs }">
                                    <div
                                        v-on="on"
                                        v-bind="attrs"
                                    >
                                        <v-text-field
                                            v-model="queries.date_from"
                                            class="xs input-color-inherit"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            :label="t('general_label_from')"
                                        ></v-text-field>
                                    </div>
                                </template>
                            </date-picker-modal>

                            <span class="sub-label px-2">-</span>

                            <date-picker-modal
                                :value="queries.date_to"
                                @updated="(v) => queries.date_to = v"
                            >
                                <template #default="{ on, attrs }">
                                    <div
                                        v-on="on"
                                        v-bind="attrs"
                                    >
                                        <v-text-field
                                            v-model="queries.date_to"
                                            class="xs input-color-inherit"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            :label="t('general_label_to')"
                                        ></v-text-field>
                                    </div>
                                </template>
                            </date-picker-modal>

                            <v-spacer></v-spacer>

                            <v-btn
                                @click="search"
                                class="sm mx-5"
                                color="primary"
                            >{{ t('general_action_search') }}</v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                v-ripple="false"
                                plain
                                color="orange darken-4"
                                @click="reset"
                            >{{ t('general_action_reset_filters') }}</v-btn>

                        </v-toolbar>
                    </template>

                    <template #data>
                        <thead>
                        <tr>
                            <th class="touched">
                                <sort-field
                                    v-model="queries.sort"
                                    field="id"
                                    @touched="onSort"
                                >{{ t('badge_label_id') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="name"
                                    @touched="onSort"
                                >{{ t('badge_label_name') }}
                                </sort-field>
                            </th>
                            <th>{{ t('badge_label_section') }}</th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="created_at"
                                    @touched="onSort"
                                >{{ t('general_label_creation_date') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="published"
                                    @touched="onSort"
                                >{{ t('badge_label_published') }}
                                </sort-field>
                            </th>
                            <th>
                                <sort-field
                                    v-model="queries.sort"
                                    field="sort_order"
                                    @touched="onSort"
                                >{{ t('badge_section_label_sort_order') }}
                                </sort-field>
                            </th>
                            <th class="text-center">{{ t('general_label_action') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="collection.data.length === 0">
                            <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                        </tr>
                        <template v-else v-for="badge in collection.data">
                            <tr>
                                <td>
                                    <a
                                        :href="`/badges/badges/${badge.id}/view`"
                                        @click.prevent="touch(badge)"
                                        :class="{ 'orange--text': ! badge.published }"
                                    >{{ badge.id }}</a>
                                </td>
                                <td>
                                    <a
                                        :href="`/badges/badges/${badge.id}/view`"
                                        @click.prevent="touch(badge)"
                                    >{{ badge.name }}</a>
                                </td>
                                <td>{{ badge.section.name }}</td>
                                <td>
                                    {{ badge.createdAt.format('DD/MM/YYYY') }}
                                </td>
                                <td>
                                    {{ badge.published ? t('general_text_yes') : t('general_text_no') }}
                                </td>
                                <td>{{ badge.sortOrder }}</td>
                                <td class="text-center">
                                    <v-menu
                                        offset-y
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item
                                                @click.prevent="edit(badge)"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_edit') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                @click.prevent="suspend(badge)"
                                            >
                                                <v-list-item-title
                                                    v-if="badge.published"
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_suspend') }}
                                                </v-list-item-title>
                                                <v-list-item-title
                                                    v-else
                                                    class="orange--text"
                                                >{{ t('general_action_unsuspend') }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <confirm-modal
                                                :title="t('general_title_confirm_action')"
                                                @agree="del(badge)"
                                            >
                                                <template #default="{ on, attrs }">
                                                    <v-list-item
                                                        href
                                                        v-on="on"
                                                        v-bind="attrs"
                                                    >
                                                        <v-list-item-title
                                                            class="customized-deep-primary--text"
                                                        >{{ t('general_action_delete') }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </confirm-modal>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </card-collection>
            </v-tabs-items>
        </v-tabs>
    </v-layout>
</div>
