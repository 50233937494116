export interface StatTotalPayload {
    total_distance: number
    total_duration: string
    total_registrations: number
    total_workout_executions: number
    total_program_executions: number
}

export class StatTotal {

    totalDistance: number
    totalDuration: string
    totalRegistrations: number
    totalWorkoutExecutions: number
    totalProgramExecutions: number

    constructor(data: StatTotalPayload) {
        this.totalDistance = data.total_distance || 0
        this.totalDuration = data.total_duration || '00:00:00.000'
        this.totalRegistrations = data.total_registrations || 0
        this.totalWorkoutExecutions = data.total_workout_executions || 0
        this.totalProgramExecutions = data.total_program_executions || 0
    }

    fill(data: StatTotalPayload): this {
        this.totalDistance = data.total_distance || 0
        this.totalDuration = data.total_duration || '00:00:00.000'
        this.totalRegistrations = data.total_registrations || 0
        this.totalWorkoutExecutions = data.total_workout_executions || 0
        this.totalProgramExecutions = data.total_program_executions || 0
        return this
    }

    public get _totalDistance(): string {
        return Math.round(this.totalDistance / 1000) + ' KM'
    }

    public get _totalDuration(): string {
        return (this.totalDuration ? this.totalDuration.split(':')[0] : '0') + ' hours'
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
