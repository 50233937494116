import moment, { Moment } from 'moment/moment'
import { Workout, WorkoutPayload } from '@/core/models/workout'
import { WorkoutSchedule, WorkoutSchedulePayload } from '@/core/models/workout-schedule'
import { WorkoutSetExecution, WorkoutSetExecutionPayload } from '@/core/models/workout-set-execution'
import { User, UserPayload } from '@/core/models/user'
import { Feedback, FeedbackPayload } from '@/core/models/feedback'

export interface WorkoutExecutionPayload {
    id: number
    user_id: number
    workout_id: number
    workout_schedule_id: number
    total_distance: number
    total_distance_unit: string
    total_duration: string
    total_rest_time: string
    total_time: string
    total_calories_burned: number
    total_steps: number
    max_speed: number
    average_speed: number
    max_heartrate: number
    average_heartrate: number
    avg_pace: string
    device: string
    finished_at: string
    user?: UserPayload
    workout?: WorkoutPayload
    workout_schedule?: WorkoutSchedulePayload
    workout_set_executions?: WorkoutSetExecutionPayload[]
    feedback?: FeedbackPayload
    users?: UserPayload[]
}

export class WorkoutExecution {

    id: number
    userId: number
    workoutId: number
    workoutScheduleId: number
    totalDistance: number
    totalDistanceUnit: string
    totalDuration: string
    totalRestTime: string
    totalTime: string
    totalCaloriesBurned: number
    totalSteps: number
    maxSpeed: number
    averageSpeed: number
    maxHeartrate: number
    averageHeartrate: number
    avgPace: string
    device: string
    finishedAt: Moment | null
    user: User
    workout: Workout
    workoutSchedule: WorkoutSchedule
    workoutSetExecutions: WorkoutSetExecution[] = <WorkoutSetExecution[]>[]
    feedback?: Feedback
    users: User[] = <User[]>[]

    constructor(data: WorkoutExecutionPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.workoutId = data.workout_id
        this.workoutScheduleId = data.workout_schedule_id
        this.totalDistance = data.total_distance
        this.totalDistanceUnit = data.total_distance_unit
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.totalCaloriesBurned = data.total_calories_burned
        this.totalSteps = data.total_steps
        this.maxSpeed = data.max_speed
        this.averageSpeed = data.average_speed
        this.maxHeartrate = data.max_heartrate
        this.averageHeartrate = data.average_heartrate
        this.avgPace = data.avg_pace
        this.device = data.device
        this.finishedAt = data.finished_at ? moment(data.finished_at) : null
        this.user = new User(data.user || <any>{})
        this.workout = new Workout(data.workout || <any>{})
        this.workoutSchedule = new WorkoutSchedule(data.workout_schedule || <any>{})
        if (data.feedback) {
            this.feedback = new Feedback(data.feedback)
        }
        this.capture(data)
    }

    fill(data: WorkoutExecutionPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.workoutId = data.workout_id
        this.workoutScheduleId = data.workout_schedule_id
        this.totalDistance = data.total_distance
        this.totalDistanceUnit = data.total_distance_unit
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.totalCaloriesBurned = data.total_calories_burned
        this.totalSteps = data.total_steps
        this.maxSpeed = data.max_speed
        this.averageSpeed = data.average_speed
        this.maxHeartrate = data.max_heartrate
        this.averageHeartrate = data.average_heartrate
        this.avgPace = data.avg_pace
        this.device = data.device
        this.finishedAt = data.finished_at ? moment(data.finished_at) : null
        this.user = new User(data.user || <any>{})
        this.workout = new Workout(data.workout || <any>{})
        this.workoutSchedule = new WorkoutSchedule(data.workout_schedule || <any>{})
        if (data.feedback) {
            this.feedback = new Feedback(data.feedback)
        }
        this.capture(data)
        return this
    }

    capture(data: WorkoutExecutionPayload): this {
        if (data.workout_set_executions) {
            this.workoutSetExecutions = <WorkoutSetExecution[]>[]
            data.workout_set_executions.map(item => this.workoutSetExecutions.push(new WorkoutSetExecution(item)))
        }
        if (data.users) {
            this.users = <User[]>[]
            data.users.map(item => this.users.push(new User(item)))
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
