import { useHttp } from '../http'

export const useStrokeHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`strokes/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`strokes`, payload)
    }

    return {
        find,
        get,
    }
}