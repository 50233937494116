
<div>
    <app-bar :title="t('trainees_title')"></app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-7"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="filters.status"
                        :items="[ { text: '-', value: '' } ].concat(userStatuses())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('user_label_status')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}</v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}</v-btn>

                </v-toolbar>
            </template>

            <template #data>
                <thead>
                    <tr>
                        <th class="touched">
                            <sort-field
                                v-model="queries.sort"
                                field="id"
                                @touched="onSort"
                            >{{ t('user_label_id') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="last_name"
                                @touched="onSort"
                            >{{ t('user_label_last_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="first_name"
                                @touched="onSort"
                            >{{ t('user_label_first_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="email"
                                @touched="onSort"
                            >{{ t('user_label_email_address') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="registered_at"
                                @touched="onSort"
                            >{{ t('user_label_sign_up_date') }}</sort-field>
                        </th>
                        <th>{{ t('user_label_status') }}</th>
                        <th class="text-center">{{ t('general_label_action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="collection.data.length === 0">
                        <td class="text-center" colspan="7">{{ t('general_text_no_data') }}</td>
                    </tr>
                    <template v-else v-for="user in collection.data">
                        <tr>
                            <td>
                                <a
                                    :href="`/trainees/${user.id}`"
                                    @click.prevent="show(user)"
                                    :class="{ 'orange--text': user.suspended, 'red--text': user.deleted }"
                                >{{ user.id }}</a>
                            </td>
                            <td>
                                <a
                                    :href="`/trainees/${user.id}`"
                                    @click.prevent="show(user)"
                                >{{ user.lastName }}</a>
                            </td>
                            <td>{{ user.firstName }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.registeredAt.format('DD/MM/YYYY HH:mm') }}</td>
                            <td>{{ userStatusTitle(user.status) }}</td>
                            <td class="text-center">
                                <v-menu
                                    offset-y
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            @click.prevent="show(user)"
                                        >
                                            <v-list-item-title
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_details') }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="user.isNotDeleted()"
                                            @click.prevent="suspend(user)"
                                        >
                                            <v-list-item-title
                                                v-if="! user.suspended"
                                                class="customized-deep-primary--text"
                                            >{{ t('general_action_suspend') }}</v-list-item-title>
                                            <v-list-item-title
                                                v-else
                                                class="orange--text"
                                            >{{ t('general_action_unsuspend') }}</v-list-item-title>
                                        </v-list-item>
                                        <confirm-modal
                                            v-if="! user.deleted"
                                            :title="t('general_title_confirm_action')"
                                            @agree="del(user)"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    href
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </confirm-modal>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
            <template #export>
                <v-btn
                    @click="_export"
                    class="exs mt-4 ml-4"
                    color="primary"
                    small
                >{{ t('general_action_export') }}</v-btn>
            </template>
        </card-collection>
    </v-layout>
</div>
