import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useUserStatsHttp } from '@/core/api/user-stats'
import { UserStat, UserStatPayload } from '@/core/models/user-stat'

const state: UserStat = new UserStat(<UserStatPayload>{})

export const userStat: Module<UserStat, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: UserStat, payload: UserStatPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useUserStatsHttp()
                    .get(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: UserStat): UserStat => {
            return state
        }
    }
}