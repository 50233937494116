
<v-flex>
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <card-collection
        class="px-0-nested"
        :meta="meta"
        :busy="overlay"
        @updated="updated"
    >
        <template #filters>
            <v-toolbar
                elevation="0"
                color="transparent"
                class="mt-7 mb-n3"
                height="56"
            >
                <v-btn
                    :to="{ name: 'notifications.create' }"
                    class="sm"
                    color="orange"
                >{{ t('general_action_create_new') }}
                </v-btn>
            </v-toolbar>
            <v-toolbar
                elevation="0"
                color="transparent"
                class="mt-7 mb-0"
                height="56"
            >
                <v-text-field
                    v-model="queries.search"
                    @click:clear="clear"
                    class="input-search md mr-2"
                    clearable
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    :label="t('general_label_search')"
                ></v-text-field>

                <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                <date-picker-modal
                    :value="queries.date_from"
                    @updated="(v) => queries.date_from = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.date_from"
                                class="xs input-color-inherit"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_from')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <span class="sub-label px-2">-</span>

                <date-picker-modal
                    :value="queries.date_to"
                    @updated="(v) => queries.date_to = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.date_to"
                                class="xs input-color-inherit mr-2"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_to')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <v-select
                    v-model="queries.role_id"
                    :items="roles"
                    class="md mx-2"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('notification_label_send_to')"
                ></v-select>

                <v-select
                    v-model="queries.status"
                    :items="[ { text: '-', value: '' } ].concat(notificationStatuses())"
                    class="md mx-2"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('notification_label_status')"
                ></v-select>

                <v-select
                    v-model="queries.force"
                    :items="[ { text: '-', value: '' }, { text: t('general_text_yes'), value: 'yes' }, { text: t('general_text_no'), value: 'no' } ]"
                    class="md mx-2"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('notifications_override')"
                ></v-select>

                <v-btn
                    @click="search"
                    class="sm mx-5"
                    color="primary"
                >{{ t('general_action_search') }}
                </v-btn>

                <v-btn
                    v-ripple="false"
                    plain
                    color="orange darken-4"
                    @click="reset"
                >{{ t('general_action_reset_filters') }}
                </v-btn>

            </v-toolbar>
        </template>

        <template #data>
            <thead>
            <tr>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="id"
                        @touched="onSort"
                    >{{ t('notification_label_id') }}</sort-field>
                </th>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="title"
                        @touched="onSort"
                    >{{ t('notification_label_title') }}</sort-field>
                </th>
                <th>{{ t('notification_label_send_to') }}</th>
                <th>{{ t('notification_label_cta') }}</th>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="send_at"
                        @touched="onSort"
                    >{{ t('notifications_send_at') }}</sort-field>
                </th>
                <th>{{ t('notification_label_status') }}</th>
                <th>{{ t('notifications_override') }}</th>
                <th class="text-center">{{ t('general_label_action') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="collection.data.length === 0">
                <td class="text-center" colspan="8">{{ t('general_text_no_data') }}</td>
            </tr>
            <template v-else v-for="notification in collection.data">
                <tr>
                    <td>
                        <a
                            :href="`/notifications/${notification.id}/view`"
                            @click.prevent="view(notification)"
                            :class="{ 'orange--text': notification.status === 'draft' }"
                        >{{ notification.id }}</a>
                    </td>
                    <td>
                        <a
                            :href="`/notifications/${notification.id}/view`"
                            @click.prevent="view(notification)"
                        >{{ notification.title }}</a>
                    </td>
                    <td>{{ notification.roleId ? notification.role.name : t('notification_label_role_id_null') }}</td>
                    <td>{{ notification.cta ? t('general_text_yes') : t('general_text_no') }}</td>
                    <td>{{ notification.sendAt.format('DD/MM/YYYY HH:mm') }}</td>
                    <td>{{ notificationStatusTitle(notification.status) }}</td>
                    <td>{{ notification.force ? t('general_text_yes') : t('general_text_no') }}</td>
                    <td
                        class="text-center"
                    >
                        <v-menu
                            offset-y
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click.prevent="view(notification)">
                                    <v-list-item-title class="customized-deep-primary--text">
                                        {{ t('general_action_details') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.prevent="edit(notification)">
                                    <v-list-item-title class="customized-deep-primary--text">
                                        {{ t('general_action_edit') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    :to="{ name: 'notifications.create', query: { id: notification.id } }"
                                >
                                    <v-list-item-title class="customized-deep-primary--text">
                                        {{ t('general_action_duplicate') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.prevent="del(notification)">
                                    <v-list-item-title class="customized-deep-primary--text">
                                        {{ t('general_action_delete') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
            </tbody>
        </template>
    </card-collection>
</v-flex>
