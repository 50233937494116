
<div class="fill-height">
    <v-progress-linear
        v-if="overlay && ! (!! user)"
        absolute
        indeterminate
    ></v-progress-linear>

    <div class="dashboard">

        <div class="dashboard-units">
            <div class="dashboard-stats dashboard-stats-full">
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i
                        class="ai ai-feedback-feeling"></i>{{ t('feedback_label_feeling') }}
                    </div>
                    <table class="dashboard-stat-items dashboard-stat-items-3">
                        <tr>
                            <td>{{ feedbackFeelingTitle('not_great') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.feelings.notGreat }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackFeelingTitle('neutral') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.feelings.neutral }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackFeelingTitle('very_good') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.feelings.veryGood }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i
                        class="ai ai-feedback-level"></i>{{ t('feedback_label_level') }}
                    </div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ feedbackLevelTitle('very_hard') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.levels.veryHard }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackLevelTitle('challenging') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.levels.challenging }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackLevelTitle('fine') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.levels.fine }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackLevelTitle('easy') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.levels.easy }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i
                        class="ai ai-feedback-fit-in-time"></i>{{ t('feedback_label_fit_in_time') }}
                    </div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ feedbackFitInTimeTitle('not_at_all') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.fitInTime.notAtAll }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackFitInTimeTitle('hard_but_ok') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.fitInTime.hardButOk }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackFitInTimeTitle('satisfying') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.fitInTime.satisfying }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackFitInTimeTitle('perfectly') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.fitInTime.perfectly }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dashboard-stat">
                    <div class="dashboard-stat-title"><i
                        class="ai ai-feedback-description"></i>{{ t('feedback_label_description') }}
                    </div>
                    <table class="dashboard-stat-items">
                        <tr>
                            <td>{{ feedbackDescriptionTitle('too_long') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.descriptions.tooLong }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackDescriptionTitle('confusing') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.descriptions.confusing }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackDescriptionTitle('clear') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.descriptions.clear }}</td>
                        </tr>
                        <tr>
                            <td>{{ feedbackDescriptionTitle('very_helpful') }}</td>
                            <td>
                                <div></div>
                            </td>
                            <td>{{ stat.descriptions.veryHelpful }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <card-collection
            class="px-6"
            :class="{ 'px-0-nested': !! user, 'pt-7': ! (!! user) }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label pr-2">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-select
                        v-model="queries.feeling"
                        :items="[ { text: '-', value: '' } ].concat(feedbackFeelings())"
                        class="md mx-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('feedback_label_feeling')"
                    ></v-select>

                    <v-select
                        v-model="queries.level"
                        :items="[ { text: '-', value: '' } ].concat(feedbackLevels())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('feedback_label_level')"
                    ></v-select>

                    <v-select
                        v-model="queries.fit_in_time"
                        :items="[ { text: '-', value: '' } ].concat(feedbackFitInTime())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('feedback_label_fit_in_time')"
                    ></v-select>

                    <v-select
                        v-model="queries.description"
                        :items="[ { text: '-', value: '' } ].concat(feedbackDescriptions())"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('feedback_label_description')"
                    ></v-select>

                    <v-select
                        v-model="queries.rating"
                        :items="[ { text: '-', value: '' } ].concat(ratingValues())"
                        class="md"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('feedback_label_rating')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="user_id"
                            @touched="onSort"
                        >{{ t('feedback_label_user_id') }}
                        </sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="id"
                            @touched="onSort"
                        >{{ t('feedback_label_id') }}
                        </sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="workout_id"
                            @touched="onSort"
                        >{{ t('feedback_label_workout_id') }}
                        </sort-field>
                    </th>
                    <th>{{ t('feedback_label_creation_date') }}</th>
                    <th>{{ t('feedback_label_feeling') }}</th>
                    <th>{{ t('feedback_label_level') }}</th>
                    <th>{{ t('feedback_label_fit_in_time') }}</th>
                    <th>{{ t('feedback_label_description') }}</th>
                    <th>{{ t('feedback_label_rating') }}</th>
                    <th>{{ t('feedback_label_comment') }}</th>
                    <th class="text-center">{{ t('general_label_action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="11">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="feedback in collection.data">
                    <tr>
                        <td>
                            <a
                                href="javascript:"
                                @click="go({ name: 'trainees.show', params: { id: feedback.userId } })"
                            >{{ feedback.userId }}</a>
                        </td>
                        <td>
                            <a
                                href="javascript:"
                                @click.prevent="touch(feedback)"
                            >{{ feedback.id }}</a>
                        </td>
                        <td>
                            <a
                                href="javascript:"
                                @click="go({ name: 'workout-executions', query: { workout_execution_id: feedback.workoutExecutionId } })"
                            >{{ feedback.workoutId }}</a>
                        </td>
                        <td>
                            {{ feedback.createdAt.format('DD/MM/YYYY HH:mm') }}
                        </td>
                        <td>
                            {{ feedbackFeelingTitle(feedback.feeling) }}
                        </td>
                        <td>
                            {{ feedbackLevelTitle(feedback.level) }}
                        </td>
                        <td>
                            {{ feedbackFitInTimeTitle(feedback.fitInTime) }}
                        </td>
                        <td>
                            {{ feedbackDescriptionTitle(feedback.description) }}
                        </td>
                        <td>
                            {{ feedback.rating }}
                        </td>
                        <td>
                            {{ feedback.comment ? t('general_text_yes') : t('general_text_no') }}
                        </td>
                        <td class="text-center">
                            <v-btn
                                @click="touch(feedback)"
                                icon
                            ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
            <template #export>
                <v-btn
                    @click="_export"
                    class="exs mt-4 ml-4"
                    color="primary"
                    small
                >{{ t('general_action_export') }}</v-btn>
            </template>
        </card-collection>
    </div>
</div>
