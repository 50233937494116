export interface IPayload {
    [key: string]: any
}

/**
 * @param payload
 * @param fillable
 */
export const filter = (payload: IPayload, fillable: string[] = []): IPayload => {
    if (fillable.length > 0) {
        return Object.keys(payload)
            .filter(key => fillable.includes(key))
            .reduce((object: IPayload, key: string) => {
                object[key] = payload[key]
                return object
            }, {})
    } else {
        return payload
    }
}