import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useStatsHttp } from '@/core/api/stats'
import { Stat, StatPayload } from '@/core/models/stat'

const state: Stat = new Stat(<StatPayload>{})

export const stat: Module<Stat, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Stat, payload: StatPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useStatsHttp()
                    .get(payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Stat): Stat => {
            return state
        }
    }
}