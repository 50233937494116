<template>
    <v-dialog
        v-model="touched"
        max-width="480"
        persistent
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="text-center customized-deep-primary--text d-block" v-html="title"></v-card-title>

            <v-card-text class="pt-6 pb-10">
                <slot name="content"></slot>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="agree"
                    width="45%"
                    dark
                >{{ t('general_text_yes') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="refuse"
                    width="45%"
                    dark
                >{{ t('general_text_no') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'

    export default defineComponent({
        name: 'ConfirmModal',

        props: {
            title: {
                type: String,
                required: true
            }
        },

        /**
         * Component constructor.
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                touched: false
            })

            const agree = (): void => {
                context.emit('agree')
                state.touched = false
            }

            const refuse = (): void => {
                context.emit('refuse')
                state.touched = false
            }

            return {
                agree,
                refuse,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
