export interface LanguagePayload {
    id: number
    name: string
    language_code: string
    icon: string
    sort_order: number
}

export class Language {

    id: number
    name: string
    languageCode: string
    icon: string
    sortOrder: number

    constructor(data: LanguagePayload) {
        this.id = data.id
        this.name = data.name
        this.languageCode = data.language_code
        this.icon = data.icon
        this.sortOrder = data.sort_order
    }

    fill(data: LanguagePayload): this {
        this.id = data.id
        this.name = data.name
        this.languageCode = data.language_code
        this.icon = data.icon
        this.sortOrder = data.sort_order
        return this
    }

    payload(): LanguagePayload {
        return {
            id: this.id,
            name: this.name,
            language_code: this.languageCode,
            icon: this.icon,
            sort_order: this.sortOrder,
        }
    }
}