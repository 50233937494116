import { Module } from 'vuex'
import { RootState, Collection } from '../store-types'
import { useStatsHttp } from '@/core/api/stats'
import { StatRank, StatRankPayload } from '@/core/models/stat-rank'

export type StatRankCollection = {
    data: Array<StatRank>
}

const state: StatRankCollection = {
    data: <any>[]
}

export const statRankCollection: Module<StatRankCollection, RootState> = {
    namespaced: true,
    state,
    mutations: {
        set: (state: StatRankCollection, payload: Collection) => {
            state.data = <any>[]
            payload.data.forEach((item: StatRankPayload) => {
                state.data.push(new StatRank(item))
            })
        },
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useStatsHttp()
                    .ranks(payload)
                    .then((data: any) => {
                        context.commit('set', data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        get: (state: StatRankCollection): StatRankCollection => {
            return state
        }
    }
}