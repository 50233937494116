import moment, { Moment } from 'moment/moment'
import { Role, RolePayload } from '@/core/models/role'
import { User, UserPayload } from '@/core/models/user'

export interface NotificationPayload {
    id: number
    role_id: number
    title: string
    message: string
    photo_url: string
    cta: boolean
    cta_text: string
    cta_params: { [key: string]: string }[] | null
    send_at: string
    force: boolean
    status: string
    created_at: string
    role?: RolePayload
    users: UserPayload[]
    translations?: {}
}

export class Notification {

    id: number
    roleId: number
    title: string
    message: string
    photo?: File
    photoUrl: string
    cta: boolean
    ctaText: string
    ctaParams: { [key: string]: string }[] | null
    ctaParamsStr: string
    sendAt: Moment
    force: boolean
    status: string
    createdAt: Moment
    role: Role
    users: User[] = <User[]>[]
    translations?: {} = <any>{}

    constructor(data: NotificationPayload) {
        this.id = data.id
        this.roleId = data.role_id
        this.title = data.title
        this.message = data.message
        this.photoUrl = data.photo_url
        this.cta = data.cta
        this.ctaText = data.cta_text
        this.ctaParams = data.cta_params
        this.ctaParamsStr = this.ctaParams ? JSON.stringify(this.ctaParams) : ''
        this.sendAt = moment(data.send_at)
        this.force = data.force
        this.status = data.status
        this.createdAt = moment(data.created_at)
        this.role = new Role(data.role || <RolePayload>{})
        this.capture(data)
    }

    fill(data: NotificationPayload): this {
        this.id = data.id
        this.roleId = data.role_id
        this.title = data.title
        this.message = data.message
        this.photoUrl = data.photo_url
        this.cta = data.cta
        this.ctaText = data.cta_text
        this.ctaParams = data.cta_params
        this.ctaParamsStr = this.ctaParams ? JSON.stringify(this.ctaParams) : ''
        this.sendAt = moment(data.send_at)
        this.force = data.force
        this.status = data.status
        this.createdAt = moment(data.created_at)
        this.role = new Role(data.role || <RolePayload>{})
        this.capture(data)
        return this
    }

    capture(data: NotificationPayload): this {
        if (data.users) {
            this.users = <User[]>[]
            data.users.map(item => this.users.push(new User(item)))
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.photo = undefined
        this.users = <any>[]
        this.translations = {}
        return this
    }

    __users(): UserPayload[] {
        const users = <UserPayload[]>[]
        this.users.map(item => users.push(<UserPayload>item.payload()))
        return users
    }

    payload(): NotificationPayload | any {
        try {
            this.ctaParams = JSON.parse(this.ctaParamsStr)
        } catch (e) {
            this.ctaParams = null
        }
        return {
            id: this.id,
            role_id: this.roleId,
            title: this.title,
            message: this.message,
            cta: this.cta,
            cta_text: this.ctaText,
            cta_params: this.ctaParams,
            send_at: this.sendAt.toISOString(),
            force: this.force,
            status: this.status,
            users: this.__users(),
            translations: this.translations,
        }
    }
}
