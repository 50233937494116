import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useComplaintHttp } from '@/core/api/complaints'
import { Complaint, ComplaintPayload } from '@/core/models/complaint'

const state: Complaint = new Complaint(<ComplaintPayload>{})

export const complaint: Module<Complaint, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: Complaint, payload: ComplaintPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useComplaintHttp()
                    .find(payload.id, payload.payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        update: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useComplaintHttp()
                    .update(payload.id, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
        photo: <T>(context: any, payload: { id: number, payload: any }): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useComplaintHttp()
                    .photo(payload.id, payload.payload)
                    .then((data: any) => {
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: Complaint): Complaint => {
            return state
        },
    }
}