
<v-row class="fill-height ma-0">
    <v-col cols="6" class="pa-0">
        <v-container fluid fill-height customized-deep-primary>
            <v-flex
                text-center
                align-center
                justify-center
            >
                <a href="/" class="d-inline-block">
                    <img
                        src="/images/logo.svg"
                        width="158"
                        height="37"
                    />
                </a>
            </v-flex>
        </v-container>
    </v-col>
    <v-col cols="6">
        <div style="position: absolute; right: 12px;">
            <language-switcher></language-switcher>
        </div>

        <v-container fluid fill-height>
            <v-flex
                align-center
                justify-center
                pa-12
            >
                <router-view />
            </v-flex>
        </v-container>
    </v-col>
</v-row>
