import moment, { Moment } from 'moment/moment'
import { User, UserPayload } from '@/core/models/user'

export interface UserCommentPayload {
    id: number
    user_id: number
    recipient_id: number
    comment: string
    created_at: string
    user?: UserPayload
    recipient?: UserPayload
}

export class UserComment {

    id: number
    userId: number
    recipientId: number
    comment: string
    createdAt: Moment
    user: User
    recipient: User

    constructor(data: UserCommentPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.recipientId = data.recipient_id
        this.comment = data.comment
        this.createdAt = moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
        this.recipient = new User(data.recipient || <UserPayload>{})
    }

    fill(data: UserCommentPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.recipientId = data.recipient_id
        this.comment = data.comment
        this.createdAt = moment(data.created_at)
        this.user = new User(data.user || <UserPayload>{})
        this.recipient = new User(data.recipient || <UserPayload>{})
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
    }

    payload(): UserCommentPayload | any {
        return {
            id: this.id,
            user_id: this.userId,
            recipient_id: this.recipientId,
            comment: this.comment,
        }
    }
}
