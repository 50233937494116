<template>
    <v-flex>
        <card-collection
            :class="{ 'px-0-nested': !! user }"
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-7 mb-n3"
                    height="56"
                >
                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md mr-2"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                    <date-picker-modal
                        :value="queries.date_from"
                        @updated="(v) => queries.date_from = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_from"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_from')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <span class="sub-label px-2">-</span>

                    <date-picker-modal
                        :value="queries.date_to"
                        @updated="(v) => queries.date_to = v"
                    >
                        <template #default="{ on, attrs }">
                            <div
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-text-field
                                    v-model="queries.date_to"
                                    class="xs input-color-inherit"
                                    outlined
                                    dense
                                    hide-details
                                    readonly
                                    :label="t('general_label_to')"
                                ></v-text-field>
                            </div>
                        </template>
                    </date-picker-modal>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="filters.swimming_location"
                        :items="[ { text: '-', value: '' } ].concat(locations())"
                        class="md mx-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('race_execution_label_swimming_location')"
                    ></v-select>

                    <v-spacer></v-spacer>

                    <v-select
                        v-model="filters.stroke_id"
                        :items="strokes"
                        class="md mr-2"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('race_execution_label_stroke')"
                    ></v-select>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}
                    </v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>

                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="id"
                            @touched="onSort"
                        >{{ t('race_execution_label_id') }}</sort-field>
                    </th>
                    <th class="touched">
                        <sort-field
                            v-model="queries.sort"
                            field="name"
                            @touched="onSort"
                        >{{ t('race_execution_label_name') }}</sort-field>
                    </th>
                    <th>{{ t('race_execution_label_swimming_location') }}</th>
                    <th>{{ t('race_execution_label_stroke') }}</th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="distance"
                            @touched="onSort"
                        >{{ t('race_execution_label_distance') }}</sort-field>
                    </th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="duration"
                            @touched="onSort"
                        >{{ t('race_execution_label_duration') }}</sort-field>
                    </th>
                    <th>{{ t('race_execution_label_avg_pace') }}</th>
                    <th>
                        <sort-field
                            v-model="queries.sort"
                            field="raced_at"
                            @touched="onSort"
                        >{{ t('race_execution_label_raced_at') }}</sort-field>
                    </th>
                    <th v-if="! user">{{ t('race_execution_label_user_name') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="9">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="execution in collection.data">
                    <tr>
                        <td>{{ execution.id }}</td>
                        <td>{{ execution.name }}</td>
                        <td>{{ locationTitle(execution.swimmingLocation) }}</td>
                        <td>{{ execution.stroke.name }}</td>
                        <td>{{ execution.distance }} m</td>
                        <td>{{ time.format(execution.duration) }}</td>
                        <td>{{ time.format(execution.avgPace) }}</td>
                        <td>{{ execution.racedAt.format('DD/MM/YYYY HH:mm') }}</td>
                        <td v-if="! user" class="touched">
                            <a
                                href="javascript:"
                                @click.prevent="viewUser(execution.user)"
                            >{{ execution.user.fullName }}</a>
                        </td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useRouter } from '@/composition-api'
    import { useStore } from '@/store'
    import { useEnums } from '@/core/defaults'
    import { RaceExecutionCollection } from '@/store/modules/race-execution-collection'
    import DatePickerModal from '@/components/DatePickerModal.vue'
    import CardCollection from '@/components/CardCollection.vue'
    import SortField from '@/components/SortField.vue'
    import moment from 'moment/moment'
    import * as time from '@/core/utils/time-helper'
    import { Stroke } from '@/core/models/stroke'
    import { User } from '@/core/models/user'

    export default defineComponent({
        name: 'RaceExecutionCollection',

        components: {
            DatePickerModal,
            CardCollection,
            SortField,
        },

        props: {
            user: {
                type: User,
                required: false
            },
        },

        /**
         * @param props
         * @param context
         */
        setup(props, context) {
            const state = reactive({
                overlay: true,
                meta: undefined,
            })

            const filters = reactive({
                swimming_location: undefined,
                stroke_id: undefined,
            })

            const queries = reactive({
                search: undefined,
                page: 1,
                per_page: 20,
                sort: '-id',
                relations: 'user,stroke',
                user_id: props.user?.id,
                date_from: undefined,
                date_to: undefined,
                filters: <any>{}
            })

            const reset = () => {
                filters.swimming_location = undefined
                filters.stroke_id = undefined
                queries.search = undefined
                queries.date_from = undefined
                queries.date_to = undefined
                queries.filters = <any>{}
                capture()
            }

            const store = useStore()
            const router = useRouter()

            const collection = computed(() => <RaceExecutionCollection>store.getters['raceExecutionCollection/get'])
            const strokes = computed(() => [ { text: '-', value: '' } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id.toString() }
                })
            ))

            const updated = (updatedQueries: any) => {
                queries.page = updatedQueries.page
                capture()
            }

            const viewUser = (user: User) => {
                router.push({ name: 'trainees.show', params: <any>{ id: user.id }, query: { tab: '4' } })
            }

            const capture = () => {
                state.overlay = true
                queries.filters = filters
                store.dispatch('raceExecutionCollection/get', queries).then((data) => {
                    state.meta = data.meta
                    state.overlay = false
                })
            }

            const search = () => {
                capture()
            }

            const clear = () => {
                queries.search = undefined
                capture()
            }

            const onSort = (value: any) => {
                if (value === undefined) {
                    value = '-id'
                }
                queries.sort = value
            }

            watch(() => [
                queries.sort,
                filters.swimming_location,
                filters.stroke_id,
            ], () => {
                capture()
            })

            onMounted(() => {
                collection.value.data = []
                store.dispatch('strokeCollection/get', { sort: 'sort_order' })
                capture()
            })

            return {
                time,
                filters,
                queries,
                collection: collection.value,
                strokes,
                moment,
                updated,
                onSort,
                search,
                clear,
                reset,
                viewUser,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>