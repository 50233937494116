export const format = (distance?: number): string => {
    let result = ''
    if (distance) {
        if (distance > 1000) {
            distance = distance / 1000
            if (distance < 100) {
                result = distance.toFixed(2) + ' km'
            } else if (distance < 1000) {
                result = distance.toFixed(1) + ' km'
            } else {
                result = Math.round(distance) + ' km'
            }
        } else {
            result = distance + ' m'
        }
    }
    return result || '0'
}