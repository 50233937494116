import { Module } from 'vuex'
import { RootState } from '../store-types'
import { useStatsHttp } from '@/core/api/stats'
import { StatTotal, StatTotalPayload } from '@/core/models/stat-total'

const state: StatTotal = new StatTotal(<StatTotalPayload>{})

export const statTotal: Module<StatTotal, RootState> = {
    namespaced: true,
    state,
    mutations: {
        update: (state: StatTotal, payload: StatTotalPayload) => {
            state.fill(payload)
        }
    },
    actions: {
        get: <T>(context: any, payload: any): Promise<T> => {
            state.unset()
            return new Promise<T>((resolve, reject) => {
                useStatsHttp()
                    .totals(payload)
                    .then((data: any) => {
                        context.commit('update', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        },
    },
    getters: {
        get: (state: StatTotal): StatTotal => {
            return state
        }
    }
}