import { useHttp } from '../http'

export const useLazyTranslationsHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`lazy-translations`, payload)
    }

    const postImport = <T>(replace: boolean): Promise<T> => {
        return useHttp().post(`lazy-translations/import`, { replace: replace })
    }

    const postExport = <T>(group?: string): Promise<T> => {
        return useHttp().post(`lazy-translations/export`, { group: group })
    }

    const find = <T>(): Promise<T> => {
        return useHttp().post(`lazy-translations/find`)
    }

    return {
        get,
        postImport,
        postExport,
        find
    }
}