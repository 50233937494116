
<div>
    <app-bar :title="badge.name"/>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(badge)"
                >
                    {{ badge.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <confirm-modal
                    :title="t('general_title_confirm_action')"
                    @agree="del(badge)"
                >
                    <template #default="{ on, attrs }">
                        <v-btn
                            class="sm"
                            color="orange"
                            outlined
                            v-on="on"
                            v-bind="attrs"
                        >{{ t('general_action_delete') }}</v-btn>
                    </template>
                </confirm-modal>
            </div>
            <div class="float-left mr-10" style="width: 394px; max-width: 394px">
                <img
                    v-if="badge.photoUrl"
                    :src="badge.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('badge_label_description') }}:</div>
                <div class="form-subtitle-description" v-html="badge.description"></div>
                <div class="form-subtitle">{{ t('badge_label_motto') }}:</div>
                <div class="form-subtitle-description" v-html="badge.motto"></div>
            </div>
            <div class="float-left" style="max-width: calc(100% - 434px)">
                <card-unit :items="[
                    { text: t('badge_label_section'), value: badge.section.name },
                    { text: t('general_label_creation_date'), value: badge.createdAt.format('DD/MM/YYYY') },
                    { text: t('badge_label_trigger'), value: badgeTriggerTitle(badge.trigger) },
                    { text: t('badge_label_stat'), value: badgeStatTitle(badge.stat) },
                    { text: t('badge_label_sort_order'), value: badge.sortOrder },
                    { text: t('badge_label_published'), value: badge.published ? t('general_text_yes') : t('general_text_no') },
                    { text: t('badge_label_events'), value: badge.events.length ? badge.events.map(v => {
                        return { value: v.name, route: { name: 'events.view', params: { id: v.id } } }
                    }) : '-' }
                ]">
                    <template #title>{{ t('badge_label_id') }}: {{ badge.id }}</template>
                </card-unit>
            </div>
        </div>
    </v-layout>
</div>
