export interface IFile {
    name: string
    path: string
    size: string
    extension: string
}

export interface IDirectory {
    name: string
    path: string
    size: string
}

export interface FilerPayload {
    disk: string
    disk_url: string
    previous: string
    files: IFile[]
    directories: IDirectory[]
    summary_size: string
}

export class Filer {

    disk: string
    diskUrl: string
    previous: string
    files: IFile[]
    directories: IDirectory[]
    summarySize: string

    constructor(data: FilerPayload) {
        this.disk = data.disk
        this.diskUrl = data.disk_url
        this.previous = data.previous
        this.files = data.files
        this.directories = data.directories
        this.summarySize = data.summary_size
    }

    fill(data: FilerPayload): this {
        this.disk = data.disk
        this.diskUrl = data.disk_url
        this.previous = data.previous
        this.files = data.files
        this.directories = data.directories
        this.summarySize = data.summary_size
        return this
    }
}