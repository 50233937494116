<template>
    <v-dialog
        v-model="touched"
        max-width="92%"
        persistent
        scrollable
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card
            class="filer"
            :loading="overlay"
        >
            <v-card-title>
                {{ '/' + (path === '/' ? '' : path) }}
                <v-spacer></v-spacer>
                <v-btn
                    @click="returnDirectory(filer.previous)"
                    plain
                    icon
                    class="mr-2"
                    :disabled="! filer.previous || overlay"
                ><v-icon color="customized-primary">mdi-keyboard-backspace</v-icon></v-btn>
                <v-btn
                    @click="refresh"
                    plain
                    icon
                    class="mr-2"
                    :disabled="overlay"
                ><v-icon color="customized-primary">mdi-refresh</v-icon></v-btn>
                <confirm-modal
                    :title="t('file_manager_action_create_directory')"
                    @agree="createDirectory"
                    @refuse="updateProps"
                >
                    <template #default="{ on, attrs }">
                        <v-btn
                            plain
                            icon
                            class="mr-2"
                            :disabled="overlay"
                            v-on="on"
                            v-bind="attrs"
                        ><v-icon color="customized-primary">mdi-plus</v-icon></v-btn>
                    </template>
                    <template #content>
                        <v-text-field
                            v-model="newDirectory"
                            :label="t('file_manager_label_name')"
                            type="text"
                            required
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </template>
                </confirm-modal>
                <v-btn
                    @click="touched = false"
                    plain
                    icon
                ><v-icon color="customized-primary">mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
                <v-simple-table class="transparent">
                    <template v-slot:default>
                        <tbody>
                        <tr v-for="directory in filer.directories">
                            <td>
                                <a
                                    href="javascript:"
                                    @click="openDirectory(directory.path)"
                                >
                                    <v-icon size="18" left>mdi-folder</v-icon>{{ directory.name }}
                                </a>
                            </td>
                            <td><v-icon size="18" left class="ml-2">mdi-code-tags</v-icon>rwxr-x---</td>
                            <td style="width: 100px">{{ directory.size }}</td>
                            <td style="width: 60px" class="text-right">
                                <v-menu
                                    :key="`menu_${directory.path}`"
                                    offset-y
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <confirm-modal
                                            :key="`update_${directory.path}`"
                                            :title="t('file_manager_action_edit_directory')"
                                            @agree="update(directory.path)"
                                            @refuse="updateProps"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    link
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_edit') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                            <template #content>
                                                <v-text-field
                                                    :value="directory.path"
                                                    :label="t('file_manager_label_name')"
                                                    required
                                                    outlined
                                                    dense
                                                    hide-details
                                                    class="mb-8"
                                                    disabled
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="changer"
                                                    :label="t('file_manager_label_new_name')"
                                                    required
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                        </confirm-modal>
                                        <confirm-modal
                                            :key="`delete_${directory.path}`"
                                            :title="t('file_manager_label_confirm_delete')"
                                            @agree="del(directory.path)"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    link
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title class="red--text">{{ t('general_action_delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </confirm-modal>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        <tr v-for="file in filer.files">
                            <td>
                                <v-icon
                                    size="18"
                                    left
                                >{{ icons[file.extension] || 'mdi-file-document-outline' }}</v-icon>{{ file.name }}
                            </td>
                            <td>
                                <v-btn
                                    @click="fire(file.path)"
                                    icon
                                ><v-icon size="16" :color="fired === file.path ? 'orange' : ''">mdi-content-copy</v-icon></v-btn>{{ fullPath(file.path) }}
                            </td>
                            <td>{{ file.size }}</td>
                            <td class="text-right">
                                <v-menu
                                    :key="`menu_${file.path}`"
                                    offset-y
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item
                                            link
                                            @click="viewFile(file.path)"
                                        >
                                            <v-list-item-title
                                                class="customized-deep-primary--text"
                                            >{{ t('file_manager_action_open') }}</v-list-item-title>
                                        </v-list-item>
                                        <confirm-modal
                                            :key="`update_${file.path}`"
                                            :title="t('file_manager_action_edit_file')"
                                            @agree="update(file.path)"
                                            @refuse="updateProps"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    link
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_edit') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                            <template #content>
                                                <v-text-field
                                                    :value="file.path"
                                                    :label="t('file_manager_label_name')"
                                                    required
                                                    outlined
                                                    dense
                                                    hide-details
                                                    class="mb-8"
                                                    disabled
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="changer"
                                                    :label="t('file_manager_label_new_name')"
                                                    required
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                        </confirm-modal>
                                        <confirm-modal
                                            :key="`delete_${file.path}`"
                                            :title="t('file_manager_label_confirm_delete')"
                                            @agree="del(file.path)"
                                        >
                                            <template #default="{ on, attrs }">
                                                <v-list-item
                                                    link
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    <v-list-item-title class="red--text">{{ t('general_action_delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </confirm-modal>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <v-card-actions>
                <v-file-input
                    v-model="photos"
                    class="md extra-dense"
                    required
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    hide-details
                    dense
                    multiple
                    :placeholder="t('file_manager_label_browse_files')"
                    @click:clear="clear"
                ></v-file-input>
                <v-btn
                    @click="upload"
                    class="exs ml-2"
                    color="primary"
                    outlined
                    small
                    :disabled="overlay || ! photos.length"
                >{{ t('file_manager_action_upload') }}</v-btn>
                <span class="form-error pl-2" v-if="form.errors.has('files.0')">{{ form.errors.get('files.0') }}</span>
                <span class="form-error pl-2" v-if="form.errors.has('new_path')">{{ form.errors.get('new_path') }}</span>
                <v-spacer></v-spacer>
                <strong>{{ t('file_manager_label_summary_size') }}: {{ filer.summarySize }}</strong>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { useI18n } from 'vue-i18n-composable'
    import { Filer } from '@/core/models/filer'
    import ConfirmModal from '@/components/ConfirmModal.vue'

    export default defineComponent({
        name: 'Filer',

        components: {
            ConfirmModal
        },

        /**
         * Component props.
         */
        props: {
            value: String,
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()

            const state = reactive({
                fired: <string | undefined>undefined,
                touched: false,
                newDirectory: <string | undefined>undefined,
                changer: <string | undefined>undefined,
                photos: <File[]>[],
                path: '/',
                overlay: true,
                icons: {
                    zip: 'mdi-folder-zip-outline',
                    rar: 'mdi-folder-zip-outline',
                    htm: 'mdi-language-html5',
                    html: 'mdi-language-html5',
                    js: 'mdi-nodejs',
                    json: 'mdi-nodejs',
                    md: 'mdi-markdown',
                    pdf: 'mdi-file-document-outline',
                    png: 'mdi-file-image',
                    jpg: 'mdi-file-image',
                    jpeg: 'mdi-file-image',
                    svg: 'mdi-file-image',
                    mp4: 'mdi-filmstrip',
                    mkv: 'mdi-filmstrip',
                    avi: 'mdi-filmstrip',
                    wmv: 'mdi-filmstrip',
                    mov: 'mdi-filmstrip',
                    txt: 'mdi-file-document-outline',
                    xls: 'mdi-file-excel',
                    word: 'mdi-file-word-outline',
                    ppt: 'mdi-file-powerpoint-outline',
                    csv: 'mdi-file-delimited-outline',
                }
            })

            const filer = computed(() => <Filer>store.getters['filer/get'])

            const form = reactive(useForm())

            const fullPath = (path: string) => {
                return '//' + path
            }

            const fire = (fire: string) => {
                navigator.clipboard.writeText(filer.value.diskUrl + '/' + fire)
                state.fired = fire
            }

            const openDirectory = (path: string): void => {
                state.overlay = true
                state.path = path
                capture()
            }

            const returnDirectory = (path: string): void => {
                state.overlay = true
                state.path = path
                capture()
            }

            const createDirectory = (): void => {
                if (state.newDirectory) {
                    state.overlay = true
                    store.dispatch('filer/createDirectory', { path: state.newDirectory }).then(() => {
                        updateProps()
                        capture()
                    })
                }
            }

            const update = (path: string): void => {
                if (state.changer) {
                    state.overlay = true
                    form.usingSubmit(store.dispatch('filer/update', { path: path, payload: { new_path: state.changer } }))
                        .then(() => {
                            updateProps()
                            capture()
                        })
                        .finally(() => {
                            state.overlay = false
                        })
                }
            }

            const upload = (): void => {
                state.overlay = true
                let data = new FormData()
                state.photos.map(item => data.append('files[]', item))
                form.usingSubmit(store.dispatch('filer/create', { path: state.path, payload: data }))
                    .then(() => {
                        capture()
                    })
                    .finally(() => {
                        state.overlay = false
                    })
            }

            const del = (path: string): void => {
                store.dispatch('filer/delete', { path: path }).finally(() => {
                    capture()
                })
            }

            const clear = (): void => {
                state.photos = []
                form.errors.clear()
            }

            const refresh = (): void => {
                state.overlay = true
                form.errors.clear()
                capture()
            }

            const viewFile = (path: string): void => {
                window.open(fullPath(path))
            }

            const capture = () => {
                store.dispatch('filer/get', { path: state.path }).then(() => {
                    state.overlay = false
                })
            }

            const updateProps = (): void => {
                let path = state.path === '/' ? '' : state.path + '/'
                state.newDirectory = path
                state.changer = path
            }

            watch(() => state.path, (newValue) => {
                updateProps()
            })

            onMounted(() => {
                capture()
            })

            return {
                form,
                filer,
                fullPath,
                updateProps,
                fire,
                openDirectory,
                returnDirectory,
                createDirectory,
                update,
                upload,
                del,
                clear,
                refresh,
                viewFile,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
