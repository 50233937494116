export const format = (time?: string, cut: boolean = false): string => {
    let result = ''
    if (time) {
        const args = time.split(':')
        let hours = false
        if (args[0] !== '00') {
            result = result + `${args[0]} h `
            hours = true
        }
        if (args[1] !== '00') {
            result = result + `${args[1]} min `
        }
        const part = args[2].split('.')
        if (part[0] !== '00' && (! cut || ! hours)) {
            result = result + `${part[0]} s`
        }
    }
    return result || '-'
}