import { useHttp } from '../http'

export const useTrainingProgramExecutionHttp = () => {

    const find = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().get(`training-program-executions/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`training-program-executions`, payload)
    }

    const del = <T>(id: number): Promise<T> => {
        return useHttp().del(`training-program-executions/${id}`)
    }

    return {
        find,
        get,
        del,
    }
}