
<v-toolbar
    :class="{ dense: dense }"
    class="stat"
    max-height="68"
    max-width="202"
>
    <div class="stat-name" v-html="name"></div>
    <v-spacer></v-spacer>
    <div class="stat-count" v-text="count"></div>
</v-toolbar>
