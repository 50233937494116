import { Workout, WorkoutPayload } from '@/core/models/workout'
import { TrainingProgram, TrainingProgramPayload } from '@/core/models/training-program'

export interface WorkoutSchedulePayload {
    id: number
    workout_id: number
    training_program_id: number
    workout_order_number: number
    week_number: number
    workout?: WorkoutPayload
    training_program?: TrainingProgramPayload
}

export class WorkoutSchedule {

    id: number
    workoutId: number
    trainingProgramId: number
    workoutOrderNumber: number
    weekNumber: number
    workout: Workout
    trainingProgram: TrainingProgram

    constructor(data: WorkoutSchedulePayload) {
        this.id = data.id
        this.workoutId = data.workout_id
        this.trainingProgramId = data.training_program_id
        this.workoutOrderNumber = data.workout_order_number
        this.weekNumber = data.week_number
        this.workout = new Workout(data.workout || <WorkoutPayload>{})
        this.trainingProgram = new TrainingProgram(data.training_program || <TrainingProgramPayload>{})
    }

    fill(data: WorkoutSchedulePayload): this {
        this.id = data.id
        this.workoutId = data.workout_id
        this.trainingProgramId = data.training_program_id
        this.workoutOrderNumber = data.workout_order_number
        this.weekNumber = data.week_number
        this.workout = new Workout(data.workout || <WorkoutPayload>{})
        this.trainingProgram = new TrainingProgram(data.training_program || <TrainingProgramPayload>{})
        return this
    }

    payload(): WorkoutSchedulePayload {
        return {
            id: this.id,
            workout_id: this.workoutId,
            training_program_id: this.trainingProgramId,
            workout_order_number: this.workoutOrderNumber,
            week_number: this.weekNumber,
        }
    }
}
