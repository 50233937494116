import moment, { Moment } from 'moment/moment'
import { GlossarySection, GlossarySectionPayload } from '@/core/models/glossary-section'
import { Stroke, StrokePayload } from '@/core/models/stroke'

export interface GlossaryDefinitionPayload {
    id: number
    glossary_section_id: number
    goal_id: number
    name: string
    content: string
    goal: string
    video: string
    published?: boolean
    sort_order: number
    created_at: string
    section?: GlossarySectionPayload
    strokes?: StrokePayload[]
    translations?: {}
}

export class GlossaryDefinition {

    id: number
    glossarySectionId: number
    goalId: number
    name: string
    content: string
    goal: string
    video: string
    published: boolean
    sortOrder: number
    createdAt: Moment
    section: GlossarySection
    strokes: Stroke[] = <Stroke[]>[]
    strokeIds: number[] = []
    translations?: {} = <any>{}

    constructor(data: GlossaryDefinitionPayload) {
        this.id = data.id
        this.glossarySectionId = data.glossary_section_id
        this.name = data.name
        this.content = data.content
        this.goal = data.goal
        this.video = data.video
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.section = new GlossarySection(data.section || <any>{})
        this.capture(data)
    }

    fill(data: GlossaryDefinitionPayload): this {
        this.id = data.id
        this.glossarySectionId = data.glossary_section_id
        this.goalId = data.goal_id
        this.name = data.name
        this.content = data.content
        this.goal = data.goal
        this.video = data.video
        this.published = !! data.published
        this.sortOrder = data.sort_order
        this.createdAt = moment(data.created_at)
        this.section = new GlossarySection(data.section || <any>{})
        this.capture(data)
        return this
    }

    capture(data: GlossaryDefinitionPayload): this {
        if (data.strokes) {
            this.strokes = <Stroke[]>[]
            this.strokeIds = <number[]>[]
            data.strokes.map(item => {
                this.strokes.push(new Stroke(item))
                this.strokeIds.push(item.id)
            })
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    public get strokesStrTitle(): string {
        return this.strokes.map(i => i.name).join(' / ')
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
        this.strokes = <Stroke[]>[]
        this.translations = {}
    }

    payload(): GlossaryDefinitionPayload | any {
        return {
            id: this.id,
            glossary_section_id: this.glossarySectionId,
            name: this.name,
            content: this.content,
            goal: this.goal,
            video: this.video,
            published: this.published,
            sort_order: this.sortOrder,
            strokes: this.strokeIds,
            translations: this.translations,
        }
    }
}
