export interface PagePayload {
    id: number
    name: string
    slug: string
    excerpt: string
    content: string
    published: boolean
    sort_order: number
    translations?: {}
}

export class Page {

    id: number
    name: string
    slug: string
    excerpt: string
    content: string
    published: boolean
    sortOrder: number
    translations?: {} = <any>{}

    constructor(data: PagePayload) {
        this.id = data.id
        this.name = data.name
        this.slug = data.slug
        this.excerpt = data.excerpt
        this.content = data.content
        this.published = data.published
        this.sortOrder = data.sort_order
        this.capture(data)
    }

    fill(data: PagePayload): this {
        this.id = data.id
        this.name = data.name
        this.slug = data.slug
        this.excerpt = data.excerpt
        this.content = data.content
        this.published = data.published
        this.sortOrder = data.sort_order
        this.capture(data)
        return this
    }

    capture(data: PagePayload): this {
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<any>{})
        this.translations = {}
        return this
    }

    payload(): PagePayload {
        return {
            id: this.id,
            name: this.name,
            slug: this.slug,
            excerpt: this.excerpt,
            content: this.content,
            published: this.published,
            sort_order: this.sortOrder,
            translations: this.translations,
        }
    }
}
