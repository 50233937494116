import { Module } from 'vuex'
import { RootState } from '@/store/store-types'
import { useDefaultHttp } from '@/core/api/defaults'
import { Language, LanguagePayload } from '@/core/models/language'
import { useLocalizer } from "@/core/localizer";

export interface ITranslations {
    [key: string]: string
}

export interface IEnums {
    [key: string]: {
        [key: string]: string
    }
}

export interface DefaultsPayload {
    languages: LanguagePayload[],
    translations: ITranslations,
    enums: IEnums,
    preferred_locale: string
}

export type Defaults = {
    languages: Language[],
    translations: ITranslations,
    enums: IEnums,
    preferredLocale: string
}

const state: Defaults = {
    languages: [],
    translations: {},
    enums: {},
    preferredLocale: ''
}

export const defaults: Module<Defaults, RootState> = {
    state,
    mutations: {
        setDefaults: (state: Defaults, payload: DefaultsPayload) => {
            state.languages = []
            payload.languages.forEach((item: LanguagePayload) => {
                state.languages.push(new Language(item))
            })
            state.translations = payload.translations
            state.enums = payload.enums
            state.preferredLocale = payload.preferred_locale

            useLocalizer().set(state.preferredLocale)
        },
    },
    actions: {
        getDefaults: <T>(context: any): Promise<T> => {
            return new Promise<T>((resolve, reject) => {
                useDefaultHttp()
                    .defaults()
                    .then((data: any) => {
                        context.commit('setDefaults', data.data)
                        resolve(data as T)
                    })
                    .catch((response: any) => {
                        reject(response as T)
                    })
            })
        }
    },
    getters: {
        defaults: (state: Defaults): Defaults => {
            return state
        },
        languages: (state: Defaults): Language[] => {
            return state.languages
        },
        translations: (state: Defaults): ITranslations => {
            return state.translations
        },
        enums: (state: Defaults): IEnums => {
            return state.enums
        },
        preferredLocale: (state: Defaults): string => {
            return state.preferredLocale
        },
    }
}