import moment, { Moment } from 'moment/moment'
import { Event, EventPayload } from '@/core/models/event'

export interface EventSchedulePayload {
    id: number
    event_id: number
    start_at: string
    end_at: string
    notification_sent_at?: string
    event?: EventPayload
}

export class EventSchedule {

    id: number
    eventId: number
    startAt: Moment
    endAt: Moment
    notificationSentAt?: Moment

    constructor(data: EventSchedulePayload) {
        this.id = data.id
        this.eventId = data.event_id
        this.startAt = moment(data.start_at)
        this.endAt = moment(data.end_at)
        this.notificationSentAt = data.notification_sent_at ? moment(data.notification_sent_at) : undefined
    }

    fill(data: EventSchedulePayload): this {
        this.id = data.id
        this.eventId = data.event_id
        this.startAt = moment(data.start_at)
        this.endAt = moment(data.end_at)
        this.notificationSentAt = data.notification_sent_at ? moment(data.notification_sent_at) : undefined
        return this
    }

    exists(): boolean {
        return !! this.id
    }

    payload(): EventSchedulePayload {
        return {
            id: this.id,
            event_id: this.eventId,
            start_at: this.startAt.toISOString(),
            end_at: this.endAt.toISOString(),
        }
    }
}
