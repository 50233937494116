export interface GoalPayload {
    id: number
    name: string
    sort_order: number
    enabled: boolean
}

export class Goal {

    id: number
    name: string
    sortOrder: number
    enabled: boolean

    constructor(data: GoalPayload) {
        this.id = data.id
        this.name = data.name
        this.sortOrder = data.sort_order
        this.enabled = data.enabled
    }

    fill(data: GoalPayload): this {
        this.id = data.id
        this.name = data.name
        this.sortOrder = data.sort_order
        this.enabled = data.enabled
        return this
    }

    payload(): GoalPayload {
        return {
            id: this.id,
            name: this.name,
            sort_order: this.sortOrder,
            enabled: this.enabled,
        }
    }
}