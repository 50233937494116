const CURRENT_LOCALE_KEY = 'current_locale'

export const useLocalizer = () => {

    const set = (locale: string): void => {
        window.localStorage.setItem(CURRENT_LOCALE_KEY, locale)
    }

    const get = (): string => {
        return window.localStorage.getItem(CURRENT_LOCALE_KEY) || ''
    }

    return {
        set,
        get,
    }
}

export const preferredLocale = () => {
    return useLocalizer().get()
}
