import { NavigationGuardNext, Route } from 'vue-router/types/router'
import { useAuth } from '@/plugins/auth'
import { useStore } from '@/store'

const permissionsGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
    let requiredPermissions = <string[]>[]

    const auth = useAuth()

    if (to.meta?.permissions) {
        to.meta.permissions.map((permission: string) => {
            if (auth.user().notPermitted(permission)) {
                requiredPermissions.push(permission)
            }
        })
    }

    if (auth.signed() && requiredPermissions.length) {
        const store = useStore()
        store.commit('notify', { color: 'error', message: `Permissions required: ${ requiredPermissions.join(', ') }` })

        next({ name: '403' })
    } else {
        next()
    }
}

export default permissionsGuard
