
<v-dialog
    v-model="touched"
    max-width="92%"
    persistent
    scrollable
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card
        class="filer"
        :loading="overlay"
    >
        <v-card-title>
            {{ '/' + (path === '/' ? '' : path) }}
            <v-spacer></v-spacer>
            <v-btn
                @click="returnDirectory(filer.previous)"
                plain
                icon
                class="mr-2"
                :disabled="! filer.previous || overlay"
            ><v-icon color="customized-primary">mdi-keyboard-backspace</v-icon></v-btn>
            <v-btn
                @click="refresh"
                plain
                icon
                class="mr-2"
                :disabled="overlay"
            ><v-icon color="customized-primary">mdi-refresh</v-icon></v-btn>
            <confirm-modal
                :title="t('file_manager_action_create_directory')"
                @agree="createDirectory"
                @refuse="updateProps"
            >
                <template #default="{ on, attrs }">
                    <v-btn
                        plain
                        icon
                        class="mr-2"
                        :disabled="overlay"
                        v-on="on"
                        v-bind="attrs"
                    ><v-icon color="customized-primary">mdi-plus</v-icon></v-btn>
                </template>
                <template #content>
                    <v-text-field
                        v-model="newDirectory"
                        :label="t('file_manager_label_name')"
                        type="text"
                        required
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                </template>
            </confirm-modal>
            <v-btn
                @click="touched = false"
                plain
                icon
            ><v-icon color="customized-primary">mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
            <v-simple-table class="transparent">
                <template v-slot:default>
                    <tbody>
                    <tr v-for="directory in filer.directories">
                        <td>
                            <a
                                href="javascript:"
                                @click="openDirectory(directory.path)"
                            >
                                <v-icon size="18" left>mdi-folder</v-icon>{{ directory.name }}
                            </a>
                        </td>
                        <td><v-icon size="18" left class="ml-2">mdi-code-tags</v-icon>rwxr-x---</td>
                        <td style="width: 100px">{{ directory.size }}</td>
                        <td style="width: 60px" class="text-right">
                            <v-menu
                                :key="`menu_${directory.path}`"
                                offset-y
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <confirm-modal
                                        :key="`update_${directory.path}`"
                                        :title="t('file_manager_action_edit_directory')"
                                        @agree="update(directory.path)"
                                        @refuse="updateProps"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                link
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_edit') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <template #content>
                                            <v-text-field
                                                :value="directory.path"
                                                :label="t('file_manager_label_name')"
                                                required
                                                outlined
                                                dense
                                                hide-details
                                                class="mb-8"
                                                disabled
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="changer"
                                                :label="t('file_manager_label_new_name')"
                                                required
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                    </confirm-modal>
                                    <confirm-modal
                                        :key="`delete_${directory.path}`"
                                        :title="t('file_manager_label_confirm_delete')"
                                        @agree="del(directory.path)"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                link
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title class="red--text">{{ t('general_action_delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </confirm-modal>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                    <tr v-for="file in filer.files">
                        <td>
                            <v-icon
                                size="18"
                                left
                            >{{ icons[file.extension] || 'mdi-file-document-outline' }}</v-icon>{{ file.name }}
                        </td>
                        <td>
                            <v-btn
                                @click="fire(file.path)"
                                icon
                            ><v-icon size="16" :color="fired === file.path ? 'orange' : ''">mdi-content-copy</v-icon></v-btn>{{ fullPath(file.path) }}
                        </td>
                        <td>{{ file.size }}</td>
                        <td class="text-right">
                            <v-menu
                                :key="`menu_${file.path}`"
                                offset-y
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        link
                                        @click="viewFile(file.path)"
                                    >
                                        <v-list-item-title
                                            class="customized-deep-primary--text"
                                        >{{ t('file_manager_action_open') }}</v-list-item-title>
                                    </v-list-item>
                                    <confirm-modal
                                        :key="`update_${file.path}`"
                                        :title="t('file_manager_action_edit_file')"
                                        @agree="update(file.path)"
                                        @refuse="updateProps"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                link
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title
                                                    class="customized-deep-primary--text"
                                                >{{ t('general_action_edit') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <template #content>
                                            <v-text-field
                                                :value="file.path"
                                                :label="t('file_manager_label_name')"
                                                required
                                                outlined
                                                dense
                                                hide-details
                                                class="mb-8"
                                                disabled
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="changer"
                                                :label="t('file_manager_label_new_name')"
                                                required
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                    </confirm-modal>
                                    <confirm-modal
                                        :key="`delete_${file.path}`"
                                        :title="t('file_manager_label_confirm_delete')"
                                        @agree="del(file.path)"
                                    >
                                        <template #default="{ on, attrs }">
                                            <v-list-item
                                                link
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                <v-list-item-title class="red--text">{{ t('general_action_delete') }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </confirm-modal>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions>
            <v-file-input
                v-model="photos"
                class="md extra-dense"
                required
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                hide-details
                dense
                multiple
                :placeholder="t('file_manager_label_browse_files')"
                @click:clear="clear"
            ></v-file-input>
            <v-btn
                @click="upload"
                class="exs ml-2"
                color="primary"
                outlined
                small
                :disabled="overlay || ! photos.length"
            >{{ t('file_manager_action_upload') }}</v-btn>
            <span class="form-error pl-2" v-if="form.errors.has('files.0')">{{ form.errors.get('files.0') }}</span>
            <span class="form-error pl-2" v-if="form.errors.has('new_path')">{{ form.errors.get('new_path') }}</span>
            <v-spacer></v-spacer>
            <strong>{{ t('file_manager_label_summary_size') }}: {{ filer.summarySize }}</strong>
        </v-card-actions>
    </v-card>
</v-dialog>
