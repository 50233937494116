<template>
    <div>
        <app-bar :title="t('workout_executions_title')"></app-bar>

        <template v-if="touchedWorkoutExecution.exists()">
            <v-app-bar
                color="transparent"
                height="84"
                elevation="0"
                class="mb-n6 px-6"
            >
                <v-toolbar-title>{{ touchedWorkoutExecution.workout.name }}</v-toolbar-title>
                <v-btn
                    @click="unset()"
                    plain
                    icon
                    absolute
                    right
                    class="mr-n8"
                ><v-icon>mdi-close</v-icon></v-btn>
            </v-app-bar>

            <div class="px-10 pb-6 overflow-hidden">
                <workout-execution-view :execution="touchedWorkoutExecution"></workout-execution-view>
            </div>
        </template>

        <v-layout>
            <template v-if="! touchedWorkoutExecution.exists()">
                <workout-execution-collection
                    @touched="touchWorkoutExecution"
                ></workout-execution-collection>
            </template>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, reactive, ref, toRefs } from '@vue/composition-api'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { WorkoutExecution } from '@/core/models/workout-execution'
    import WorkoutExecutionCollection from '@/components/workout-executions/WorkoutExecutionCollection.vue'
    import WorkoutExecutionView from '@/components/workout-executions/WorkoutExecutionView.vue'

    export default defineComponent({
        components: {
            WorkoutExecutionCollection,
            WorkoutExecutionView,
        },

        /**
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()

            const state = reactive({
                workoutExecutionId:  <number | undefined>parseInt(<any>router.currentRoute.query.workout_execution_id),
            })

            const touchedWorkoutExecution = ref(new WorkoutExecution(<any>{}))

            const touchWorkoutExecution = (execution: WorkoutExecution) => {
                Object.assign(touchedWorkoutExecution.value, execution)
            }

            const captureWorkoutExecution = () => {
                if (state.workoutExecutionId) {
                    store.dispatch('workoutExecution/get', {
                        id: state.workoutExecutionId,
                        payload: {
                            relations: 'user,workout,feedback,workout.sets,workout.stroke,workout_set_executions,workout_set_executions.set,workout_set_executions.set.stroke'
                        }
                    }).then((data) => {
                        touchWorkoutExecution(new WorkoutExecution(data.data))
                    })
                }
            }

            const unset = () => {
                if (state.workoutExecutionId) {
                    router.replace({ query: undefined })
                    state.workoutExecutionId = undefined
                }
                touchedWorkoutExecution.value.unset()
            }

            onMounted(() => {
                captureWorkoutExecution()
            })

            return {
                touchedWorkoutExecution: <WorkoutExecution>touchedWorkoutExecution.value,
                touchWorkoutExecution,
                unset,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>