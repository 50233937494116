import { useHttp } from '../http'

export const useFeedbackStatsHttp = () => {

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`feedback/backstats`, payload)
    }

    return {
        get,
    }
}