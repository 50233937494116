export interface MetaPayload {
    to: number
    from: number
    current_page: number
    last_page: number
    per_page: number
    total: number
}

/**
 * @param  {MetaPayload} meta
 * @return number
 */
export function pageCount(meta: MetaPayload) {
    let count = meta.total / meta.per_page
    let rounded = Math.round(count)
    if (count - rounded > 0) {
        rounded += 1
    }
    return rounded
}